package editor

import androidx.compose.runtime.Composable
import androidx.compose.runtime.SideEffect
import document.Caret
import document.Fragment
import document.ParagraphStyle
import document.TextStyle
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement

private enum class RomanSymbol(val decimal: Int) {
    I(1),
    IV(4),
    V(5),
    IX(9),
    X(10),
    XL(40),
    L(50),
    XC(90),
    C(100),
    CD(400),
    D(500),
    CM(900),
    M(1000);

    companion object {
        fun closest(value: Int) =
            values()
                .sortedByDescending { it.decimal }
                .firstOrNull { value >= it.decimal }
    }
}

fun intToRomanNumeral(num: Int): String = RomanSymbol.closest(num)
    .let { symbol ->
        if (symbol != null) {
            "$symbol${intToRomanNumeral(num - symbol.decimal)}"
        } else {
            ""
        }
    }

fun toBase26(num: Int): String {
    val symbols = ('a'..'z').toList()

    if (num <= 26) return symbols[num - 1].toString()

    val quotient = num / 26
    val remainder = num % 26

    return toBase26(quotient) + symbols[remainder - 1]
}

fun toNumbered(address: List<Int>): String {
    val level = address.size
    val normalizedLevel = level % 3
    val lastNumber = address.lastOrNull() ?: return ""

    return when (normalizedLevel) {
        1 -> "${lastNumber + 1}."
        2 -> toBase26(lastNumber + 1) + "."
        0 -> intToRomanNumeral(lastNumber + 1) + "."
        else -> ""
    }
}

fun toBullets(address: List<Int>): String {
    val remainder = address.size % 3
    // '\uFEFF'
    return when (remainder) {
        1 -> "\u2022"
        2 -> "\u25e6"
        0 -> "\u25aa"
        else -> ""
    }
}

@Composable
fun renderParagraph(
    p: Fragment.Paragraph,
    attrBuilderContext: AttrBuilderContext<HTMLDivElement>?,
    caret: Caret?,
    style: TextStyle?,
    dc: DocContext,
    mode: RenderMode,
    blockVersion: Int? = null
) {
//    console.log("RENDER PARAGRAPH", caret)
//    console.log("RENDER PARAGRAPH ${p.guid}")
//    console.log("[RENDER]: render ${p.guid} with version ${dc.domObserver.getVersion(p.guid)}")

    if (mode == RenderMode.DOCX) {
        P({
            p.paragraphStyle?.let {
                dc.getNamedStyle(it.name)?.combineWith(it) ?: it
            }?.buildAttributes(this, mode) ?: style {
                marginBottom(.5.em)
            }
        }) {
            for (element in p.elements) {
                when (element) {
                    is Fragment.StyledSpan -> renderStyledSpan(element, null, caret, style, dc, mode)
                    is Fragment.Paragraph -> renderParagraph(element, null, caret, style, dc, mode)
                    is Fragment.LinkedImage -> renderLinkedImage(element, null, dc)
                    is Fragment.StoredImage -> renderStoredImage(element, null, dc)
                    is Fragment.TableParagraph -> renderTableParagraph(element, null, caret, style, dc, mode)
                    is Fragment.Frame -> renderFrame(element, dc, mode)
//                else -> {
//                     most likely invisible element
//                }
                }
            }
        }
    } else {

        SideEffect {
//            dc.spellChecker.highlight(p.guid)

//            logger.warning { "Paragraph ${p.guid} recompose END" }
//            dc.domObserver.finish(p.guid)
        }

        Div({
            // We have to combine doc-wide named style (if used) with locally set
            // paragraph style (it set):
            p.paragraphStyle?.let {
                dc.getNamedStyle(it.name)?.combineWith(it) ?: it
            }?.buildAttributes(this) ?: classes("mb-2")

            attrBuilderContext?.invoke(this)

            style {
                property("z-index", "1")
            }
            id(p.guid)
            attr("data-bv", "$blockVersion")
            domVersion(dc.domObserver, p.guid)
        }) {
            p.paragraphStyle?.firstLineIndent?.let { i ->
                if (i > 0) {
                    Div({
                        style {
                            width((i * 3).em)
//                        maxWidth((i * 3).em)
//                        minWidth((i * 3).em)
                            display(DisplayStyle.InlineBlock)
                        }
                    }) {
                        Text("${invisibleNBSP}")
                    }
                }
            }

            p.paragraphStyle?.listStyle?.let { listStyle ->
                val i = p.paragraphStyle.indentLevel ?: 0
                var leftPosition = (3 * i).em
                Div({
                    style {
                        width((1.5).em)
//                        maxWidth((i * 3).em)
//                        minWidth((i * 3).em)
                        display(DisplayStyle.InlineBlock)
                        position(Position.Absolute)
                        left(leftPosition)
                    }
                }) {
                    when (listStyle) {
                        ParagraphStyle.List.Bullets -> Text(toBullets(p.listAddress))
                        ParagraphStyle.List.Numbers -> Text(toNumbered(p.listAddress))
                    }
                }
            }

//        println("render p, ${p.elements.size} elements: ${p.isBlank}")
            for (element in p.elements) {
                when (element) {
                    is Fragment.StyledSpan -> renderStyledSpan(element, null, caret, style, dc, mode, p.paragraphStyle)
                    is Fragment.Paragraph -> renderParagraph(element, null, caret, style, dc, mode, blockVersion)
                    is Fragment.LinkedImage -> renderLinkedImage(element, null, dc)
                    is Fragment.StoredImage -> renderStoredImage(element, null, dc)
                    is Fragment.TableParagraph -> renderTableParagraph(element, null, caret, style, dc, mode, blockVersion)
                    is Fragment.Frame -> renderFrame(element, dc, mode)
//                else -> {
//                     most likely invisible element
//                }
                }
            }
        }
    }
}