package views

import Router
import androidx.compose.runtime.*
import client
import controls.Di
import controls.Icon
import controls.RouterLink
import controls.Row
import kotlinx.browser.window
import net.sergeych.cloudoc.api.ApiStorage
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement

@Composable
fun Heading(title: String, showStorageWarning: Boolean = false, content: ContentBuilder<HTMLDivElement>? = null) {
    var storage: ApiStorage? by remember { mutableStateOf(client.storageState.value) }

    LaunchedEffect(true) {
        client.storageState.collect { storage = it }
    }

    if( showStorageWarning) {
        if (storage?.let { it.available <= 0 } == true) {
            Di("alert alert-danger") {
                Text("Хранилище переполнено, ")
                RouterLink("/profile", "пополните его", {
                    classes("alert-link")
                })
                Text(", или удалите часть файлов")
            }
        }
    }
    Row {
        Di("col-auto") {
            H2 {
                if (window.location.pathname != "/") {
                    Icon.ArrowLeft.render {
                        style { cursor("pointer") }
                        onClick { Router.back() }
                        classes("me-1")
                    }
                    Text(title)
                } else {

                    Img("/cloudocs-icon-md.png") {
                        style {
                            height(1.1.em)
                            cursor("pointer")
                        }
                        classes("me-1")
                        onClick {
                            Router.push("/")
                        }
                    }

                    Di("d-inline d-sm-none") {
                        Text(title)
                    }
                    Di("d-none d-sm-inline") {
                        Text("cloudocs.ru: $title")
                    }
                }
            }
        }
        content?.let { c ->
            Di("col-md") {
                c()
            }
        }
//        if (window.location.pathname != "/") {
//            Di("col float-end mt-3") {
//                BtnLink("<< назад") { Router.back() }
//            }
//        }
    }
}