package editor

import androidx.compose.runtime.*
import booleanField
import controls.*
import document.BugException
import document.Fragment
import kotlinx.browser.window
import kotlinx.coroutines.launch
import modalDialg
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.attributes.type
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.dom.*

data class FrameMargin(
    val amount: String,
    val unit: String
)

data class FrameMargin4(
    val top: FrameMargin,
    val right: FrameMargin,
    val bottom: FrameMargin,
    val left: FrameMargin
)

data class FrameMenuData(
    val f: Fragment.Frame
)

fun parseMargin(units: List<String>, marginString: String?): FrameMargin {
    if (marginString == null) return FrameMargin("", "auto")
    val l = marginString.length

    val unit = marginString.filter { !it.isDigit() }

    if (units.indexOf(unit) != -1) {
        return FrameMargin(marginString.substring(0 until(l - unit.length)), unit)
    }

    throw BugException("undefined frame margin type '${marginString}'")
}

fun parseMargin4(units: List<String>, marginString: String?): FrameMargin4 {
    val default = FrameMargin("", "auto")

    if (marginString == null) return FrameMargin4(default, default, default, default)

    val parts = marginString.split(" ")

    if (parts.size == 1) {
        val allSides = parseMargin(units, parts[0])
        return FrameMargin4(
            allSides,
            allSides,
            allSides,
            allSides
        )
    }

    if (parts.size == 2) {
        val topBottom = parseMargin(units, parts[0])
        val rightLeft = parseMargin(units, parts[1])

        return FrameMargin4(topBottom, rightLeft, topBottom, rightLeft)
    }

    if (parts.size == 4) {
        return FrameMargin4(
            parseMargin(units, parts[0]),
            parseMargin(units, parts[1]),
            parseMargin(units, parts[2]),
            parseMargin(units, parts[3])
        )
    }

    throw BugException("broken margin format '${marginString}'")
}

fun formatMargin(mt: FrameMargin, mr: FrameMargin, mb: FrameMargin, ml: FrameMargin): String {
    return listOf(mt, mr, mb, ml).map { "${it.amount}${it.unit}" }.joinToString(" ")
}

enum class Align(override val label: String, override val value: String): SelectItem {
    LEFT("По левому краю", "left"),
    RIGHT("По правому краю", "right"),
    CENTER("По центру", "center"),
}

@Composable
fun FrameMenu(dc: DocContext, frame: Fragment.Frame, mode: RenderMode = RenderMode.EDITOR) {
    if (!mode.isInteractive) return

    val scope = rememberCoroutineScope()
    val units = listOf("px", "em", "rem", "auto", "rere")
    val margin = parseMargin4(units, frame.margin)

//    console.log("FRAME MARGIN '${frame.margin}'")

    var marginTop by mutableStateOf(margin.top)
    var marginRight by mutableStateOf(margin.right)
    var marginBottom by mutableStateOf(margin.bottom)
    var marginLeft by mutableStateOf(margin.left)

    var align by mutableStateOf(Align.values().find { it.value == frame.align } ?: throw BugException("broken frame align"))
    var isWrapped by mutableStateOf(frame.isWrapped)

    fun renderModal() {
        modalDialg("Редактировать объект") {
            body {
                Div(attrs = {
                    style { width(100.percent) }
                    ref {
                        window.blur()
                        it.focus()
                        onDispose { it.blur() }
                    }
                }) {
                    marginField(
                        marginTop,
                        units,
                        "Верхний отступ",
                    ) {
                        marginTop = it
                    }
                    marginField(
                        marginRight,
                        units,
                        "Правый отступ",
                    ) {
                        marginRight = it
                    }
                    marginField(
                        marginBottom,
                        units,
                        "Нижний отступ",
                    ) {
                        marginBottom = it
                    }
                    marginField(
                        marginLeft,
                        units,
                        "Левый отступ",
                    ) {
                        marginLeft = it
                    }

                    selectField(
                        align as SelectItem,
                        Align.values().toList(),
                        label = "Выравнивание"
                    ) { newAlign ->
                        val nAlign = newAlign as Align
                        if (nAlign == Align.CENTER) isWrapped = false
                        align = nAlign
                    }

                    if (align != Align.CENTER) {
                        booleanField(isWrapped, labelContent = {
//                            if (frame.isWrapped) Text("Выключить обтекание")
//                            else Text("Включить обтекание")
                            Text("Обтекание текстом")
                        }) {
                            isWrapped = it
                        }
                    }
                }
            }
            footer {
                Button(attrs = {
                    type(ButtonType.Button)
                    classes("btn", "btn-danger")
                    attr("data-bs-dismiss", "modal")
                    onClick {
                        scope.launch {
                            dc.deleteTerminalFragment(frame.guid)
                        }
                        close()
                    }
                }) { Text("Удалить") }
                Button(attrs = {
                    type(ButtonType.Button)
                    classes("btn", "btn-secondary")
                    attr("data-bs-dismiss", "modal")
                    onClick { close() }
                }) { Text("Отменить") }
                Button(attrs = {
                    type(ButtonType.Button)
                    classes("btn", "btn-primary")
                    onClick {
                        val updated = frame.copy(
                            margin = formatMargin(marginTop, marginRight, marginBottom, marginLeft),
                            align = align.value,
                            isWrapped = isWrapped
                        )

                        scope.launch {
                            dc.updateTerminalFragment(updated)
                        }

                        close()
                    }
                }) { Text("Сохранить") }
            }
        }
    }

    Di("btn-group ${DOCUMENT_CONTROL_CLASS}", {
        attr("role", "group")
        style {
            position(Position.Absolute)
            if (frame.align == "right") {
                right(-17.px)
            }
            else left(-17.px)
            top(0.percent)
            bottom(0.percent)

            property("margin", "auto 0")
        }
    }) {
        Button({
            type(ButtonType.Button)
            classNames("btn btn-secondary btn-sm")
            style {
                width(17.px)
                paddingLeft(0.px)
                paddingRight(0.px)
                if (frame.align == "right") {
                    borderRadius(
                        0.px,
                        0.25.cssRem,
                        0.25.cssRem,
                        0.px
                    )
                }
            }
            onClick { renderModal() }
        }) {
            Icon.ThreeDotsVertical.render({ style { fontSize(1.2.em) } })
        }

        Ul({
            classes("dropdown-menu")
        }) {
            Li {
                A(null, {
                    classNames("dropdown-item ${DOCUMENT_CONTROL_CLASS}")
                    onClick { scope.launch {
                        val updatedFrame = frame.copy(align = "left")
                        dc.updateTerminalFragment(updatedFrame)
                    } }
                }) { Text("Выравнивание по левому краю") }
                A(null, {
                    classNames("dropdown-item ${DOCUMENT_CONTROL_CLASS}")
                    onClick { scope.launch {
                        val updatedFrame = frame.copy(align = "right")
                        dc.updateTerminalFragment(updatedFrame)
                    } }
                }) { Text("Выравнивание по правому краю") }
                if (!frame.isWrapped) A(null, {
                    classNames("dropdown-item ${DOCUMENT_CONTROL_CLASS}")
                    onClick { scope.launch {
                        val updatedFrame = frame.copy(align = "center")
                        dc.updateTerminalFragment(updatedFrame)
                    } }
                }) { Text("Выравнивание по центру") }
                if (frame.align != "center") A(null, {
                    classNames("dropdown-item ${DOCUMENT_CONTROL_CLASS}")
                    onClick { scope.launch {
                        val updatedFrame = frame.copy(isWrapped = !frame.isWrapped)
                        dc.updateTerminalFragment(updatedFrame)
                    } }
                }) {
                    if (frame.isWrapped) Text("Выключить обтекание")
                    else Text("Включить обтекание")
                }
            }
        }
    }
}

@Composable
fun renderFrame(
    frame: Fragment.Frame,
    dc: DocContext,
    mode: RenderMode
) {
    var frameClasses = mutableListOf<String>("fframe")
    val isWrappedLeft = frame.isWrapped && frame.align == "left"
    val isWrappedRight = frame.isWrapped && frame.align == "right"

//    if (frame.isWrapped) {
//        when (frame.align) {
//            "left" -> frameClasses.add("float-start")
//            "right" -> frameClasses.add("float-end")
//            else -> {}
//        }
//    }

    P(attrs = {
        classes(frameClasses)
        attr("data-ftype", "frame")
        // FIXME: frame guid is rendered as id in frame content element
        attr("frame-guid", frame.guid)
        style {
            display(DisplayStyle.Flex)

            if (frame.isWrapped) {
                when (frame.align) {
                    "left" -> property("float", "left")
                    "right" -> property("float", "right")
                    else -> {}
                }
            }

            property("margin", frame.margin)
            property("position", "relative")
            if (frame.align == "right") {
                if (!frame.isWrapped) flexDirection(FlexDirection.RowReverse)
            }
            if (frame.align == "center" && !frame.isWrapped) justifyContent(JustifyContent.Center)
            property("max-width", "100%")
        }
    }) {
        if (mode.isInteractive) FrameMenu(dc, frame)
        when (frame.type) {
            "image" -> {
                renderImage(frame, dc, mode)
            }

            else -> {}
        }
    }
}