package editor

import controls.Toaster
import document.BugException
import kotlinx.browser.window
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.cancel
import kotlinx.coroutines.delay
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.attributes.AttrsScope
import org.w3c.dom.*
import tools.Debouncer
import tools.randomId
import kotlin.time.Duration
import kotlin.time.Duration.Companion.milliseconds

data class Watcher(
    val guid: String,
    val version: String,
    val job: CompletableDeferred<Boolean>
)

fun <T: Element> AttrsScope<T>.domVersion(obs: DOMObserver, guid: String, version: String? = null) {
    val ver = version ?: obs.versionMap[guid]

    ver?.let {
        attr(obs.renderVersionAttrName, it)
    }
}

/*
    We have no access to drawing phase of recomposition.
    To be sure that composable components already rendered we can put unique
    renderVersion as html attribute for each UI redraw event and wait for it with MutationObserver

    renderVersionAttrName - what property name to use as html attribute for renderVersion
 */
class DOMObserver(
    val dc: DocContext,
    val containerId: String = DOCUMENT_CONTENT_ID,
    val renderVersionAttrName: String = "data-rv",
    val rejectTimeout: Duration = 200.milliseconds,
    val debug: Boolean = false
): LogTag("[DOMObserver]") {
    var observer: MutationObserver? = null
    val versionMap = mutableMapOf<String, String>()
    val watchers = mutableListOf<Watcher>()

    fun log(msg: String, isErr: Boolean = false) {
        if (isErr) console.error("[OBSERVER]: ${msg}")
        else if (debug) {
//            if (isErr) console.error("[OBSERVER]: ${msg}")
            console.warn("[OBSERVER]: ${msg}")
        }
    }

    private fun createVersion(guid: String): String {
        return guid + randomId(3)
    }

    fun getVersion(guid: String): String? {
        return versionMap[guid]
    }

    fun verCode(version: String): String {
        return version.slice(version.length - 3..version.length)
    }

    fun updateVersion(guid: String): String {
        val version = createVersion(guid)
        versionMap[guid] = version
        return version
    }

    fun cancelJobs(guid: String) {
        watchers.filter { it.guid == guid }.forEach {
            log("id=${it.guid} ver=${verCode(it.version)} rejected as outdated")
            it.job.complete(false)
        }
    }

    /*
        Creates watcher for element by guid.
        Watcher will wait for specific version of element, created for it.
        All previous watchers for this guid will be completed as false
     */
    fun watch(guid: String, debugCaller: String?): Watcher {
        val version = updateVersion(guid)
        log("watch: id=${guid} ver=${verCode(version)} caller=${debugCaller ?: ""}")

        cancelJobs(guid)

        val job = createJob(guid, version)
        val watcher = Watcher(guid, version, job)
        watchers.add(watcher)

        return watcher
    }

    /*
        Wait for all scheduled render jobs
     */
    suspend fun waitAll(debugCaller: String?): Boolean {
        var isComplete = true

        if (debug) {
            log("waitAll run ${watchers.size} caller=${debugCaller}")
            watchers.forEach {
                log("${it.guid} -> ${it.version}: ${it.job.isCompleted}")
            }
            log("waitAll end list")
        }
        watchers.forEach {
            isComplete = isComplete && it.job.await()
        }
        log("waitAll completed with ${isComplete}")
        return isComplete
    }

    suspend fun wait(guid: String, debugCaller: String?): Boolean {
        var isComplete = true

        watchers.forEach {
            if (it.guid == guid) {
                log("wait ${it.guid} ver=${verCode(it.version)} caller=${debugCaller}")
//                console.warn("[OBSERVER]: wait ${it.guid} ver=${verCode(it.version)} run ")
                isComplete = isComplete && it.job.await()
//                console.warn("[OBSERVER]: wait ${it.guid} ver=${verCode(it.version)} completed ${isComplete}")
            }
        }

//        console.warn("[OBSERVER]: wait ${guid} completed with ${isComplete}")

        return isComplete
    }

    fun complete(guid: String) {
        watchers.filter { it.guid == guid }.forEach { it.job.complete(true) }
    }

    fun connect() {
        fun checkTarget(target: HTMLElement) {
            val guid = target.id
            val version = target.getAttribute(renderVersionAttrName)
            watchers.find { it.guid == guid && it.version == version }?.let { watcher ->
                if (debug) {
                    log("checkTarget: id=${watcher.guid} ver=${verCode(watcher.version)} FOUND")
                    watchers.forEach {
                        log("checkTarget: wait for ${it.guid} -> ${it.version}: ${it.job.isCompleted}")
                    }
                    log("checkTarget: done")
                }
                watcher.job.complete(true)
            }
            target.childNodes.asList().filter {
                it is HTMLDivElement || it is HTMLTableElement
            }.forEach { checkTarget(it as HTMLElement) }
        }

        globalLaunch {
            val container = window.document.querySelector("#$containerId")
                ?: throw BugException("DOMObserver: can't find container")

            val observerOptions = MutationObserverInit(
                subtree = true,
                childList = true,
                attributeFilter = listOf(renderVersionAttrName).toTypedArray()
            )

            val obs = MutationObserver { records, observer ->
//                console.warn("[OBSERVER]: records", records)
                records.forEach { rec ->
                    val target = rec.target
                    val targets = rec.addedNodes.asList().filter { it is HTMLDivElement }

                    if (target is HTMLDivElement || target is HTMLTableElement) checkTarget(target as HTMLElement)

                    targets.forEach { checkTarget(it as HTMLDivElement) }
                }
            }

            obs.observe(container, observerOptions)

            log("connected to ${container.id}")

            observer = obs
        }
    }

    fun disconnect() {
        observer?.disconnect()
        watchers.forEach { it.job.complete(false) }
    }

    private fun createJob(guid: String, version: String): CompletableDeferred<Boolean> {
        val job = CompletableDeferred<Boolean>()

        val terminator = Debouncer(GlobalScope, rejectTimeout) {
            if (job.isActive) {
                if (DebugList.domObserverTimeout) {
                    val message = "Элемент guid = ${guid} не был перерисован с версией ${verCode(version)}"
                    log("id=${guid} ver=${verCode(version)} rejected by timeout", true)
//                    Toaster.error(message)
                    dc.replay.onError(message)
                } else {
                    log("id=${guid} ver=${verCode(version)} rejected by timeout", true)
                    job.complete(false)
                }
            }
        }

        terminator.schedule()

        job.invokeOnCompletion {
            watchers.removeAll { it.version == version && it.guid == guid }
        }

        return job
    }
}