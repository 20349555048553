@file:Suppress("unused")

package document

import kotlinx.serialization.Serializable
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.css.*
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import tools.ColorSerializer

@Serializable
data class TextStyle(
    val fontFamily: FontFamily? = null,
    val fontSize: Int? = null,
    val fontWeight: FontWeight? = null,
    @Serializable(with=ColorSerializer::class)
    val textColor: CSSColorValue? = null,
    @Serializable(with=ColorSerializer::class)
    val backgroundColor: CSSColorValue? = null,
    val italics: Boolean? = null,
    val underline: Boolean? = null,
    val parentStyle: TextStyle? = null
) {

    fun buildAttributes(scope: AttrsScope<HTMLElement>) {
        with(scope) {
            style {
                fontFamily?.let { fontFamily(it.cssFamily) }
                fontWeight?.let { fontWeight(it.cssWeight) }
                fontSize?.let { fontSize(it.pt) }
                textColor?.let { color(it) }
                backgroundColor?.let { backgroundColor(it) }
                italics?.let { fontStyle("italic")}
                underline?.let { textDecoration("underline")}
            }
        }
    }

    enum class FontFamily(val cssFamily: String) {
        Monospaced("monoscape"), Serif("serif"), SansSerif("Gill Sans, sans-serif"),
        Cursive("cursive");
    }

    enum class FontWeight(val cssWeight: String) {
        Normal("normal"), Bold("bold"), Light("lighter");
    }

    fun withChild(childStyle: TextStyle?): TextStyle {
        return computeStyle(this, childStyle)
    }

    fun withParent(parentStyle: TextStyle?): TextStyle {
        return computeStyle(parentStyle, this)
    }

    val  effectiveStyle: TextStyle by lazy {
        withParent(parentStyle)
    }

    fun intersect(other: TextStyle): TextStyle {
        return TextStyle(
            fontFamily = if (other.fontFamily == fontFamily) fontFamily else null,
            fontSize = if (other.fontSize == fontSize) fontSize else null,
            fontWeight = if (other.fontWeight == fontWeight) fontWeight else null,
            textColor = if (other.textColor == textColor) textColor else null,
            backgroundColor = if (other.backgroundColor == backgroundColor) backgroundColor else null,
            italics = if (other.italics == italics) italics else null,
            underline = if (other.underline == underline) underline else null,
        )
    }

    /**
     * create instance with current style updated by non-null fields of the other if not null
     * otherwise returns this (as nothing to alter)
     */
    fun updateWith(other: TextStyle?): TextStyle {
        return other?.let {
            TextStyle(
                fontFamily = other.fontFamily ?: fontFamily,
                fontSize = other.fontSize ?: fontSize,
                fontWeight = other.fontWeight ?: fontWeight,
                textColor = other.textColor ?: textColor,
                backgroundColor = other.backgroundColor ?: backgroundColor,
                italics = other.italics ?: italics,
                underline = other.underline ?: underline
            )
        } ?: this
    }

    companion object {
        val defaultFamily = FontFamily.SansSerif
        val defaultSize = 10
        val defaultWeight = FontWeight.Normal

        val default = TextStyle()

        val bold = TextStyle(fontWeight = FontWeight.Bold)
        val italics = TextStyle(italics = true)
        val boldItalics = TextStyle(italics = true, fontWeight = FontWeight.Bold)
        val underline = TextStyle(underline = true)

        val heading = TextStyle(fontSize = 26)
        val subheading = TextStyle(fontSize = 15, textColor = Color("#666666"))
        val heading1 = TextStyle(fontSize = 20)
        val heading2 = TextStyle(fontSize = 16)
        val heading3 = TextStyle(fontSize = 14, textColor = Color("#434343"))
        val heading4 = TextStyle(fontSize = 12, textColor = Color("#666666"))

        fun computeStyle(parentStyle: TextStyle?, childStyle: TextStyle?): TextStyle {
            return TextStyle(
                childStyle?.fontFamily ?: parentStyle?.fontFamily,
                childStyle?.fontSize ?: parentStyle?.fontSize,
                childStyle?.fontWeight ?: parentStyle?.fontWeight,
                childStyle?.textColor ?: parentStyle?.textColor,
                childStyle?.backgroundColor ?: parentStyle?.backgroundColor,
                childStyle?.italics ?: parentStyle?.italics,
                childStyle?.underline ?: parentStyle?.underline
            )
        }
    }
}