package editor

import document.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.hasClass
import net.sergeych.mp_logger.debug
import net.sergeych.mp_logger.error
import net.sergeych.mp_logger.warning
import org.w3c.dom.*

enum class MoveDirection {
    UP,
    DOWN,
    X
}

enum class CaretRoot {
    PARAGRAPH,
    TABLE,
    TEMPORARY
}

data class CaretMove(
    val position: Position? = null,
    val direction: MoveDirection? = null,
    val x: Double? = null
)

//interface CaretInspector {
//    var caret: Caret?
//
//    fun <T> ensureCaret(c: Caret? = caret, f: (Caret) -> T): T {
//        if (c == null) throw Exception("caret required")
//
//        return f(c)
//    }
//
//    fun get(guid: String): Block?
//    fun caretBlock(c: Caret? = caret): Block
//    fun caretAtNextBlock(c: Caret? = caret): Caret?
//    fun caretAtPrevBlock(c: Caret? = caret): Caret?
//}

fun DocContext.caretContainer(c: Caret? = caret): IParagraph {
    return ensureCaret(c) {
        caretBlock(c).find(it.path.dropLast(1).last()) as IParagraph
    }
}

fun DocContext.caretSpan(c: Caret? = caret): Fragment.StyledSpan {
    return ensureCaret(c) {
        caretBlock(c).find(it.spanId) as Fragment.StyledSpan? ?: throw BugException("can't find caret span")
    }
}

fun DocContext.moveCaret(
    newCaret: Caret?,
    caretX: Double? = null,
    direction: MoveDirection? = null
): Caret? {
    return newCaret?.let {
        if (caret == newCaret) return null
        resetNextTransactionStyle()

        lastMove = lastMove.copy(x = caretX, direction = direction)
        domObserver.watch(CaretId, "moveCaret")
//        console.log("caret move from=${caret?.toFullString()} to=${it.toFullString()}, watch ${watcher.version}")
//        console.warn("[MOVE CARET]: lastMove.x = ${lastMove.x} next version ${watcher.version}")
        caret = it
        it
    }
}

/**
 * Set caret to home (whthere there is one or not)
 */
fun DocContext.caretToHome(): Caret? {
    return doc.firstBlock.caretAt(0)
}

fun DocContext.caretToEnd(): Caret {
    return doc.lastBlock.caretAtEnd()
}

fun DocContext.caretLeftBlockWide(c: Caret? = caret): Caret? {
    return ensureCaret(c) { caretBlock(c).caretLeft(it) }
}


fun DocContext.caretRightBlockWide(c: Caret? = caret): Caret? {
    return ensureCaret(c) { caretBlock(c).caretRight(it) }
}

fun DocContext.caretAtContainerStart(): Caret {
    return ensureCaret {
        val b = caretBlock()
        val c = caretContainer()
        val span = c.firstTextFragment()

        b.caretAt(span.guid, 0) ?: throw BugException("Can't put caret at start of ${span.guid}")
    }
}

fun DocContext.caretAtContainerEnd(): Caret {
    return ensureCaret {
        val b = caretBlock()
        val c = caretContainer()
        val span = c.lastTextFragment()

        b.caretAt(span.guid, span.lastOffset) ?: throw BugException("Can't put caret at start of ${span.guid}")
    }
}

fun DocContext.caretAtBlockStart(): Caret {
    return ensureCaret { caretBlock().caretAtStart() }
}

fun DocContext.caretAtBlockEnd(): Caret {
    return ensureCaret { caretBlock().caretAtEnd() }
}

suspend fun DocContext.caretDownBlockDOM(x: Double? = null): Caret? {
    val block = doc.nextBlockFocusable(caretBlock()) ?: return null

    val isReady = waitBlock(block.guid)
    if (!isReady) return null

    if (x == null) return block.caretAtStart()

    // requested x-coordinate it could be inside, or outside our para,
    // so we should check it:
    val range = block.firstStyledSpan.yRangeAtDOM(0)
//    console.log("[CARET DOWN] x=${x} y=${range.start + 2}")
    val atPoint = caretAtPointDOM(Point(x, range.start + 2), true)
    if (atPoint != null) return atPoint

    // the requested x is too far for this short para, so we set to end:
    return block.caretAtEnd()
}

suspend fun DocContext.caretUpBlockDOM(x: Double? = null): Caret? {
    val block = doc.prevBlockFocusable(caretBlock()) ?: return null

    val isReady = waitBlock(block.guid)
    if (!isReady) return null

    if (x == null) return block.caretAtEnd()

    val range = block.lastStyledSpan.yRangeAtDOM(-1)
//    console.log("[CARET UP] x=${x} y=${range.start + 2}")
    val atPoint = caretAtPointDOM(Point(x, range.start + 2), true)
    if (atPoint != null) return atPoint

    // the requested x is too far for this short para, so we set to end:
    return block.caretAtEnd()
}

suspend fun DocContext.moveCaretToHome(): Caret? {
    return moveCaret(caretToHome())
}

/**
 * Set cuttent caret (must not be null!) to the next block, optionally, to the x-poition
 * most close to the requested
 */
suspend fun DocContext.moveCaretDownBlockDOM(x: Double? = null): Caret? {
    return withAsyncCaret { c ->
        val caretBox = caretBoxDOM(c) ?: return@withAsyncCaret null

        lastMove = lastMove.copy(x = x ?: caretBox.center.x)
        moveCaret(caretDownBlockDOM(x), lastMove.x)
    }
}

suspend fun DocContext.moveCaretDownBlockDOM(x: Double? = null, caret: Caret? = null): Caret? {
    lastMove = lastMove.copy(x = x)
    return moveCaret(caret, x)
}

/**
 * Move existing caret (should not be null) to the previous block, optionally,
 * close to the requested x-coordinate.
 */
suspend fun DocContext.moveCaretUpBlockDOM(x: Double? = null): Caret? {
    return withAsyncCaret { c ->
        val caretBox = caretBoxDOM(c) ?: return@withAsyncCaret null

        lastMove = lastMove.copy(x = x ?: caretBox.center.x)
        moveCaret(caretUpBlockDOM(x), lastMove.x)
    }
}

suspend fun DocContext.moveCaretUpBlockDOM(x: Double? = null, caret: Caret? = null): Caret? {
    return moveCaret(caret, x)
}

suspend fun DocContext.moveCaretAtContainerStart(): Caret? {
    return moveCaret(caretAtContainerStart())
}

suspend fun DocContext.moveCaretAtContainerEnd(): Caret? {
    return moveCaret(caretAtContainerEnd())
}

suspend fun DocContext.moveCaretAtBlockStart(): Caret? {
    return moveCaret(caretAtBlockStart())
}

suspend fun DocContext.moveCaretAtBlockEnd(): Caret? {
    return moveCaret(caretAtBlockEnd())
}

//fun DocContext.caretCoordinates(): Point {
//    caretSpan()?.let { f ->
//        val elemRect = elemRect(f)
//        val bodyRect = window.document.body?.getBoundingClientRect()
////            offset   = elemRect.top - bodyRect.top;
//
//        return Point(elemRect.left - bodyRect!!.left, elemRect.top - bodyRect.top)
//
//    } ?: throw Exception("no fragment with caret: no caret?")
//}

// FIXME: Caret should be in that span already drawen
suspend fun DocContext.caretBoxDOM(c: Caret = caret!!): Box? {
    val isReady = waitCaret() && waitBlock(c.blockId)
    if (!isReady) return null

//    val spanEl = window.document.getElementById(c.spanId)
//    console.log("CaretBoxDOM: ", spanEl)
//    val cursors = spanEl?.getElementsByClassName(CaretCSS)
//    console.log("CaretBoxDOM: ", cursors)
    val elem = window.document.getElementById(c.spanId)
        ?.getElementsByClassName("blinking-cursor")?.get(0)
        ?: throw BugException("cursor element was not found - need to wait?")

    return Box(elem.getBoundingClientRect()) - bodyOrigin()
}

// returns table guid if caret is in table
fun DocContext.getTableId(caret: Caret): String? {
    val cb = caretBlock(caret)
    if (cb.paragraph is Fragment.TableParagraph) return cb.guid
    return null
}

suspend fun DocContext.caretAtPointDOM(point: Point, isApproximate: Boolean = false): Caret? {
    val debug = false

    fun log(msg: String) {
        if (debug) console.warn("[CARET AT POINT]: ${msg}")
    }

    log("run")
    val isReady = domObserver.waitAll("caretAtPointDOM")

    if (!isReady) return null

    val elements = window.document.elementsFromPoint(point.x, point.y)
    fun checkNode(e: Node?): Boolean { return e != null && e.nodeName == "#text" }

    fun fixOffset(text: String?, offset: Int?): Int? {
        if (text == null || offset == null) return null

        var corrected = offset
        var i = 0
        var chars = text.toList()

        while(i < offset) {
            // FIXME: remove invisible from document
            if (chars[i] == invisibleNBSP) corrected -= 1
            i++
        }

        return corrected
    }

    log("elements: ${elements}")

    val firstSpan = elements.firstOrNull {
        it.hasClass(StyledSpanCSS) && (checkNode(it.childNodes[0]) || checkNode(it.childNodes[1]))
    }

    log("firstSpan: ${firstSpan?.id}")

    firstSpan?.let {
        val fragmentElement = it
        var index = 0
        var elem = it.childNodes[0]
        if (elem is HTMLElement && elem.hasClass("blinking-cursor")) {
            // skip the caret
            elem = it.childNodes[1]
            index = 1
        }
        if (elem == null) {
            warning { "expected text content element is null" }
            return null
        }
        if (elem.nodeName != "#text") {
            warning { "findSpanAndCharacter: element that is to be span has wrong first node: ${elem.nodeName}: ${it.outerHTML}" }
            return null
        }
        val s = elem.textContent
        if (s == null) {
            warning { "findSpanAndCharacter: text content of an element is null" }
            return null
        }

        // There could be a caret INSIDE:
        var offset: Int? = null
        if (index == 0) {
            log("caretAtPoint: elementsFromPoint: closest span: caret is not the first node")
            val next = it.childNodes[index + 1]
            if (next != null && next is HTMLElement && next.hasClass("blinking-cursor")) {
                log("caretAtPoint: elementsFromPoint: closest span: caret is second node")
                // we might have text split by existing caret:
                val right = it.childNodes[index + 2]
                if (right != null) {
                    log("caretAtPoint: elementsFromPoint: closest span: caret divides text")
                    val leftOffset = textOffset(elem, point, 0, s.length)
                    if (leftOffset != null) {
                        offset = leftOffset
                        log("caretAtPoint: elementsFromPoint: closest span: left text node offset = ${leftOffset}, resultingOffset = ${offset.toString()}")
                    } else {
                        val rightOffset = textOffset(
                            right, point, 0, right.textContent?.length ?: 0
                        )

                        if (rightOffset != null) {
                            offset = rightOffset + s.length - 2
                            log("caretAtPoint: elementsFromPoint: closest span: right text node offset = ${rightOffset}, resultingOffset = ${offset.toString()}")
                        } else {
                            error { "point not in both left and right parts, what does it mean?" }
                            return null
                        }
                    }
                }
            } else {
                log("caretAtPoint: elementsFromPoint: closest span: no caret in span")
            }
        }

        if (offset == null) {
            offset = fixOffset(elem.textContent, textOffset(elem, point, 0, s.length))

            log("caretAtPoint: elementsFromPoint: closest span: offset for whole text span = ${offset.toString()}")
        }
        if (offset == null) {
            error { "Failed to find text offset in ${fragmentElement.outerHTML} at ${point}" }
            return null
        }

        log("caretAtPoint: elementsFromPoint: closest span: resulting offset O = ${offset}")

        findBlockAndFragmentDOM(doc, fragmentElement as HTMLElement).let { (block, fragment) ->
//            console.log(">.< set caret at guid=${fragment.guid} offset=${offset}")

            return block.paragraph.caretAt(fragment.guid, offset)
        }
    } ?: run {
        val closestBlock = getClosestBlockDOM(point)

        closestBlock?.let { block ->
//            debug {
//                "caretAtPoint: found closest block ${block.guid}"
//            }
            var closestParagraph: IParagraph? = block.paragraph

            if (block.paragraph is Fragment.TableParagraph) {
                closestParagraph = block.paragraph.getClosestCell(point)
                log("caretAtPoint: we're in table, closest paragraph is ${closestParagraph?.guid}")
            }

            val closestSpan = closestParagraph?.getClosestTextFragment(point)

            closestSpan?.let { span ->
                log("caretAtPoint: found closest span")

                document.getElementById(span.guid)?.let { el ->
                    val spanBox = Box(el.getBoundingClientRect())
                    val defaultOffset = if (point.x >= spanBox.right) span.text.length else 0

                    if (!isApproximate) return block.paragraph.caretAt(span.guid, defaultOffset)

                    val offset = StyledSpanClosestOffset(span, point)
                    log("caret at span offset=${offset} defaultOffset=${defaultOffset} textlen = ${span.text.length}")
                    return block.paragraph.caretAt(span.guid, offset ?: defaultOffset)
                } ?: run {
                    log("return null 1")
                    return null
                }
            } ?: run {
                // Check if we are clicking table cell
                log("return null 2")

                return null
            }
        } ?: run {
            log("return null 3")
            return null
        }
    }
}

/**
 * Calculates TextStyle at caret position
 */
//fun DocContext.getCaretStyle(ct: Caret? = caret): TextStyle {
//    ct?.let { c ->
//        val span = caretSpan(c) as Fragment.StyledSpan
//        return span.textStyle ?: TextStyle()
//    } ?: throw Exception("caret required")
//}


/**
 * Calculates intersection of TextStyle in given caret range
 */
//fun DocContext.getCaretRangeStyle(caretRange: CaretRangeOrdered? = selection2.range): TextStyle {
//    caretRange?.let { range ->
//        val start = range.left
//        val end = range.right
//
//        var cursor = start
//        var intersection = getCaretStyle(start)
//
//        while (cursor != end) {
//
//            val nextCursor = caretRight(cursor)
//
////                 FIXME: backspace case: there may be no end after deleting character
//            if (nextCursor == null) throw BugException("caret right is the same")
//
//            cursor = caretLeft(cursor)
//
//            intersection = intersection.intersect(getCaretStyle(cursor))
//        }
//
//        return intersection
//    } ?: throw Exception("caret range required")
//}

fun DocContext.getStyle(c: Caret, caretBlock: Block? = null): TextStyle {
    val block = (get(c.blockId) ?: caretBlock) ?: throw BugException("caret block not in chain")
    val span = block.find(c.spanId) as? Fragment.StyledSpan ?: throw BugException("caret span not in chain")

    return span.textStyle ?: TextStyle()
}

fun DocContext.getStyle(range: CRange): TextStyle {
    val left = range.left
    val right = range.right
    var cursor: Caret? = left
    var intersection = getStyle(cursor!!)

    do {
        val cursorStyle = getStyle(cursor!!)
//        console.log("CURSOR STYLE", cursorStyle.italics, cursorStyle.fontWeight, cursorStyle.underline)
        intersection = intersection.intersect(cursorStyle)
        cursor = caretRight(cursor)
    } while(cursor != right && cursor != null)

    return intersection
}

fun DocContext.caretIsAtParagraphStart(): Boolean = caret?.let { c ->
    caretBlock().firstStyledSpanOrNull?.let { sspan ->
//        println("CAIPS: ${c.offset}, ${c.spanId} / ${sspan}")
        c.offset == 0 && c.spanId == sspan.guid
    }
} ?: false

data class Word(
    val str: String,
    val range: CRange
) {
    fun isAlpha(): Boolean {
        return str?.find { !it.isLetter() } == null
    }
}

fun DocContext.getNextWord(isForward: Boolean, caretFrom: Caret? = caret): Word? {
    val debug = false
    fun log(msg: String) {
        if (debug) console.log("[CARET TO NEXT WORD]: ${msg}")
    }

    fun nextCaret(c: Caret?): Caret? {
        if (c == null) return null
        val block = caretBlock(c)
        val span = caretSpan(c)

        // check terminal fragment stops
        if (isForward) {
            log("nextCaret: forward")
            if (c.offset == span.lastOffset) {
                log("nextCaret: last offset")
                val tf = block.nextTerminalFragment(c.spanId)
                if (tf == null || tf !is Fragment.StyledSpan) {
                    log("nextCaret: next tf is not text")
                    return null
                } else {
                    if (tf.isLink()) {
                        log("nextCaret: next tf is link")
                        return null
                    }
                }
            }
        } else {
            log("nextCaret: backward")
            if (c.offset == 0) {
                log("nextCaret: zero offset")
                val tf = block.prevTerminalFragment(c.spanId)
                if (tf == null || tf !is Fragment.StyledSpan) {
                    log("nextCaret: prev tf is not text")
                    return null
                } else {
                    if (tf.isLink()) {
                        log("nextCaret: prev tf is link")
                        return null
                    }
                }
            }
        }

        // check container end stops
        return when(c.rootType) {
            CaretRoot.TABLE -> {
                if (isForward) {
                    log("nextCaret: forward in cell")
                    caretRightCellWide(c)
                } else {
                    log("nextCaret: backward in cell")
                    caretLeftCellWide(c)
                }
            }
            CaretRoot.PARAGRAPH -> {
                if (isForward) {
                    log("nextCaret: forward in block")
                    caretRightBlockWide(c)
                } else {
                    log("nextCaret: backward in block")
                    caretLeftBlockWide(c)
                }
            }
            else -> null
        }
    }

    fun escapeStartCaret(c: Caret): Caret? {
        val block = caretBlock(c)
//        block.printFull()
        val span = caretSpan(c)

        if (isForward) {
            log("escapeStartCaret: forward")
            if (c.offset == span.lastOffset) {
                log("escapeStartCaret: last offset")
                val nextF = block.nextTerminalFragment(span)
                if (nextF == null) {
                    log("escapeStartCaret: next tf is null, careRight")
                    return caretRight(c)
                }
                else {
                    log("escapeStartCaret: next tf is not null")
                    if (nextF !is Fragment.StyledSpan || nextF.isLink()) {
                        log("escapeStartCaret: next tf is not simple text, skip to start of next text")
                        val nextText = block.nextTextFragment(span)
                            ?: throw BugException("block last element is not text")
                        return block.caretAt(nextText.guid, 0)
                    } else {
                        log("escapeStartCaret: next tf is text, check container end")
                        when(c.rootType) {
                            CaretRoot.TABLE -> {
                                val cell = block.find(c.cellId) as? Fragment.Paragraph
                                    ?: throw BugException("Can't get cell")
                                val lastText = cell.lastTextFragment()
                                if (lastText.guid == c.spanId) {
                                    log("escapeStartCaret: caret at cell end, move to next cell")
                                    return caretRight(c)
                                } else return c
                            }
                            CaretRoot.PARAGRAPH -> {
                                val lastText = block.lastTextFragment()
                                if (lastText.guid == c.spanId) {
                                    log("escapeStartCaret: caret at block end, move to next block")
                                    return caretRight(c)
                                } else return c
                            }
                            else -> return c
                        }
                    }
                }
            } else {
                log("escapeStartCaret: offset is not last, do nothing")
                return c
            }
        } else {
            log("escapeStartCaret: backward")
            if (c.offset == 0) {
                val prevF = block.prevTerminalFragment(span)
                if (prevF == null) {
                    log("escapeStartCaret: prev tf is null, careLeft")
                    return caretLeft(c)
                }
                else {
                    log("escapeStartCaret: prev tf is not null")
                    if (prevF !is Fragment.StyledSpan || prevF.isLink()) {
                        log("escapeStartCaret: prev tf is not simple text, skip to end of prev text")
                        val prevText = block.prevTextFragment(span)
                            ?: throw BugException("block first element is not text")
                        return block.caretAt(prevText.guid, prevText.lastOffset)
                    } else {
                        log("escapeStartCaret: prev tf is text, check container beginning")
                        when(c.rootType) {
                            CaretRoot.TABLE -> {
                                val cell = block.find(c.cellId) as? Fragment.Paragraph
                                    ?: throw BugException("Can't get cell")
                                val lastText = cell.firstTextFragment()
                                if (lastText.guid == c.spanId) {
                                    log("escapeStartCaret: caret at cell beginning, move to prev cell")
                                    return caretLeft(c)
                                } else return c
                            }
                            CaretRoot.PARAGRAPH -> {
                                val lastText = block.firstTextFragment()
                                if (lastText.guid == c.spanId) {
                                    log("escapeStartCaret: caret at block beginning, move to prev block")
                                    return caretLeft(c)
                                } else return c
                            }
                            else -> return c
                        }
                    }
                }
            } else {
                log("escapeStartCaret: offset is not zero, do nothing")
                return c
            }
        }
    }

    fun escapeCaretInLink(c: Caret): Caret? {
        val block = caretBlock(c)
        val nextSpan = if (isForward) block.nextTextFragment(c.spanId) else block.prevTextFragment(c.spanId)

        if (nextSpan == null) return null

        val offset = if (isForward) 0 else nextSpan.lastOffset

        return block.caretAt(nextSpan.guid, offset)
    }

    fun getChar(prev: Caret?, next: Caret): Char? {
        if (prev == null) return null

        val nextSpan = caretSpan(next)

        if (isForward) {
            if (next.offset > 0) return nextSpan.text[next.offset - 1]
            else {
                val prevSpan = caretSpan(prev)
                if (prevSpan.lastOffset == 0) return null
                return prevSpan.text[prevSpan.lastOffset - 1]
            }
        } else {
            if (next.offset < nextSpan.lastOffset) return nextSpan.text[next.offset]
            else {
                val prevSpan = caretSpan(prev)
                if (prevSpan.lastOffset == 0) return null
                return prevSpan.text[0]
            }
        }
    }

    fun skipSpaces(start: Caret): Pair<Caret, Char?> {
        log("Skip spaces")
        var prev: Caret = start
        var next: Caret?
        var skipDone = false
        var c: Char? = null

        while(!skipDone) {
            next = nextCaret(prev)
            if (next == null) skipDone = true
            else {
                val char = getChar(prev, next)
                log("skip spaces: next char is '${char}' and its space=${char == ' '}")
                if (char != ' ') {
                    c = char
                    skipDone = true
                }
                else prev = next
            }
        }

        return Pair(prev, c)
    }

    fun skip(start: Caret, isAlphaSegment: Boolean): Word {
        var prev: Caret = start
        var next: Caret?
        var skipDone = false
        var word = ""

        while(!skipDone) {
            next = nextCaret(prev)
            if (next == null) skipDone = true
            else {
                val char = getChar(prev, next!!)
                if (char == null || char == ' ' || char.isLetter() != isAlphaSegment) {
//                    console.log("skip stop")
                    skipDone = true
                }
                else {
                    word += char
//                    console.log("skip '${char}'")
                    prev = next!!
                }
            }
        }
        val range = if (isForward) CRange(start, prev) else CRange(prev, start)
//        console.log("GOT WORD '${word}' from=${range.left.toFullString()} to=${range.right.toFullString()}")
        return Word(word, range)
    }

    return caretFrom?.let { caretStart ->
        fun defaultWord(end: Caret): Word {
            val range = if (isForward) CRange(caretStart, end) else CRange(end, caretStart)
            return Word("", range)
        }

        var current = escapeStartCaret(caretStart)
        log("ESCAPED CARET ${current?.toFullString()}")

        if (current == null) return null
        else {
            val next = nextCaret(current) ?: return defaultWord(current)
            val nextSpan = caretSpan(next)
            if (nextSpan.isLink()) {
                val escaped = escapeCaretInLink(next)
                if (escaped == null) return null
                else return defaultWord(escaped)
            }

            var firstChar = getChar(current, next) ?: return defaultWord(current)
            if (firstChar == ' ') {
                val skipped = skipSpaces(current)
                current = skipped.first
                firstChar = skipped.second ?: return defaultWord(current)
            }

            log("AFTER SPACES ${current.toFullString()}")
            val isAlphaSegment = firstChar.isLetter()

            return skip(current, isAlphaSegment)
        }
    }
}

fun DocContext.getWords(guid: String): List<Word>? {
    val block = doc.safeGet(guid) ?: return null
    val text = block.plainText
    val words = mutableListOf<Word>()
    var i = 0
    var wordStart: Int? = null
    var word = ""

    while(i < text.length) {
        val char = text[i]

        if (char.isLetter()) {
            word += char
            wordStart = wordStart ?: i
        } else {
            if (word != "") {
                wordStart?.let {
                    words.add(Word(word, CRange(block.getPlainCaret(it), block.getPlainCaret(i))))
                }
                word = ""
                wordStart = null
            }
        }

        i++
    }

    return words
}

//fun DocContext.getWords(guid: String): List<Word> {
//    val block = get(guid) ?: return emptyList()
//    var allWords = mutableListOf<Word>()
//
//    var cursor: Caret? = block.caretAtStart()
//
//    while(cursor != null && cursor.blockId == guid) {
//        val word = getNextWord(true, cursor)
//        if (word?.isAlpha() == true && word.range.right.blockId == guid) allWords += word
//        cursor = word?.range?.right
//    }
//
//    return allWords
//}

fun DocContext.caretToNextWord(isForward: Boolean): Caret? {
    return getNextWord(isForward)?.let { if (isForward) it.range.right else it.range.left }
}

interface CaretSelector<T: L2Element<T>> {
    fun get(guid: String): T?
    fun caretLeft(c: Caret?): Caret?
    fun caretRight(c: Caret?): Caret?
    fun caretLeftCellWide(c: Caret?): Caret?
    fun caretRightCellWide(c: Caret?): Caret?
    fun indexOfBlock(guid: String): Int
    fun caretLeftBlockWide(c: Caret?): Caret?
    fun caretRightBlockWide(c: Caret?): Caret?
}

// Should only be get by getCRange, never constructed manually
data class CRange(val left: Caret, val right: Caret) {
    fun getDOMRange(): Range? {
        val nLeft = left.getNodePosition()
        val nRight = right.getNodePosition()

        if (nLeft == null || nRight == null) return null

        val range = Range()

        range.setStart(nLeft.node, nLeft.offset)
        range.setEnd(nRight.node, nRight.offset)

        return range
    }

    fun getDOMRects(): List<DOMRect> {
        return getDOMRange()?.let { it.getClientRects().toList() } ?: emptyList()
    }
}

fun CaretSelector<Block>.getOrdered(
    a: Caret,
    b: Caret
): Pair<Caret, Caret> {
    fun response(isOrdered: Boolean): Pair<Caret, Caret> {
        return if (isOrdered) Pair(a, b) else Pair(b, a)
    }

//    fun blockIndex(c: Caret): Int {
//        return blocks.indexOfFirst { it.guid == c.blockId }
//    }

    if (a == b) return response(true)

    val mutual = a.getMutualPath(b)

    if (mutual.isEmpty()) return response(indexOfBlock(b.blockId) > indexOfBlock(a.blockId))

    if (mutual.size == a.path.size)
        return response(b.offset > a.offset)

    val commonBlock = get(a.blockId) ?: throw BugException("caret blockId=${a.blockId} is not in chain")
    val lastMutualContainerId = mutual.last()
    val lastMutualContainer = if (mutual.size == 1) commonBlock else commonBlock.find(lastMutualContainerId) as? IParagraph
        ?: throw BugException("caret containerId=${lastMutualContainerId} is not in chain")
    val differentElementIndex = mutual.size

    val aIndex = lastMutualContainer.elements.indexOfFirst { it.guid == a.path[differentElementIndex] }
    val bIndex = lastMutualContainer.elements.indexOfFirst { it.guid == b.path[differentElementIndex] }

    return response(bIndex > aIndex)
}

fun CaretSelector<Block>.getCRange(
    a: Caret,
    b: Caret,
): CRange? {
    val caretPair = getOrdered(a, b)

    val left = caretPair.first
    val right = caretPair.second

    if (left == right) return null

    if (left.rootType != right.rootType) return null
    if (left.rootType == CaretRoot.TABLE && left.cellId != right.cellId) return null

    val leftBlock = get(left.blockId) ?: throw BugException("Can't find range left block")
    val leftSpan = leftBlock.caretSpan(left)
    val rightBlock = get(right.blockId) ?: throw BugException("Can't find range right block")
    val rightSpan = rightBlock.caretSpan(right)

    // not links, return
    if (!leftSpan.isLink() && !rightSpan.isLink()) return CRange(left, right)

    var updatedLeft: Caret? = left
    var updatedRight: Caret? = right

    fun escapeLeft(c: Caret): Caret? {
        return when(c.rootType) {
            CaretRoot.TABLE -> caretLeftCellWide(c.copy(offset = 0))
            CaretRoot.PARAGRAPH -> caretLeft(c.copy(offset = 0))
            else -> null
        }
    }

    fun escapeRight(c: Caret): Caret? {
        return when(c.rootType) {
            CaretRoot.TABLE -> caretRightCellWide(c.copy(offset = rightSpan.lastOffset))
            CaretRoot.PARAGRAPH -> caretRight(c.copy(offset = rightSpan.lastOffset))
            else -> null
        }
    }

    // same span, link
    if (leftSpan.guid == rightSpan.guid && leftSpan.isLink()) {
        // same span,link, full span selection
        if (left.offset == 0 && right.offset == leftSpan.lastOffset) {
            updatedLeft = escapeLeft(left)
            updatedRight = escapeRight(right)

            return updatedRight?.let { r ->
                updatedLeft?.let { l ->
                    if (l == r) null
                    else CRange(l, r)
                }
            }
        } else return null
    }
    // left is link, escape
    if (leftSpan.isLink()) {
//        console.log("Left caret is link escape left")
        updatedLeft = escapeLeft(left)
    }

    // right is link, escape
    if (rightSpan.isLink()) {
//        console.log("Right caret is link, escape right ${right.toFullString()}")
        updatedRight = escapeRight(right)
//        console.log("Right escaped to ${updatedRight?.toFullString()}")
    }

    return updatedLeft?.let { l ->
        updatedRight?.let { r ->
            if (l == r) null
            else CRange(l, r)
        }
    }
}

data class TextNodePosition(val node: Node, val offset: Int)

fun Caret.getNodePosition(): TextNodePosition? {
    val el = window.document.getElementById(spanId) ?: return null
    val nodes = el.childNodes.asList().filterIsInstance<Text>()

    var target: Node? = null
    var currentOffset = offset
    var nodeIndex = 0

    while(nodeIndex < nodes.size && target == null) {
        val node = nodes[nodeIndex]
        val text = node.textContent ?: ""
        // FIXME: remove invisible from doc
        val cleanedText = text.filter { it != invisibleNBSP }

        if (currentOffset > cleanedText.length) {
            currentOffset -= cleanedText.length
        } else {
            target = node

            // Fix invisible space offset
            if (cleanedText.length < text.length) {
                var realOffsetIndex = 0
                var offsetIndex = 0

                while(realOffsetIndex < currentOffset) {
                    val char = text[offsetIndex]
                    if (char != invisibleNBSP) realOffsetIndex++

                    offsetIndex++
                }

                val correction = offsetIndex - realOffsetIndex
                currentOffset += correction
            }
        }

        nodeIndex++
    }

    return target?.let { TextNodePosition(it, currentOffset) }
}