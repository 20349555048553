package views

import Router
import androidx.compose.runtime.*
import controls.*
import editor.*
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock.System.now
import kotlinx.datetime.Instant
import net.sergeych.cloudoc.LocalEvent
import net.sergeych.cloudoc.api.ApiEvent
import net.sergeych.cloudoc.doc.Cloudoc
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.dom.*
import tools.localUrl
import views.DocSaveState.*

enum class DocSaveState {
    Unchanged, Dirty, Saving, Saved, SaveFailed
}

@Composable
fun EditHeading(
    doc: Cloudoc,
    context: DocContext,
    renderMode: RenderMode = RenderMode.EDITOR,
) {
    val scope = rememberCoroutineScope()
    var title by remember { mutableStateOf(doc.title) }
    var hasShare by remember { mutableStateOf<Boolean?>(null) }
    var docSaveState: DocSaveState by remember { mutableStateOf(Unchanged) }
    var docStateChangedAt: Instant by remember { mutableStateOf(now()) }
    var docSavedAt: Instant by remember { mutableStateOf(doc.updatedAt) }

    LaunchedEffect(true) {
        doc.events().collect {
            when (it) {
                is ApiEvent.Doc.MetaChanged, is ApiEvent.Doc.BodyChanged -> title = doc.title
                is ApiEvent.Doc.ShareChanged -> hasShare = it.isShared
                else -> {}
            }
        }
    }
    if (renderMode.isInteractive) LaunchedEffect(doc.docId) {
        hasShare = doc.getPublicShare() != null || doc.sharedWith().isNotEmpty()
    }

    LaunchedEffect(doc.docId) {
        doc.localEvents().collect { ev ->
            docStateChangedAt = now()
            when (ev) {
                is LocalEvent.Doc.Dirty -> docSaveState = Dirty
                is LocalEvent.Doc.SaveFailed -> docSaveState = SaveFailed
                is LocalEvent.Doc.Saving -> docSaveState = Saving
                is LocalEvent.Doc.Saved -> {
                    docSaveState = Saved
                    if(renderMode.isInteractive) docSavedAt = now()
                }
                else -> {}
            }
        }
    }

    Di("fixed-top p-2 shadow-sm bg-body") {
        Div({
            style {
                width(100.percent)
                position(Position.Relative)
                overflow("hidden")
            }
        }) {
            Table({
                style { width(100.percent) }
            }) {
                Tr {
                    Td({
                        attr("width", "1%")

                        style {
                            whiteSpace("nowrap")
                        }
                    }) {
                        Di("h2 mb-0", {
                            style {
                                cursor("pointer")
                            }
                            onClick { Router.push("/") }
                        }) {
//                            Img("/birch1.png") {
//                                style {
//                                    height(1.3.em)
//                                }
//                            }
//                            Di("d-inline d-sm-none") {
//                                Text("β: ")
//                            }
//                            Di("d-none d-sm-inline") {
//                                Text("βереста: ")
//                            }
                            Icon.ArrowLeft.render()
                            Icon.RichText.render({
                                style { height(1.2.em) }
                                classNames("ms_1 me_1 d-none d-sm-inline")
                            })
                        }
                    }
                    Td({
                        style {
                            property("max-width", "0")
                            whiteSpace("nowrap")
                            overflow("hidden")
                            property("text-overflow", "ellipsis")
                        }
                    }) {
                        Di("h2 mb-0", {
                            style {
                                width(100.percent)
                                whiteSpace("nowrap")
                                overflow("hidden")
                                property("text-overflow", "ellipsis")
                            }

                        }) {
                            title?.let { Text(it) }
                                ?: Span({ classNames("text-muted") }) { Text(doc.titleOrDefault) }
                            Spa("d-none d-sm-inline text-secondary fs-6") {
                                if( docSaveState == Dirty )
                                    +" изменён"
                                else {
                                    +" сохранён "
                                    RelativeTime(docSavedAt)
                                }
                            }
                        }

                    }
                    Td({
                        attr("width", "1%")
                        style {
                            whiteSpace("nowrap")
                        }
                    }) {
                        if (renderMode.isInteractive) {
                            Di("shadow-sm p-1 bg-body rounded d-inline-block position-relative cursor-pointer",
                                {
                                    onClick {
                                        it.preventDefault()
                                        scope.launch {
                                            context.search.open(false)
                                        }
                                    }
                                }) {
                                Icon.Search.render(1.2.em)
                            }
//                            Di("shadow-sm p-1 bg-body rounded cursor-pointer d-inline-block", {
//                                onClick {
//                                    collaboratorsDialog(doc)
//                                }
//                            }) {
//                                (if (hasShare == true) Icon.People else Icon.PeesonAdd).render(1.5.em)
//                            }
                            Di("shadow-sm p-1 bg-body rounded d-inline-block position-relative", {
                                when (docSaveState) {
                                    SaveFailed -> classes("text-danger")
                                    Saved -> classes("text-success")
                                    else -> {}
                                }
                                style {
                                    whiteSpace("nowrap")
                                }
                            }) {
                                val icon = when (docSaveState) {
                                    Unchanged -> Icon.CloudCheck
                                    Dirty -> Icon.CloudArrowUp
                                    Saving -> Icon.CloudUpload
                                    Saved -> Icon.CloudCheck
                                    SaveFailed -> Icon.CloudSlash
                                }
                                icon.render(1.5.em)
                            }
                            Di("shadow-sm p-1 bg-body rounded cursor-pointer d-inline-block", {
                                onClick {
                                    context.exportDOCX()
                                }
                            }) {
                                Icon.Download.render(1.5.em)
                            }
                            Di("shadow-sm p-1 bg-body rounded cursor-pointer d-inline-block", {
                                onClick {
                                    context.print()
                                }
                            }) {
                                Icon.Printer.render(1.5.em)
                            }
                            Di("shadow-sm p-1 bg-body rounded cursor-pointer d-inline-block", {
                                onClick {
                                    collaboratorsDialog(doc)
                                }
                            }) {
                                (if (hasShare == true) Icon.People else Icon.PeesonAdd).render(1.5.em)
                            }

                            Di("shadow-sm p-1 bg-body rounded cursor-pointer d-inline-block", {
                                onClick { e ->
                                    Router.push("/profile")
                                    e.preventDefault()
                                    e.stopPropagation()
                                }
                            }) {
                                Icon.Gear.render({ style { fontSize(1.5.em) } })
                            }
                        } else {
                            Di("shadow-sm p-1 bg-body rounded d-inline-block") {
                                Div({
                                    classNames("d-none d-sm-inline me-1")
                                    style {
                                        property("vertical-align", "text-top")
                                        property("font-size", "smaller")
                                        position(Position.Relative)
                                        bottom(0.4.em)
                                    }
                                }) {
                                    Text("только чтение")
                                }
                                Icon.Eye.render(1.5.em)
                            }
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun anonymousShare(doc: Cloudoc) {
    var publicShare by remember { mutableStateOf<String?>(null) }
    var loading by remember { mutableStateOf(true) }

    LaunchedEffect(true) {
        Toaster.launchCatching {
            publicShare = doc.getPublicShare()?.let { localUrl(it) }
        }
        loading = false
    }
    if (loading)
        WaitPanel("Загружаю данные")
    else {
        publicShare?.let { share ->
            Di("text-center") {
                Text("разрешен анонимный просмотр по секретной ссылке:")
                Copyable(share, maxLength = 24)
                Di("mt-2") {
                    Btn("удалить ссылку", Icon.ExclamationTriangle, Variant.Danger) {
                        loading = true
                        Toaster.launchCatching({ loading = false }) {
                            doc.deletePublicShare()
//                                    info("публичная ссылка удалена" )
                            publicShare = null
                        }
                    }
                }
            }
        } ?: run {
            Di("text-center") {
                Text("Публичный анонимный доступ запрещен. ")
                Br {}
                Btn(
                    "создать публичную ссылку", Icon.ExclamationTriangle, Variant.Secondary,
                    cls = "mt-1"
                ) {
                    Toaster.launchCatching({ loading = false }) {
                        loading = true
                        publicShare = localUrl(doc.createPublicShare())
                    }
                }
            }
        }
    }

}

//fun showDocSharing(doc: Cloudoc) {
//    modalDialg("Совместный доступ") {
//        body {
//            anonymousShare(doc)
//        }
//    }
//}
