package editor

import Browser
import document.BugException
import document.TextStyle
import kotlinx.browser.window
import org.w3c.dom.events.KeyboardEvent
import views.TextStyleMixin

enum class Mod(
    private val modName: String? = null,
    val symbol: Char? = null,
    val isOn: (ev: KeyboardEvent) -> Boolean
) {
    CTRL("Ctrl", isOn = { ev -> ev.ctrlKey }),
    CMD("Cmd", '⌘', isOn = { ev -> ev.metaKey }),
    ALT("Alt", isOn = { ev -> ev.altKey }),
    OPTION("Option", isOn = { ev -> ev.altKey }),
    SHIFT("Shift", isOn = { ev -> ev.shiftKey }),
    TAB("Tab", isOn = { ev -> ev.key == "Tab" }),
    ENTER("Enter", isOn = { ev -> ev.key == "Enter" }),
    BACKSPACE("Backspace", '⌫', isOn = { ev -> ev.key == "Backspace" }),
    ALEFT("ArrowLeft", '←', isOn = { ev -> ev.key == "ArrowLeft" }),
    ARIGHT("ArrowRight", '→', isOn = { ev -> ev.key == "ArrowRight" }),
    AUP("ArrowUp", '↑', isOn = { ev -> ev.key == "ArrowUp" }),
    ADOWN("ArrowDown", '↓', isOn = { ev -> ev.key == "ArrowDown" }),
    SPACE("Space", isOn = { ev -> ev.key == " " }),
    ESCAPE("Esc", isOn = { ev -> ev.key == "Escape" }),

    A("A", isOn = { ev -> ev.code == "KeyA" }),
    B("B", isOn = { ev -> ev.code == "KeyB" }),
    C("C", isOn = { ev -> ev.code == "KeyC" }),
    F("F", isOn = { ev -> ev.code == "KeyF" }),
    I("I", isOn = { ev -> ev.code == "KeyI" }),
    J("J", isOn = { ev -> ev.code == "KeyJ" }),
    K("K", isOn = { ev -> ev.code == "KeyK" }),
    P("P", isOn = { ev -> ev.code == "KeyP" }),
    U("U", isOn = { ev -> ev.code == "KeyU" }),
    V("V", isOn = { ev -> ev.code == "KeyV" }),
    X("X", isOn = { ev -> ev.code == "KeyX" }),
    Z("Z", isOn = { ev -> ev.code == "KeyZ" }),

    N0("0", isOn = { ev -> ev.code == "Digit0" }),
    N1("1", isOn = { ev -> ev.code == "Digit1" }),
    N2("2", isOn = { ev -> ev.code == "Digit2" }),
    N3("3", isOn = { ev -> ev.code == "Digit3" }),
    N4("4", isOn = { ev -> ev.code == "Digit4" }),


    SINGLE(isOn = { ev -> ev.key.length == 1});

    fun help(): String? {
        return symbol?.toString() ?: modName
    }
}

class Combo(val modsOn: Set<Mod> = emptySet()) {
    private fun platformSpecific(mod: Mod, isOSX: Boolean): Mod {
        if (!isOSX) return mod

        return when(mod) {
            Mod.CTRL -> Mod.CMD
            Mod.ALT -> Mod.OPTION
            else -> mod
        }
    }

    fun check(ev: KeyboardEvent, mod: Mod, isOSX: Boolean): Boolean {
        if (!isOSX) return mod.isOn(ev)

        return mod.isOn(ev) || platformSpecific(mod, isOSX).isOn(ev)
    }

    fun isOn(ev: KeyboardEvent, isOSX: Boolean): Boolean {
        val modifiers = setOf(Mod.CTRL, Mod.ALT, Mod.SHIFT, Mod.CMD, Mod.OPTION)
        val shouldBeOff = (modifiers - modsOn).toMutableSet()

        if (isOSX) {
            if (modsOn.contains(Mod.CTRL)) shouldBeOff -= Mod.CMD
            if (modsOn.contains(Mod.ALT)) shouldBeOff -= Mod.OPTION
        }

        return modsOn.find { !check(ev, it, isOSX) } == null && shouldBeOff.find { check(ev, it, isOSX) } == null
    }

    fun getName(isOSX: Boolean): String {
        val local = modsOn.map { platformSpecific(it, isOSX) }

        return local.mapNotNull { it.help() }.joinToString("+")
    }

    constructor(mod: Mod): this(setOf(mod))
}

data class ShortcutResult(
    val shouldPropagate: Boolean = false
)

class Shortcut(
    val name: String,
    val combo: Combo,
    val isEnabled: Boolean = true,
    val action: suspend (dc: DocContext, ev: KeyboardEvent) -> ShortcutResult?
) {
    suspend fun run(dc: DocContext, ev: KeyboardEvent, isOSX: Boolean): ShortcutResult? {
        if (combo.isOn(ev, isOSX)) return action(dc, ev)
        return null
    }

    companion object {
        fun simple(
            name: String,
            combo: Combo,
            isEnabled: Boolean = true,
            action: suspend (dc: DocContext) -> Unit
        ): Shortcut {
            return Shortcut(name, combo, isEnabled) { dc, _ ->
                action(dc)

                return@Shortcut ShortcutResult()
            }
        }

        fun simpleEvent(
            name: String,
            combo: Combo,
            action: suspend (dc: DocContext, ev: KeyboardEvent) -> Unit
        ): Shortcut {
            return Shortcut(name, combo) { dc, ev ->
                action(dc, ev)

                return@Shortcut ShortcutResult()
            }
        }

        fun propagated(
            name: String,
            combo: Combo,
            action: suspend (dc: DocContext) -> Boolean
        ): Shortcut {
            return Shortcut(name, combo) { dc, _ ->
                val shouldPropagate = action(dc)

                if (shouldPropagate) ShortcutResult(true)
                else null
            }
        }
    }
}

class Group(val name: String, val shortcuts: List<Shortcut>)

val system = Group("System", listOf(
    Shortcut.propagated("Выход из меню help", Combo(Mod.ESCAPE)) { _ ->
        // Close help panel if it is open:
        val i = js("bootstrap.Offcanvas.getInstance('#helpKeys')")
        if (i != null)
            js("i.hide()")
        // let esc be processed by others
        return@propagated true
    },

    Shortcut.simple("Каретка влево", Combo(Mod.ALEFT)) { dc -> dc.onArrowLeft(false) },
    Shortcut.simple("Каретка вправо", Combo(Mod.ARIGHT)) { dc -> dc.onArrowRight(false) },

    Shortcut.simple("Каретка вверх", Combo(Mod.AUP)) { dc -> dc.onArrowUp(isShift = false, false) },
    Shortcut.simple("Каретка вниз", Combo(Mod.ADOWN)) { dc -> dc.onArrowDown(isShift = false, false) },

    Shortcut.simple("Каретка влево с выделением",
        Combo(setOf(Mod.SHIFT, Mod.ALEFT))
    ) { dc -> dc.onArrowLeft(true) },

    Shortcut.simple("Каретка вправо с выделением",
        Combo(setOf(Mod.SHIFT, Mod.ARIGHT))
    ) { dc -> dc.onArrowRight(true) },

    Shortcut.simple("Каретка вверх с выделением",
        Combo(setOf(Mod.SHIFT, Mod.AUP))
    ) { dc -> dc.onArrowUp(isShift = true, false) },

    Shortcut.simple("Каретка вниз с выделением",
        Combo(setOf(Mod.SHIFT, Mod.ADOWN))
    ) { dc -> dc.onArrowDown(isShift = true, false) },

    Shortcut.simple("К предыдущему слову с выделением",
        Combo(setOf(Mod.ALT, Mod.SHIFT, Mod.ALEFT))
    ) { dc -> dc.onArrowLeftAlt(true) },

    Shortcut.simple("К следующему слову с выделением",
        Combo(setOf(Mod.ALT, Mod.SHIFT, Mod.ARIGHT))
    ) { dc -> dc.onArrowRightAlt(true) },

    Shortcut.simple("В начало абзаца с выделением",
        Combo(setOf(Mod.CTRL, Mod.SHIFT, Mod.ALEFT))
    ) { dc -> dc.onArrowLeftCtrl(true) },

    Shortcut.simple("В конец абзаца с выделением",
        Combo(setOf(Mod.CTRL, Mod.SHIFT, Mod.ARIGHT))
    ) { dc -> dc.onArrowRightCtrl(true) },

    Shortcut.simple("К предыдущему абзацу с выделением",
        Combo(setOf(Mod.CTRL, Mod.SHIFT, Mod.AUP))
    ) { dc -> dc.onArrowUp(isShift = true, true) },

    Shortcut.simple("К следующему абзацу с выделением",
        Combo(setOf(Mod.CTRL, Mod.SHIFT, Mod.ADOWN))
    ) { dc -> dc.onArrowDown(isShift = true, true) },


    Shortcut.simpleEvent("Ввод символа",
        Combo(setOf(Mod.SINGLE))
    ) { dc, ev ->
        val shouldIndent = dc.caretIsAtParagraphStart() && ev.key == " "
        if (!shouldIndent) dc.insertChar(ev.key)
        else {
            dc.changeFirstLineIndent(1)
        }
      },

    Shortcut.simpleEvent("Ввод символа",
        Combo(setOf(Mod.SHIFT, Mod.SINGLE))
    ) { dc, ev ->
        dc.insertChar(ev.key)
    },

    Shortcut.simple("Удалить символ",
        Combo(Mod.BACKSPACE)
    ) { dc ->
        if (!dc.caretIsAtParagraphStart()) dc.onBackspace(false)
        else {
            val b = dc.caretBlock()

            val shouldIndent = (b.paragraphStyle?.indentLevel ?: 0) > 0
            val shouldIndentFirst = (b.paragraphStyle?.firstLineIndent ?: 0) > 0

            if (shouldIndent) dc.changeIndent(-1)
            else if (shouldIndentFirst) dc.changeFirstLineIndent(-1)
            else dc.onBackspace(false)
        }
      },

    Shortcut.simple("Переход на новую строку", Combo(Mod.ENTER)) { dc -> dc.splitBlockAtCaret() }
))

val common = Group("Общие", listOf(
    Shortcut.simpleEvent("Поиск", Combo(setOf(Mod.CTRL, Mod.F))) { dc, ev ->
        ev.preventDefault()
//        dc.openSearch(false)
        dc.search.open(false)
                                                                 },

    Shortcut.simpleEvent("Поиск с заменой", Combo(setOf(Mod.CTRL, Mod.SHIFT, Mod.F))) { dc, ev ->
        ev.preventDefault()
//        dc.openSearch(true)
        dc.search.open(true)
                                                                                      },

    Shortcut.simpleEvent("Печать", Combo(setOf(Mod.CTRL, Mod.P))) { dc, ev ->
        ev.preventDefault()
        dc.print() },
))

val navigation = Group("Навигация", listOf(

    Shortcut.simple("К предыдущему слову",
        Combo(setOf(Mod.ALT, Mod.ALEFT))
    ) { dc -> dc.onArrowLeftAlt(false) },

    Shortcut.simple("К следующему слову",
        Combo(setOf(Mod.ALT, Mod.ARIGHT))
    ) { dc -> dc.onArrowRightAlt(false) },

    Shortcut.simple("В начало абзаца",
        Combo(setOf(Mod.CTRL, Mod.ALEFT))
    ) { dc -> dc.onArrowLeftCtrl(false) },

    Shortcut.simple("В конец абзаца",
        Combo(setOf(Mod.CTRL, Mod.ARIGHT))
    ) { dc -> dc.onArrowRightCtrl(false) },

    Shortcut.simple("К предыдущему абзацу",
        Combo(setOf(Mod.CTRL, Mod.AUP))
    ) { dc -> dc.onArrowUp(isShift = false, true) },

    Shortcut.simple("К следующему абзацу",
        Combo(setOf(Mod.CTRL, Mod.ADOWN))
    ) { dc -> dc.onArrowDown(isShift = false, true) },
))

val editing = Group("Редактирование", listOf(
    Shortcut.simple("Удалить слово влево от курсора",
        Combo(setOf(Mod.CTRL, Mod.BACKSPACE))
    ) { dc -> dc.onBackspace(true) }
))

val paragraphStyle = Group("Стиль параграфа", listOf(
    Shortcut.simple("Увеличить отступ", Combo(Mod.TAB)) { dc -> dc.onTab() },
    Shortcut.simple("Уменьшить отступ", Combo(setOf(Mod.SHIFT, Mod.TAB))) { dc -> dc.onShiftTab() },

    Shortcut.simple("Обычный текст",
        Combo(setOf(Mod.CTRL, Mod.ALT, Mod.N0))
    ) { dc -> dc.setParagraphTextStyle(TextStyle.default) },

    Shortcut.simple("Заголовок 1",
        Combo(setOf(Mod.CTRL, Mod.ALT, Mod.N1))
    ) { dc -> dc.setParagraphTextStyle(TextStyle.heading1) },

    Shortcut.simple("Заголовок 2",
        Combo(setOf(Mod.CTRL, Mod.ALT, Mod.N2))
    ) { dc -> dc.setParagraphTextStyle(TextStyle.heading2) },

    Shortcut.simple("Заголовок 3",
        Combo(setOf(Mod.CTRL, Mod.ALT, Mod.N3))
    ) { dc -> dc.setParagraphTextStyle(TextStyle.heading3) },

    Shortcut.simple("Заголовок 4",
        Combo(setOf(Mod.CTRL, Mod.ALT, Mod.N4))
    ) { dc -> dc.setParagraphTextStyle(TextStyle.heading4) },

    Shortcut.simple("Переключить выравнивание",
        Combo(setOf(Mod.CTRL, Mod.J))
    ) { dc -> dc.cycleParagraphTextAlign() }
))

val selection = Group("Работа с выделенным текстом", listOf(
    Shortcut.simple("Выделить все",
        Combo(setOf(Mod.CTRL, Mod.A))
    ) { dc -> dc.selectAll() },

    Shortcut.propagated("Копировать",
        Combo(setOf(Mod.CTRL, Mod.C))
    ) { dc ->
        dc.copy()
        true
      },

    Shortcut.propagated("Вырезать",
        Combo(setOf(Mod.CTRL, Mod.X))
    ) { dc ->
        dc.cutSelection()
        true
      },

    Shortcut.simple("Вставить",
        Combo(setOf(Mod.CTRL, Mod.V)),
        isEnabled = false
    ) { dc ->
        if (Browser.support(EXTENSION.CLIPBOARD_READ)) {
            Browser.clipboardReadText()?.let { dc.paste(it) }
        }
      },

    Shortcut.simple("Вставить без форматирования",
        Combo(setOf(Mod.CTRL, Mod.SHIFT, Mod.V)),
        isEnabled = false
    ) { dc ->
        if (Browser.support(EXTENSION.CLIPBOARD_READ)) {
            Browser.clipboardReadText()?.let { dc.paste(it, true) }
        }
      },

    Shortcut.simple("Выделить текст жирным",
        Combo(setOf(Mod.CTRL, Mod.B))
    ) { dc -> dc.toggleTextStyleMixin(TextStyleMixin.BOLD) },

    Shortcut.simple("Выделить текст курсивом",
        Combo(setOf(Mod.CTRL, Mod.I))
    ) { dc -> dc.toggleTextStyleMixin(TextStyleMixin.ITALICS) },

    Shortcut.simple("Выделить текст подчеркиванием",
        Combo(setOf(Mod.CTRL, Mod.U))
    ) { dc -> dc.toggleTextStyleMixin(TextStyleMixin.UNDERLINE) },
))

val undo = Group("Отмена/Возврат изменений", listOf(
    Shortcut.simple("Отменить последнее изменение",
        Combo(setOf(Mod.CTRL, Mod.Z))
    ) { dc -> dc.undo() },

    Shortcut.simple("Вернуть последнее изменение",
        Combo(setOf(Mod.CTRL, Mod.SHIFT, Mod.Z))
    ) { dc -> dc.redo() },
))

//val debug = Group("Разработка", listOf(
//    Shortcut.simple("Перерисовать блоки", Combo(listOf(Mod.SHIFT, Mod.TAB))) { ev, dc -> dc.onShiftTab() },
//))

val SHORTCUT_GROUPS = listOf(
    system,
    navigation,
    paragraphStyle,
    editing,
    selection,
    undo,
    common
)

class ShortcutManager(val dc: DocContext) {
    private val isOSX = window.navigator.platform.indexOf("Mac") >= 0
    val shortcuts = SHORTCUT_GROUPS.map { it.shortcuts }.flatten()

    fun getShortcut(name: String): String {
        return shortcuts.find { it.name == name }?.combo?.getName(isOSX) ?: throw BugException("can't find shortcut")
    }

    suspend fun handle(ev: KeyboardEvent): ShortcutResult? {
        var candidates = shortcuts.filter { it.combo.isOn(ev, isOSX) }

        val maxSize = candidates.maxByOrNull { it.combo.modsOn.size }

        if (maxSize == null) return null

        candidates = candidates.filter { it.combo.modsOn.size == maxSize.combo.modsOn.size }

        if (candidates.size > 1) {
            val cs = candidates.joinToString(", ") { it.name }
            throw BugException("More than one shortcut on same combo: $cs")
        }

        val shortcut = candidates.first()
        console.log("[SHORTCUT]: ${shortcut.name}")
        val result = if (shortcut.isEnabled) shortcut.run(dc, ev, isOSX) else ShortcutResult(true)

        return result
    }
}