package views

import androidx.compose.runtime.*
import controls.Col
import controls.Di
import controls.Row
import controls.textField
import net.sergeych.merge3.MergedBlock
import net.sergeych.merge3.merge3
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun TestMerge() {
    var src by remember { mutableStateOf("Hello, world!") }
    var a by remember { mutableStateOf("Hello, friend!") }
    var b by remember { mutableStateOf("Bye, world!") }
    Di("container") {
        Row {
            Col {
                H1 { Text("Test the merge") }
                textField(src, "source") {
                    src = it
                }
                textField(a, "A") {
                    a = it
                }
                textField(b, "B") {
                    b = it
                }
                H4 { Text("Merged:") }
                val x = merge3(src.toList(), a.toList(), b.toList())
                Di("border border-success p-2 mb-2 rounded-3") {
                    Text(x.merged.joinToString(""))
                }
                Di("border border-success p-2 mb-2 rounded-3") {
                    for (block in x.blocks) {
                        Span({
                            if (block !is MergedBlock.Unchanged) {
                                classes("bg-warning")
                            }
                        }) {
                            Text(block.data.joinToString(""))
                        }
                    }
                }
            }
        }
    }
}