package controls

import Router
import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLButtonElement

fun AttrsScope<HTMLButtonElement>.primary(vararg clsNames: String) {
    classes(clsNames.toList() + "btn-primary")
}

fun AttrsScope<HTMLButtonElement>.secondary(vararg clsNames: String) {
    classes(clsNames.toList() + "btn-secondary")
}

@Suppress("unused")
fun AttrsScope<HTMLButtonElement>.light(vararg clsNames: String) {
    classes(clsNames.toList() + "btn-light")
}

@Suppress("unused")
fun AttrsScope<HTMLButtonElement>.dark(vararg clsNames: String) {
    classes(clsNames.toList() + "btn-dark")
}

fun AttrsScope<HTMLButtonElement>.info(vararg clsNames: String) {
    classes(clsNames.toList() + "btn-info")
}

fun AttrsScope<HTMLButtonElement>.success(vararg clsNames: String) {
    classes(clsNames.toList() + "btn-success")
}

@Composable
fun Bn(attrBuilderContext: AttrBuilderContext<HTMLButtonElement>? = null, builder: ContentBuilder<HTMLButtonElement>) {
    Button({
        classes("btn")
        attrBuilderContext?.invoke(this)
    }) {
        builder()
    }
}

@Composable
fun Btn(
    text: String? = null,
    icon: Icon? = null,
    variant: Variant = Variant.Primary,
    size: BtnSize? = null,
    outline: Boolean = true,
    attr: AttrBuilderContext<HTMLButtonElement>? = null,
    cls: String? = null,
    me: Int? = null,
    ms: Int? = null,
    mt: Int? = null,
    mb: Int? = null,
    disabled: Boolean = false,
    content: ContentBuilder<HTMLButtonElement>? = null,
    whenClicked: (SyntheticMouseEvent) -> Unit
) {
    Button({
        classes("btn", variant.buttonClassFor(outline))
        attr?.invoke(this)
        size?.let { classes(it.className) }
        cls?.let { classNames(it) }
        ms?.let { classes("ms-$it") }
        me?.let { classes("me-$it") }
        mt?.let { classes("mt-$it") }
        mb?.let { classes("mb-$it") }
        if (disabled) disabled()
        onClick {
            it.preventDefault()
            it.stopPropagation()
            whenClicked(it)
        }
    }) {
        icon?.render({
            if (text != null) classes("me-2")
        })
        text?.let { Text(it) }
        content?.invoke(this)
    }
}

@Composable
fun BtnLink(
    text: String? = null,
    icon: Icon? = null,
    tip: String? = null,
    variant: Variant = Variant.Primary,
    attrs: AttrBuilderContext<HTMLAnchorElement>? = null,
    alertLink: Boolean = false,
    whenClicked: (SyntheticMouseEvent) -> Unit
) {
    A("#", {
        classes("d-inline-block")
        attrs?.invoke(this)
        tip?.let { title(it) }
        style {
            cursor("pointer")
            if (text != null)
                property("border-bottom", "this solid")
        }
        onClick {
            it.preventDefault()
            it.stopPropagation()
            whenClicked(it)
        }
        if (alertLink) classes("alert-link")
        else classes(variant.textClass)
    }) {
        icon?.render {
            if (text != null) classes("me-2")
        }
        text?.let { Text(it) }
    }
}


@Composable
fun RouterLink(
    url: String,
    text: String? = null,
    attrs: AttrBuilderContext<HTMLAnchorElement>? = null,
    alertLink: Boolean = false,
    builder: ContentBuilder<HTMLAnchorElement>? = null,
) {
    A("#", {
        attrs?.invoke(this)
        if (alertLink) classes("alert-link")
        onClick { Router.push(url) }
    }) {
        text?.let { Text(it) }
        builder?.invoke(this)
    }

}
