package editor

import androidx.compose.runtime.*
import controls.classNames
import document.Fragment

import kotlinx.browser.document
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import modalDialg
import net.sergeych.boss_serialization_mp.BossEncoder
import net.sergeych.boss_serialization_mp.decodeBoss
import net.sergeych.mp_tools.decodeBase64
import net.sergeych.mp_tools.encodeToBase64
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.attributes.type
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.*
import org.w3c.dom.events.Event
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag
import tools.randomId
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

val ACCEPT = listOf(
    "image/png",
    "image/jpeg",
    "image/svg"
)

@Serializable
sealed class ImageFormat {
    @Serializable
    @SerialName("document.ImageFormat.Geometry")
    class Geometry(
        val width: Float,
        val height: Float,
        val x: Float,
        val y: Float,
        val scaleX: Float,
        val scaleY: Float,
        val rotate: Float
    ): ImageFormat()

    @Serializable
    @SerialName("imported")
    class Imported(): ImageFormat()
}

@Serializable
data class Image(
    val bin: ByteArray,
    val format: ImageFormat
) {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class.js != other::class.js) return false

        other as Image

        if (!bin.contentEquals(other.bin)) return false
        if (format != other.format) return false

        return true
    }

    override fun hashCode(): Int {
        var result = bin.contentHashCode()
        result = 31 * result + format.hashCode()
        return result
    }
}

@Composable
fun renderLinkedImage(
    fragment: Fragment.LinkedImage,
    attrBuilderContext: AttrBuilderContext<HTMLImageElement>?,
    dc: DocContext,
) {
    println("---- render image!! ---- ${fragment.url}")
    SideEffect { dc.selection.showDOM() }

    if (fragment.isVisible) {
        Img(fragment.url, "image", {
            classes("_image")
//        style?.buildAttributes(this)
//        fragment.textStyle?.buildAttributes(this)
            id(fragment.guid)
            attr("data-ftype", "image")
            attrBuilderContext?.invoke(this)
        })
    }
}


external interface CropperData {
    var x: Float
    var y: Float
    var width: Float
    var height: Float
    var scaleX: Float
    var scaleY: Float
    var rotate: Float
}
fun CropperData(): CropperData = js("{}")

suspend fun cropImage(source: ByteArray, format: ImageFormat) = suspendCoroutine { continuation ->
    if (format !is ImageFormat.Geometry) continuation.resume(source)
    else {
        val canvas = document.createElement("canvas") as HTMLCanvasElement
        canvas.width = format.width.toInt()
        canvas.height = format.height.toInt()

        val context = canvas.getContext("2d") as CanvasRenderingContext2D
        val image = Image()

        image.onload = {
            context.drawImage(
                image,
                format.x.toDouble(), format.y.toDouble(), format.width.toDouble(), format.height.toDouble(),
                0.0, 0.0, format.width.toDouble(), format.height.toDouble()
            )
            val result = canvas.toDataURL("image/png").split(";base64,")[1]

            image.remove()
            canvas.remove()


            continuation.resume(result.decodeBase64())
        }

        image.src = "data:image/png;base64, ${source.encodeToBase64()}"
    }
}

// DEPRECATED
@Composable
fun renderStoredImage(
    fragment: Fragment.StoredImage,
    attrBuilderContext: AttrBuilderContext<HTMLImageElement>?,
    dc: DocContext,
) {
    var imageFormat: ImageFormat by remember { mutableStateOf(fragment.format) }
    val url = URL.createObjectURL(
        Blob(listOf(fragment.bin).toTypedArray(), BlobPropertyBag("image/png"))
    )
    val scope = rememberCoroutineScope()

    SideEffect { dc.selection.showDOM() }

    fun renderEditModal() {
        fun jsCropper(img: Element, data: CropperData) {
            js("new Cropper(img, { zoomable: false, data: data })")
        }

        fun onCrop(e: Event) {
            val ce = e as CustomEvent
            val d = ce.detail.asDynamic()
//            console.log(d)
            imageFormat = ImageFormat.Geometry(
                d.width as Float,
                d.height as Float,
                d.x as Float,
                d.y as Float,
                d.scaleX as Float,
                d.scaleY as Float,
                d.rotate as Float
            )
        }

        modalDialg("Редактировать изображение") {
            body {
                val controlId: String = remember { randomId(17) }

                Div(attrs = { style { width(100.percent) } }) {
                    DisposableEffect(fragment.guid) {
                        val img = document.getElementById(controlId)

                        img?.addEventListener("crop", ::onCrop)

                        if (fragment.format is ImageFormat.Geometry) {
                            val f = fragment.format
                            img?.let {
//                                console.log("Attach JS cropper")
                                scope.launch {
                                    delay(200)
                                    jsCropper(it, CropperData().apply {
                                        width = f.width
                                        height = f.height
                                        x = f.x
                                        y = f.y
                                        scaleX = f.scaleX
                                        scaleY = f.scaleY
                                        rotate = f.rotate
                                    })
//                                    console.log("JS cropper attached")
                                }
                            }
                        }
//                        else {
//                            img?.let {
//                                scope.launch {
//                                    delay(200)
//                                    jsCropper(it, CropperData())
////                                    console.log("JS cropper attached")
//                                }
//                            }
//                        }

                        onDispose {
                            img?.removeEventListener("crop", ::onCrop)
                        }
                    }

                    Img(url, attrs = {
                        id(controlId)
                        style {
                            width(100.percent)
                            maxWidth(100.percent)
                        }
                    })
                }
            }
            footer {
                Button(attrs = {
                    type(ButtonType.Button)
                    classes("btn", "btn-danger")
                    attr("data-bs-dismiss", "modal")
                    onClick {
                        scope.launch { dc.deleteTerminalFragment(fragment.guid) }
                        close()
                    }
                }) { Text("Удалить") }
                Button(attrs = {
                    type(ButtonType.Button)
                    classes("btn", "btn-secondary")
                    attr("data-bs-dismiss", "modal")
                    onClick { close() }
                }) { Text("Отменить") }
                Button(attrs = {
                    type(ButtonType.Button)
                    classes("btn", "btn-primary")
                    onClick {
                        scope.launch { dc.updateTerminalFragment(fragment.copy(format = imageFormat)) }
                        close()
                    }
                }) { Text("Сохранить") }
            }
        }
    }

    if (fragment.isVisible) {
        when(fragment.format) {
            is ImageFormat.Geometry -> {
                Div(attrs = {
                    classes("_image")
                    style {
                        overflow("hidden")
                        width(fragment.format.width.px)
                        height(fragment.format.height.px)
                        maxWidth("100%")
                        cursor("pointer")
                    }
                    onClick {
                        renderEditModal()
                    }
                }) {
                    Img(url, "image") {
                        style {
                            marginLeft(-fragment.format.x.px)
                            marginTop(-fragment.format.y.px)
                        }
                        id(fragment.guid)
                        attr("data-ftype", "image")
                        attrBuilderContext?.invoke(this)
                    }
                }

            }
            is ImageFormat.Imported -> {
                Div(attrs = {
                    classes("_image")
                    style {
                        overflow("hidden")
                        maxWidth("100%")
                        cursor("pointer")
                    }
                    onClick {
                        renderEditModal()
                    }
                }) {
                    Img(url, "image") {
                        id(fragment.guid)
                        attr("data-ftype", "image")
                        attrBuilderContext?.invoke(this)
                    }
                }
            }

            else -> {}
        }
    }
}

fun objectURL(bin: ByteArray): String {
    return URL.createObjectURL(
        Blob(listOf(bin).toTypedArray(), BlobPropertyBag("image/png"))
    )
}

fun imgData64(bin: ByteArray): String {
    val encoded = bin.encodeToBase64()

    return "data:image/png;base64, $encoded"
}

fun imageEditModal(
    scope: CoroutineScope,
    imageBin: ByteArray,
    initialFormat: ImageFormat,
    onDelete: () -> Unit,
    onConfirm: (newFormat: ImageFormat) -> Unit
) {
    var format = initialFormat
    val url = objectURL(imageBin)

    fun jsCropper(img: Element, data: CropperData) {
        js("new Cropper(img, { zoomable: false, data: data, autoCropArea: true })")
    }

    val onCrop = { e: Event ->
        val ce = e as CustomEvent
        val d = ce.detail.asDynamic()

        format = ImageFormat.Geometry(
            d.width as Float,
            d.height as Float,
            d.x as Float,
            d.y as Float,
            d.scaleX as Float,
            d.scaleY as Float,
            d.rotate as Float
        )
    }

    modalDialg("Редактировать изображение") {
        body {
            val controlId: String = remember { randomId(17) }

            Div(attrs = { style { width(100.percent) } }) {

                Img("data:image/png;base64, ${imageBin.encodeToBase64()}", attrs = {
                    ref { el ->
                        val isLoaded = CompletableDeferred<Boolean>()
                        val onLoad: (e: Event?) -> Unit = { e: Event? -> isLoaded.complete(true) }

                        el.addEventListener("load", onLoad)
                        el.addEventListener("crop", onCrop)
                        if (el.complete) onLoad(null)

                        scope.launch {
                            isLoaded.await()
                            // FIXME: CropperJS still needs something to wait
                            delay(200)

                            when (format) {
                                is ImageFormat.Geometry -> {
                                    val f = format as ImageFormat.Geometry

                                    document.getElementById(controlId)?.let {
                                        jsCropper(it, CropperData().apply {
                                            width = f.width
                                            height = f.height
                                            x = f.x
                                            y = f.y
                                            scaleX = f.scaleX
                                            scaleY = f.scaleY
                                            rotate = f.rotate
                                        })
                                    }
                                }

                                is ImageFormat.Imported -> {
                                    jsCropper(el, CropperData())
                                }

                                else -> {}
                            }
                        }

                        onDispose {
                            el.removeEventListener("load", onLoad)
                            el.removeEventListener("crop", onCrop)
                        }
                    }
                    id(controlId)
                    style {
                        display(DisplayStyle.Block)
                        width(100.percent)
                        maxWidth(100.percent)
                    }
                })
            }
        }
        footer {
            Button(attrs = {
                type(ButtonType.Button)
                classes("btn", "btn-danger")
                attr("data-bs-dismiss", "modal")
                onClick {
                    onDelete()
                    close()
                }
            }) { Text("Удалить") }
            Button(attrs = {
                type(ButtonType.Button)
                classes("btn", "btn-secondary")
                attr("data-bs-dismiss", "modal")
                onClick { close() }
            }) { Text("Отменить") }
            Button(attrs = {
                type(ButtonType.Button)
                classes("btn", "btn-primary")
                onClick {
                    onConfirm(format)
                    close()
                }
            }) { Text("Сохранить") }
        }
    }
}

@Composable
fun renderImage(frame: Fragment.Frame, dc: DocContext, mode: RenderMode) {
    val image = frame.bin.decodeBoss<Image>()
    val imageFormat: ImageFormat by mutableStateOf(image.format)

    val scope = rememberCoroutineScope()
    var croppedSRC by remember { mutableStateOf<String?>(null) }

    LaunchedEffect(frame.guid, imageFormat) {
        val croppedBin = cropImage(image.bin, imageFormat)
        croppedSRC = imgData64(croppedBin)
    }

    SideEffect { dc.selection.showDOM() }

    if (frame.isVisible) {
        when(image.format) {
            is ImageFormat.Geometry, is ImageFormat.Imported -> {
                croppedSRC?.let {
                    Img(it, "image") {
                        ref { el ->
                            val callback: (e: Event?) -> Unit = { _ ->
                                dc.domObserver.complete(frame.guid)
                            }

                            if (mode.isExport) {
                                el.addEventListener("load", callback)
                                if (el.complete) callback(null)
                            }

                            onDispose {
                                if (mode.isExport) el.removeEventListener("load", callback)
                            }
                        }

                        style {
                            if (mode.isInteractive) cursor("pointer")
                            if (image.format is ImageFormat.Geometry && mode == RenderMode.DOCX) {
                                width(image.format.width.px)
                                height(image.format.height.px)
                            }

                            property("max-width", "100%")
                        }
                        if (mode.isInteractive) {
                            id(frame.guid)
                            attr("data-ftype", "image")
                            classNames("$DOCUMENT_CONTROL_CLASS $DOCUMENT_FRAME")

                            onClick {
                                imageEditModal(
                                    scope,
                                    image.bin,
                                    imageFormat,
                                    onDelete = {
                                        scope.launch { dc.deleteTerminalFragment(frame.guid) }
                                    },
                                    onConfirm = {
                                        val newBinary = BossEncoder.encode(image.copy(format = it))
                                        val updatedFrame = frame.copy(bin = newBinary)
                                        scope.launch { dc.updateTerminalFragment(updatedFrame) }
                                    }
                                )
                            }
                        }
                    }
                }
            }

            else -> {}
        }
    }
}