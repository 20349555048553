package editor

import document.Caret
import kotlinx.serialization.Serializable

@Serializable
data class Position(val blockId: String,val fragmentId: String,val offset: Int) {
    operator fun plus(i: Int): Position {
        if( offset + i < 0 )
            throw IndexOutOfBoundsException("negative offset")
        return copy(offset = offset + i)
    }
    operator fun minus(i: Int) = this + (-i)
}

fun Caret.position(): Position = Position(path[0], spanId, offset)