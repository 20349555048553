import androidx.compose.runtime.*
import controls.Btn
import controls.Di
import controls.Variant
import controls.WaitPanel
import kotlinx.browser.document
import kotlinx.coroutines.CompletableDeferred
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import net.sergeych.boss_serialization_mp.BossEncoder
import net.sergeych.boss_serialization_mp.decodeBoss
import net.sergeych.cloudoc.api.ApiAcceptance
import net.sergeych.cloudoc.api.ApiAgreement
import net.sergeych.cloudoc.api.CDApi
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.debug
import net.sergeych.mp_logger.exception
import net.sergeych.mp_logger.info
import net.sergeych.parsec3.defaultNamedStorage
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Hr
import org.jetbrains.compose.web.dom.Text
import tools.randomId

object AcceptManager : LogTag("ACPTM") {

    @Serializable
    data class AccInfo(val createdAt: Instant, val acceptedVersion: Int?)

    val ds = defaultNamedStorage("__accpt__")

    fun accept(code: String, version: Int) {
        ds[code] = BossEncoder.encode(AccInfo(Clock.System.now(), version))
    }

    fun revoke(code: String) {
        ds[code] = null
    }

    operator fun get(code: String): AccInfo? =
        ds[code]?.decodeBoss<AccInfo>()

    var acceptances = mutableMapOf<String, ApiAcceptance>()
    suspend fun checkAction(action: ApiAgreement.Action, doReload: Boolean = false): Boolean {

        if (doReload || acceptances.isEmpty()) reload()

        for (a in acceptances.values.toList()) {
            val ar = a.requiresUserDecision(action)
            info { "проверяем ${a.code}: ${a.acceptedVersion ?: "-"}: $ar" }
            if (ar) {
                val x = requestAcceptance(a)
                acceptances[a.code] = x
                if (!x.isAccepted) return false
            }
        }
        return true
    }

    suspend fun reload() {
        debug { "загружаем список необходимых соглашений" }
        try {
            debug { "load1"}
            acceptances = client.call(CDApi.acptList).map { a ->
                val b = if (a.agreement.action == ApiAgreement.Action.GuestAccess) {
                    get(a.agreement.code)?.let { i ->
                        a.copy(createdAt = i.createdAt, acceptedVersion = i.acceptedVersion)
                    } ?: a
                } else a
                a.code to b
            }.toMap().toMutableMap()
            debug { "список загружен, количество соглашений: ${acceptances.size}" }
        }
        catch(t: Throwable) {
            debug { "load err"}
            exception { "не удалось загрузить список соглашений" to t}
        }
    }

    fun retryRejected(action: ApiAgreement.Action) {
        acceptances.values.filter { it.agreement.action == action && it.isDeclined }.toList().forEach {
            acceptances[it.code] = it.toRevoked()
            revoke(it.code)
        }
    }

    suspend fun requestAcceptance(acpt: ApiAcceptance): ApiAcceptance {
        val result = CompletableDeferred<ApiAcceptance>()
        val agr = acpt.agreement

        modalDialg {
            xl()
            heading(agr.title)
            body {
                var loaded by remember { mutableStateOf(false) }
                val txtid = remember { randomId(17) }
                Div({
                    id(txtid)
                }) {
                    WaitPanel("загружаю текст документа")
                }
                if (loaded) {
                    Hr {}
                    if (agr.minimalVersion != null) {
                        Div {
                            Text("Для продолжения работы ребуется ваше согласие.")
                        }
                    }
                    Br {}
                    Di("text-center") {
                        Btn("Я СОГЛАСЕН", variant = Variant.Success) {
                            result.complete(acpt.toAccepted())
                            accept(acpt.code, acpt.agreement.version)
                            close()
                        }
                        Btn("Я ОТКАЗЫВАЮСЬ", variant = Variant.Warning, ms = 3) {
                            result.complete(acpt.toDeclined())
                            close()
                        }
                    }
                    Br{}
                    Di("small text-muted text-right w-100") {
                        Text("Версия документа: ${agr.version}")
                    }
                }
                LaunchedEffect(true) {
                    document.getElementById(txtid)?.innerHTML = acpt.agreement.html(client)
                    loaded = true
                }
            }
            onClose {
                if (result.isActive) result.complete(acpt.toDeclined())
            }
        }
        return result.await()
    }
}