package editor

import document.Caret
import kotlinx.coroutines.delay
import net.sergeych.cloudoc.api.DocState
import net.sergeych.cloudoc.doc.Cloudoc

fun DocContext.getState(): EditorState {
    val isOn = if (spellChecker != null) spellChecker.isOn else lastState?.isSpellCheckOn
    val exclude = if (spellChecker != null) spellChecker.exclude else lastState?.spellCheckExclude

    return EditorState(
        caret?.position(),
        isOn,
        exclude,
    )
}

//fun DocContext.getActualState(): EditorState {
//    val isOn = if (spellChecker != null) spellChecker.isOn else lastState?.isSpellCheckOn
//    val exclude = if (spellChecker != null) spellChecker.exclude else lastState?.spellCheckExclude
//
//    return EditorState(
//        caret?.position(),
//        isOn,
//        exclude,
//    )
//}

suspend fun DocContext.save(cloudoc: Cloudoc) {
    val ctx = this

    doc.withLock("dc.save") {
//        delay(5000)
        if (transactionMode != TransactionMode.INSTANT || !isActive.value) return@withLock

        withAsyncCaret {
            if (doc.isDirty()) doc.save(cloudoc, ctx)
            val actualState = getState()
            if (lastState != actualState) {
                cloudoc.saveState(DocState.pack(actualState))
                if (lastMove.position != actualState.caretPosition)
                    lastMove = lastMove.copy(position = actualState.caretPosition)
            }
        }
    }
}

suspend fun DocContext.saveState(cd: Cloudoc? = cloudoc) {
    if (cd == null) return

    val actualState = getState()

    if (lastState != actualState) {
        cd.saveState(DocState.pack(actualState))

        if (lastMove.position != actualState.caretPosition)
            lastMove = lastMove.copy(position = actualState.caretPosition)
    }
}

fun DocContext.getInitialCaret(pos: Position?): Caret? {
    if (pos == null) return caretToHome()

    val cBlock = doc.allBlocks.find { it.guid == pos.blockId } ?: return caretToHome()

    val cSpan = cBlock.find(pos.fragmentId) ?: return cBlock.caretAtStart()

    val offset = if (pos.offset > cSpan.lastOffset) cSpan.lastOffset else pos.offset

    return cBlock.caretAt(cSpan.guid, offset)
}

suspend fun DocContext.setState(state: EditorState?) {
    lastState = state
    val initialCaret = getInitialCaret(state?.caretPosition)
//    console.error(initialCaret?.toFullString())
    lastMove = lastMove.copy(position = initialCaret?.position())
    caret = initialCaret
    lastSavedChain = DocChain(doc.allBlocksCopy, initialCaret)
    replay.init(doc.allBlocksCopy, initialCaret)

    state?.spellCheckExclude?.let {
        spellChecker?.exclude = it
    }

    // REFACTOR
//    state?.isSpellCheckOn?.let {
//        spellChecker.turnOn()
//    }

    fixV1()
}
