package controls

enum class Variant {
    Primary,
    Secondary,
    Info,
    Success,
    Warning,
    Danger,
    Ligh,
    Dark,
    Link;

    val buttonClass by lazy {
        when(this) {
            Primary -> "btn-primary"
            Secondary -> "btn-secondary"
            Info -> "btn-info"
            Success -> "btn-success"
            Warning -> "btn-warning"
            Danger -> "btn-danger"
            Ligh -> "btn-light"
            Dark -> "btn-dark"
            Link -> "btn-link"
        }
    }

    val outlineButtonClass by lazy {
        buttonClass.replace("btn-", "btn-outline-")
    }

    fun buttonClassFor(outline: Boolean) = if( !outline ) buttonClass else outlineButtonClass

    val textClass by lazy {
        when(this) {
            Primary -> "text-primary"
            Secondary -> "text-secondary"
            Info -> "text-info"
            Success -> "text-success"
            Warning -> "text-warning"
            Danger -> "text-danger"
            Ligh -> "text-light"
            Dark -> "text-dark"
            Link -> "text-link"
        }
    }
}
