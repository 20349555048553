package views

import androidx.compose.runtime.*
import client
import controls.*
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import modalDialg
import net.sergeych.cloudoc.api.*
import net.sergeych.cloudoc.doc.Cloudoc
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import tools.rememberDebouncer
import kotlin.time.Duration.Companion.milliseconds

@Suppress("UnusedReceiverParameter")
@Composable
fun DialogScope.collabsForm(doc: Cloudoc) {
    var searchText by remember { mutableStateOf("") }
    var foundUsers by remember { mutableStateOf(listOf<ApiUser>()) }
    var newUser by remember { mutableStateOf<ApiUser?>(null) }
    var newRole by remember { mutableStateOf(DocRole.Reader) }
    val collabs = remember { mutableStateListOf<ApiMember>() }
    var loading by remember { mutableStateOf(true) }
    var waiting by remember { mutableStateOf(false) }
    val mutex = remember {  Mutex() }

    suspend fun search() {
        foundUsers = if (searchText.isBlank()) listOf()
        else {
            client.call(
                client.api.userFind,
                UserFindArgs(searchText, collabs.map { it.user.id })
            )
        }
    }

    suspend fun reload() {
        mutex.withLock {
            collabs.clear()
            val cc = doc.sharedWith().sortedBy { it.user.name }
            collabs.addAll(cc)
            loading = false
            waiting = false
            println("reloaded: ${collabs.toList()}")
        }
    }

    LaunchedEffect(true) {
        doc.events().collect() {
            if (it is ApiEvent.Doc.ShareChanged) reload()
        }
    }

    val debouncer = rememberDebouncer(500.milliseconds) { search() }

    if (newUser == null) {
        Di("position-relative") {
            H3 { Text("Совместный доступ:") }
            Button({
                classNames("btn btn-close position-absolute end-0 top-0 mt-1")
                attr("data-bs-dismiss","modal")
            }) { Text("") }
        }

        textField(searchText, "добавить", attrs = { id("addCollabsSearch") }) {
            searchText = it
            if (searchText == "") debouncer.executeNow() else debouncer.schedule()
        }

        if (loading || waiting) {
            WaitPanel("загружаем данные")
            if (loading) LaunchedEffect(true) { reload() }
        } else
            if (foundUsers.isEmpty() && collabs.isEmpty()) {
                Di("alert alert-secondary") {
                    Text("нет соавторов. Найдите нового соавтора при помощи поля выше.")
                }

                globalLaunch {
                    delay(600)
                    window.document.getElementById("addCollabsSearch")?.let {
                        (it as? HTMLInputElement)?.focus()
                    }
                }
            } else {


                Di("list-group", {
                    style {
                        maxHeight(11.em)
                        overflow("auto")
                    }
                }) {
                    if (foundUsers.isEmpty()) {
                        for (c in collabs) {
                            Di("list-group-item") {
                                Row {
                                    Col("7", {classes("position-relative")}) {
                                        Div({
                                            style {
                                                display(DisplayStyle.InlineBlock)
                                                position(Position.Relative)
                                                top(0.4.em)
                                            }
                                        }) {
                                            Text(c.user.name)
                                        }
                                    }
                                    Col("5") {
                                        Select({
                                            classes("form-select")
                                            onChange { ev ->
                                                globalLaunch {
                                                    waiting = true
                                                    val r = DocRole.valueOf(ev.value!!)
                                                    if (r == DocRole.Inaccessible) doc.deleteCollaboration(c.user)
                                                    else doc.updateCollaboration(c.user, r)
//                                                    waiting = false
//                                                    loading = true
                                                }
                                            }
                                        }) {
                                            for (r in DocRole.collaborators + DocRole.Inaccessible) {
                                                Option(r.name, {
                                                    if (r == c.role) selected()
                                                }) {
                                                    Text(r.text)
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        for (u in foundUsers) {
                            Di("list-group-item list-group-item-action", {
                                onClick {
                                    newUser = u
                                }
                            }) {
                                Text(u.name)
                            }
                        }
                    }
                }

            }
        Hr {}
        anonymousShare(doc)
    } else {
        val n = newUser!!
        H3 {
            Icon.ArrowLeft.render({
                onClick {
                    newUser = null
                }
                style {
                    cursor("pointer")
                }
                classes("me-2")
            })
            Text("предоставить доступ:")
        }
        Di("mb-3") {
            Label {
                Text("Выберите доступ для пользователя: ")
                B { Text(n.name) }
                Text(":")
            }
            newRole = DocRole.Writer
            Select({
                classes("form-select")
                onChange { newRole = DocRole.valueOf(it.value!!) }
            }) {
                for (r in DocRole.collaborators)
                    Option(r.name,{
                        if( r == newRole ) selected()
                    }) { Text(r.text) }
            }
        }
        Btn(
            "Предоставить доступ"
        ) {
            loading = true
            newUser?.let { user ->
                globalLaunch {
                    waiting = true
                    doc.updateCollaboration(user, newRole)
                    newUser = null
                    searchText = ""
                    foundUsers = listOf()
                }
            }
        }
    }
}

fun collaboratorsDialog(doc: Cloudoc) {

    modalDialg {
        body {
//            var mode by remember { mutableStateOf(Mode.ShowExisting) }
            collabsForm(doc)
        }
    }
}
