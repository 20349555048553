package tools

import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.MutableSharedFlow
import net.sergeych.mp_logger.LogTag
import net.sergeych.mp_logger.exception
import net.sergeych.mp_tools.globalLaunch

fun <T> MutableSharedFlow<T>.smartEmit(value: T): Job? {
    return if (tryEmit(value)) null
    else globalLaunch { emit(value) }
}


fun <T> runTracing(text: String = "ошибка:", f: () -> T): Result<T> =
    runCatching(f).also {
        it.exceptionOrNull()?.let { e ->
            LogTag("RUNC").exception { text to e }
        }
    }

@Suppress("unused")
suspend fun <T> asyncTracing(text: String = "ошибка:", f: suspend () -> T): Result<T> =
    try {
        Result.success(f())
    } catch (t: Throwable) {
        LogTag("RUNC").exception { text to t }
        Result.failure(t)
    }
