package controls

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import document.BugException
import kotlinx.coroutines.selects.select
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLLabelElement
import tools.randomId

interface SelectItem {
    val label: String
    val value: String
}

@Composable
fun selectFieldHeadless(
    value: SelectItem,
    options: List<SelectItem>,
    defaultOption: SelectItem? = null,
    controlId: String? = null,
    setter: (v: SelectItem) -> Unit
) {
    Select({
        if (controlId != null) id(controlId)
        classNames("form-select")
        onChange { ev ->
            val selected = options.find { it.value == ev.value } ?: throw BugException("select option not found")

            try {
                setter(selected)
            }
            catch(t: Throwable) {
                console.error("unexpected exception in input handler")
                t.printStackTrace()
            }
        }
    }) {
        console.log("SELECT OPT SIZE", options.size)
        options.forEach { option ->
            Option(option.value, {
                if (option == value) selected()
            }) { Text(option.label) }
        }
    }
}

@Composable
fun selectField(
    value: SelectItem,
    options: List<SelectItem>,
    defaultOption: SelectItem? = null,
    label: String? = null,
    labelContent: ContentBuilder<HTMLLabelElement>? = null,
    isValid: Boolean? = null,
    message: String? = null,
    additionalClasses: String? = "mb-3",
    floating: Boolean = true,
    setter: (v: SelectItem) -> Unit,
): String {
    val controlId: String = remember { randomId(17) }
    val hasLabel = label != null || labelContent != null

    Div({
        if (hasLabel && floating) classes("form-floating")
        additionalClasses?.let { classes(it) }
    }) {
        if (hasLabel && !floating) {
            Label(controlId) {
                label?.let { Text(it) }
                labelContent?.invoke(this)
            }
        }

        selectFieldHeadless(value, options, defaultOption, controlId) {
            setter(it)
        }

        if (hasLabel && floating) {
            Label(controlId) {
                label?.let { Text(it) }
                labelContent?.invoke(this)
            }
        }
        if (message != null) {
            Div({
                when (isValid) {
                    true -> classes("valid-feedback")
                    false -> classes("invalid-feedback")
                    else -> classes("small")
                }
            }) {
                Text(message)
            }
        }
    }

    return controlId
}