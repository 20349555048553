package views

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticMouseEvent
import booleanField
import controls.*
import document.*
import editor.*
import editor.Image
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import modalDialg
import net.sergeych.boss_serialization_mp.BossEncoder
import org.jetbrains.compose.web.attributes.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.*

import org.w3c.dom.events.Event
import org.w3c.dom.url.URL
import org.w3c.files.File
import tools.randomId

val TOOLBAR_ID = "context-toolbar"

enum class ParagraphTextStyle(val title: String, val style: TextStyle) {
    HEADING("Заголовок", TextStyle.heading),
    SUBHEADING("Подзаголовок", TextStyle.subheading),
    HEADING1("Заголовок 1", TextStyle.heading1),
    HEADING2("Заголовок 2", TextStyle.heading2),
    HEADING3("Заголовок 3", TextStyle.heading3),
    HEADING4("Заголовок 4", TextStyle.heading4),
    NORMAL("Обычный", TextStyle.default)
}

enum class TextStyleMixin(
    val title: @Composable () -> Unit,
    val enable: (t: TextStyle) -> TextStyle,
    val disable: (t: TextStyle) -> TextStyle,
    val isEnabled: (t: TextStyle) -> Boolean,
) {
    BOLD(
        { B { Text("Ж") } },
        { t -> t.copy(fontWeight = TextStyle.FontWeight.Bold) },
        { t -> t.copy(fontWeight = null) },
        { it.fontWeight == TextStyle.FontWeight.Bold }
    ),
    ITALICS(
        { I { Text("К") } },
        { t -> t.copy(italics = true) },
        { t -> t.copy(italics = null) },
        { it.italics == true }
    ),
    UNDERLINE(
        { Span(attrs = { style { textDecoration("underline") } }) { Text("П") } },
        { t -> t.copy(underline = true) },
        { t -> t.copy(underline = null) },
        { it.underline == true }
    );

    fun toggle(t: TextStyle): TextStyle {
        return if (isEnabled(t)) disable(t) else enable(t)
    }
}


@Composable
fun StyleMixinButton(
    currentStyle: TextStyle,
    mixin: TextStyleMixin,
    onChange: suspend (e: SyntheticMouseEvent) -> Unit
) {
    val scope = rememberCoroutineScope()
    var classNames = "btn btn-secondary"
    if (mixin.isEnabled(currentStyle)) classNames += " active"

    Button(attrs = {
        onClick { scope.launch { onChange(it) } }
        classes(*classNames.split(' ').toTypedArray())
    }) { mixin.title() }
}

@Composable
fun ListStyleButton(
    currentStyle: ParagraphStyle?,
    listStyle: ParagraphStyle.List,
    onChange: suspend (e: SyntheticMouseEvent) -> Unit
) {
    val scope = rememberCoroutineScope()
    var classNames = "btn btn-secondary"
    val isActive = currentStyle?.listStyle == listStyle
    if (isActive) classNames += " active"

    Button(attrs = {
        onClick { scope.launch { onChange(it) } }
        classes(*classNames.split(' ').toTypedArray())
    }) {
        when(listStyle) {
            ParagraphStyle.List.Bullets -> { Icon.ListBulleted.render({ style { fontSize(1.5.em) } }) }
            ParagraphStyle.List.Numbers -> { Icon.ListNumbered.render({ style { fontSize(1.5.em) } }) }
        }
    }
}

@Composable
fun AddImageButton(doc: Doc, dc: DocContext) {
    var imageFile: File? by remember { mutableStateOf(null) }
    var imageFormat: ImageFormat.Geometry? by remember { mutableStateOf(null) }
    val ctrlId = remember { randomId(11) }
    val imageId = remember { randomId(11) }
    val scope = rememberCoroutineScope()

    Button(attrs = {
        onClick {
            window.document.getElementById(ctrlId)?.let {
                (it as HTMLInputElement).click()
            }
        }
        classes("btn", "btn-secondary")
    }) { Icon.Image.render({ style { fontSize(1.5.em) } }) }

    Input(InputType.File) {
        attr("id", ctrlId)
        accept(ACCEPT.joinToString(", "))
        style { display(DisplayStyle.None) }
        // FIXME: need to set value
        onChange {
            val file = it.target.files?.asList()?.firstOrNull()

            imageFile = file
        }
    }

    imageFile?.let {
        modalDialg("Добавить изображение") {
            body {
                Div(attrs = { style { width(100.percent) } }) {
                    Img(URL.createObjectURL(it), attrs = {
                        style {
                            width(100.percent)
                            maxWidth(100.percent)
                            display(DisplayStyle.Block)
                        }
                        id(imageId)
                        ref {

                            fun jsCropper(img: Element) {
                                js("new Cropper(img, { zoomable: false })")
                            }

                            fun onCrop(e: Event) {
                                val ce = e as CustomEvent
                                val d = ce.detail.asDynamic()

                                imageFormat = ImageFormat.Geometry(
                                    d.width as Float,
                                    d.height as Float,
                                    d.x as Float,
                                    d.y as Float,
                                    d.scaleX as Float,
                                    d.scaleY as Float,
                                    d.rotate as Float
                                )
                            }

                            it.addEventListener("crop", ::onCrop)

                            scope.launch {
                                delay(200)
                                jsCropper(it)
                            }

                            onDispose {
                                it.removeEventListener("crop", ::onCrop)
                            }
                        }
                    })
                }
            }
            footer {
                Button(attrs = {
                    type(ButtonType.Button)
                    classes("btn", "btn-secondary")
                    attr("data-bs-dismiss", "modal")
                    onClick {
                        imageFile = null
                        close()
                    }
                }) { Text("Отменить") }
                Button(attrs = {
                    type(ButtonType.Button)
                    classes("btn", "btn-primary")
                    onClick {
                        scope.launch {
                            imageFile?.let { file ->
                                imageFormat?.let { format ->
                                    val imageBin = readFile(file)
                                    val image = Image(imageBin, format)
                                    val frame = Fragment.Frame("image", BossEncoder.encode(image))

                                    dc.insertTerminalFragmentAtCaret(frame)
                                }
                            }
                            imageFile = null
                            imageFormat = null
                            close()
                        }
                    }
                }) { Text("Добавить") }
            }
        }
    }
}

@Composable
fun AddTableButton(doc: Doc, dc: DocContext, isCaretInTable: Boolean) {
    val scope = rememberCoroutineScope()
    var showModal by remember { mutableStateOf(false) }
    var rows: Int? by remember { mutableStateOf(2) }
    var cols: Int? by remember { mutableStateOf(2) }
    var hasHeader by remember { mutableStateOf(false) }

    Button(attrs = {
        onClick {
            showModal = true
        }
        classes("btn", "btn-secondary")
        if (isCaretInTable) disabled()
    }) { Icon.Table.render({ style { fontSize(1.5.em) } }) }

    if (showModal) {
        dc.setIsActive(false)
        modalDialg("Добавить таблицу") {
            body {
                Div(attrs = {
                    style { width(100.percent) }
                    ref {
                        window.blur()
                        it.focus()
                        onDispose { it.blur() }
                    }
                }) {
                    booleanField(hasHeader, "Сделать первую строку заголовком", isSwitch = true) {
                        hasHeader = it
                    }
                    textField(
                        rows?.toString() ?: "",
                        "Количество строк"
                    ) {
                        console.log("rows changed", it)
                        rows = if (it != "") it.toInt() else null
                    }
                    textField(
                        cols?.toString() ?: "",
                        "Количество столбцов"
                    ) {
                        cols = if (it != "") it.toInt() else null
                    }
                }
            }
            footer {
                Button(attrs = {
                    type(ButtonType.Button)
                    classes("btn", "btn-secondary")
                    attr("data-bs-dismiss", "modal")
                    onClick {
                        dc.setIsActive(true)
                        showModal = false
                        close()
                    }
                }) { Text("Отменить") }
                Button(attrs = {
                    type(ButtonType.Button)
                    if (rows == null || rows == 0 || cols == 0 || cols == null)
                        disabled()
                    classes("btn", "btn-primary")
                    onClick {
                        scope.launch {
                            dc.insertTable(rows ?: 0, cols ?: 0, hasHeader)
                            dc.setIsActive(true)
                            showModal = false
                            close()
                        }
                    }
                }) { Text("Добавить") }
            }
        }
    }
}

@Composable
fun TextStyleToolbar(doc: Doc, dc: DocContext) {
    var initialParagraphStyle: ParagraphStyle? = null
    var initialCaretStyle = TextStyle()
    var isCaretInTable by remember { mutableStateOf(false) }
    var isSpellCheckOn by remember { mutableStateOf(dc.spellChecker?.isOn ?: false) }

    dc.caret?.let {
        val caretContainer = dc.caretContainer(it)
        initialParagraphStyle = caretContainer.paragraphStyle
        initialCaretStyle = dc.getStyle(it)
        isCaretInTable = it.rootType == CaretRoot.TABLE
    }

    var paragraphStyle by remember { mutableStateOf(initialParagraphStyle) }
    var computedStyle by remember { mutableStateOf(initialCaretStyle) }
    val scope = rememberCoroutineScope()

    fun getParagraphTextStyle(paragraphStyle: ParagraphStyle?): ParagraphTextStyle? {
        val textStyle = paragraphStyle?.defaultTextStyle

        if (textStyle == null) return ParagraphTextStyle.NORMAL
        else return ParagraphTextStyle.values().find { it.style == textStyle }
    }

    fun updateStyles(b: Block, c: Caret?) {
        if (c == null) return
        val container = b.find(c.path[c.path.size - 2]) ?: return
        paragraphStyle = (container as Fragment.Paragraph).paragraphStyle
        isCaretInTable = c.rootType == CaretRoot.TABLE
        try {
            computedStyle = dc.selection.range?.let {
                dc.getStyle(it)
            } ?: run {
                dc.getStyle(c, b)
            }
        } catch(e: Exception) {
            dc.replay.onError("TOOLBAR: ${e}")
        }
    }

    LaunchedEffect("toolbarListeners") {
        doc.events.collect { e ->
            scope.launch {
                when (e) {
                    is Doc.Event.UpdateCaretBlock -> {
                        dc.doc.withLock("toolbar.updateStyles") {
                            updateStyles(e.block, e.caret)
                        }
                    }

                    else -> {}
                }
            }
        }
    }

    Div(attrs = {
        classes("btn-group")
        attr("role", "group")
        attr("aria-label", "")
        id(TOOLBAR_ID)
    }) {
        AddTableButton(doc, dc, isCaretInTable)
        AddImageButton(doc, dc)

        if (dc.spellChecker != null) {
            Button(attrs = {
                onClick {
                    (it.target as HTMLElement).blur()
                    scope.launch {
                        dc.doc.withLock("spellchecker.toggle") {
                            if (isSpellCheckOn) dc.spellChecker.turnOff()
                            else dc.spellChecker.turnOn()
                            isSpellCheckOn = !isSpellCheckOn
                        }
                    }
                }
                var css = "btn btn-secondary"
                if (isSpellCheckOn) css += " active"

                classNames(css)
            }) { Icon.SpellCheck.render({ style { fontSize(1.5.em) } }) }
        }

        Button(attrs = {
            onClick {
                (it.target as HTMLElement).blur()
                scope.launch { dc.cycleParagraphTextAlign() }
            }
            classes("btn", "btn-secondary")
        }) {
            when(paragraphStyle?.textAlign) {
                ParagraphStyle.TextAlign.Left -> { Icon.TextLeft.render({ style { fontSize(1.5.em) } }) }
                ParagraphStyle.TextAlign.Right -> { Icon.Textright.render({ style { fontSize(1.5.em) } }) }
                ParagraphStyle.TextAlign.Center -> { Icon.TextCenter.render({ style { fontSize(1.5.em) } }) }
                ParagraphStyle.TextAlign.Justify -> { Icon.TextJustify.render({ style { fontSize(1.5.em) } }) }
                null -> { Icon.TextLeft.render({ style { fontSize(1.5.em) } }) }
            }
        }

        Button(attrs = {
            onClick {
                (it.target as HTMLElement).blur()
                scope.launch { dc.changeIndent(-1) }
            }
            classes("btn", "btn-secondary")
        }) { Icon.ArrowBarLeft.render({ style { fontSize(1.5.em) } }) }
        Button(attrs = {
            onClick {
                (it.target as HTMLElement).blur()
                scope.launch { dc.changeIndent(+1) }
            }
            classes("btn", "btn-secondary")
        }) { Icon.ArrowBarRight.render({ style { fontSize(1.5.em) } }) }

        ParagraphStyle.List.values().forEach { listStyle ->
            ListStyleButton(paragraphStyle, listStyle) {
                (it.target as HTMLElement).blur()
                dc.toggleListStyle(listStyle)
            }
        }

        TextStyleMixin.values().forEach { mixin ->
            StyleMixinButton(computedStyle, mixin) {
                (it.target as HTMLElement).blur()
                dc.doc.withLock("toolbar.toggleTextStyleMixin") {
                    dc.toggleTextStyleMixin(mixin)
                    computedStyle = mixin.toggle(computedStyle)
                }
            }
        }

        Div(attrs = {
            classes("btn-group")
            attr("role", "group")
        }) {
            Button(attrs = {
                classes("btn", "btn-secondary", "dropdown-toggle")
                attr("data-bs-toggle", "dropdown")
                attr("aria-expanded", "false")
            }) {
                Text(getParagraphTextStyle(paragraphStyle)?.title ?: "Стиль параграфа")
            }

            Ul(attrs = { classes("dropdown-menu") }) {
                ParagraphTextStyle.values().forEach { s ->
                    Li {
                        Button(attrs = {
                            onClick {
                                (it.target as HTMLElement).blur()
                                scope.launch {
                                    dc.doc.withLock("toolbar.setParagraphStyle") {
                                        val newParagraphStyle =
                                            ParagraphStyle(name = s.name, defaultTextStyle = s.style)
                                        dc.setParagraphStyle(newParagraphStyle)
                                        paragraphStyle = newParagraphStyle
                                    }
                                }
                            }
                            classes("dropdown-item")
                        }) { Div(attrs = { s.style.buildAttributes(this) }) { Text(s.title) } }
                    }
                }
            }
        }
    }
}