package worker

import kotlinx.serialization.Serializable

@Serializable sealed interface WorkerMessage
@Serializable sealed interface Request<R : RequestResult> : WorkerMessage
@Serializable sealed interface RequestResult : WorkerMessage
@Serializable data class Response(val result: RequestResult? = null, val error: String? = null): WorkerMessage

@Serializable
data class CheckResult(val guid: String, val typos: List<String>): RequestResult
@Serializable
data class Check(val guid: String, val words: List<String>): Request<CheckResult>

@Serializable
data class CorrectionResult(val typo: String, val corrections: List<String>?): RequestResult
@Serializable
data class Correction(val typo: String): Request<CorrectionResult>

@Serializable
data class InitResult(val state: Boolean): RequestResult
@Serializable
data class Init(val debug: Boolean): Request<InitResult>

@Serializable
data class LoadDictionaryResult(val dictionarySRC: String, val state: Boolean): RequestResult
@Serializable
data class LoadDictionary(val dictionarySRC: String): Request<LoadDictionaryResult>