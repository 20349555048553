package views

import Router
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import client
import controls.CenteredForm
import controls.Di
import controls.WaitMessage

@Composable
fun Logout() {
    CenteredForm("Выход из системы") {
        Di("alert alert-primary") {
            WaitMessage("выполняется выход из учетной записи")
        }
    }
    LaunchedEffect(true) {
        try {
            client.logout()
        }
        catch(t: Throwable) {
            console.log("Logout error: $t")
            t.printStackTrace()
        }
        finally {
            Router.replace("/")
        }
    }
}