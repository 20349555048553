package editor

import androidx.compose.runtime.*
import net.sergeych.mp_logger.LogTag
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.dom.Text

val logger = LogTag("COMPOSER")

@Composable
fun ensureI(isItalics: Boolean, content: @Composable () -> Unit) {
    if (isItalics) I { content() }
    else content()
}

@Composable
fun ensureU(isUnderline: Boolean, content: @Composable () -> Unit) {
    if (isUnderline) U { content() }
    else content()
}



