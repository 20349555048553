package controls

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import kotlinx.browser.window
import net.sergeych.mp_logger.LogTag
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H5
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import tools.randomId
import tools.runTracing

open class DialogScope : LogTag("DLGS") {

    internal var dialog: dynamic = null

    private var _body: ContentBuilder<HTMLDivElement>? = null
    private var _header: ContentBuilder<HTMLDivElement>? = null
    private var _footer: ContentBuilder<HTMLDivElement>? = null
    fun heading(f: ContentBuilder<HTMLDivElement>) {
        _header = {
            H5({ classes("modal-title") }) {
                f()
            }
            if( !_staticBackdrop ) {
                Bn({
                    classes("btn-close")
                    attr("data-bs-dismiss", "modal")
                }) {}
            }
        }
    }

    fun heading(text: String) {
        heading { Text(text) }
    }

    private var sizeStyle: String? = null

    @Suppress("unused")
    fun lg() {
        sizeStyle = "modal-lg"
    }

    @Suppress("unused")
    fun sm() {
        sizeStyle = "modal-sm"
    }

    fun xl() {
        sizeStyle = "modal-xl"
    }

    fun body(f: ContentBuilder<HTMLDivElement>) {
        _body = f
    }

    fun footer(f: ContentBuilder<HTMLDivElement>) {
        _footer = f
    }

    var _closeHandler = mutableListOf<() -> Unit>()
    fun onClose(f: () -> Unit) {
        _closeHandler += f
    }

    internal fun fireClose() {
        _closeHandler.forEach { h -> runTracing { h() } }
    }

    open fun close() {
        @Suppress("UNUSED_VARIABLE") val d = dialog // this is important to use inside js!
        js("d.hide()")
    }

    internal var _staticBackdrop: Boolean = false
    private set

    fun staticBackdrop() {
        _staticBackdrop = true
    }


    @Composable
    open fun render(id: String) {
        Div({
            classes("modal", "fade")
            tabIndex(-1)
            id(id)
        }) {
            Div({
                classes("modal-dialog")
                sizeStyle?.let { classes(it) }
                classNames("modal-dialog-scrollable modal-dialog-centered")
            }) {
                Di("modal-content") {
                    _header?.let { h ->
                        Di("modal-header") { h() }
                    }
                    Di("modal-body") {
                        _body?.invoke(this)
                    }
                    _footer?.let { f ->
                        Di("modal-footer") { f() }
                    }
                }
            }
        }
    }
}

@Composable
fun Dialog(title: String? = null, f: DialogScope.() -> Unit) {
    val id = remember { randomId(7) }
    val scope = DialogScope().apply {
        title?.let { heading(it) }
        f()
    }
    scope.render(id)
    LaunchedEffect(true) {
        window.document.getElementById(id)?.let { el ->
            // we need a simple local var to cope with js():
            val d = if( scope._staticBackdrop )
                js("new bootstrap.Modal(el, {keyboard: false, backdrop: 'static'})")
            else
                js("new bootstrap.Modal(el, {})")
            scope.dialog = d
            js("d.show()")
            el.addEventListener("hidden.bs.modal", {
                scope.fireClose()
            })
        } ?: console.error("не удалось создать диалог")
    }
}