package editor

import org.w3c.dom.DOMRect
import kotlin.math.abs
import kotlin.math.min

data class Box(val topLeft: Point, val bottomRight: Point) {
    constructor(x0: Double, y0: Double, x1: Double, y1: Double) : this(Point(x0, y0), Point(x1, y1))
    constructor(dr: DOMRect) : this(dr.left, dr.top, dr.right, dr.bottom)

    constructor(p: Point, size: Double) : this( p - size/2, p + size/2)

    val x0 get(): Double = topLeft.x
    val left by ::x0

    val x1 get(): Double = bottomRight.x
    val right by ::x1

    val y0 get(): Double = topLeft.y
    val top by ::y0

    val y1 get(): Double = bottomRight.y
    val bottom by ::y1

    val width: Double by lazy { x1 - x0 }
    val height: Double by lazy { y1 - y0 }

    val bottomLeft: Point by lazy { Point(x0, y1) }
    val topRight: Point by lazy { Point(x1, y0) }

    operator fun contains(p: Point): Boolean = p.x in x0..x1 && p.y in y0..y1

    infix fun above(b1: Box): Boolean = y1 < b1.y0

    infix fun below(b1: Box): Boolean = y0 > b1.y1

    val center: Point by lazy { Point(x0 + (x1 - x0) / 2.0, y0 + (y1 - y0) / 2.0) }

    override fun toString(): String = "Box(x:$x0$ellipsis$x1, y:$y0$ellipsis$y1)"

    operator fun minus(p: Point): Box = Box(x0 - p.x, y0 - p.y, x1 - p.x, y1 - p.y)

    operator fun plus(p: Point): Box = Box(x0 + p.x, y0 + p.y, x1 + p.x, y1 + p.y)

    /**
     * The shortest distance from a point to the closest ppoint of the rectangle.
     * It is more complex than it could appear: the point could be
     * inside the box (that means 0 distance), above, below, left or right to or ub remainig areas. For every
     * case a different method is used.
     * @param p point to calculate distance from
     * @return distance to the closest point of the rectangle or 0.0 if p is inside
     */
    fun distanceTo(p: Point): Double {
        /*
        Distance to rect could be very different:

        - if the point is inside: 0
        - if the point is above or below - vertical from point to rect's closest side
        - if the point is left or right - horizontal to the rect's closest side
        - otherwise, the distance to the closest edge.
         */
        // point inside: 0
        if (p in this) return 0.0
        // directly above or below
        if (p.x in x0..x1) {
            return min(abs(p.y - y0), abs(p.y - y1))
        }
        // directly left or right
        if (p.y in y0..y1) {
            return min(abs(p.x - x0), abs(p.x - x1))
        }
        // in one of the remainig 4 quadrant, as other areas are excluded
        if (p.x < x0) {
            return if (p.y < y0)
            // to the top-left
                p.distanceTo(topLeft)
            else
            // to the bottom-left
                p.distanceTo(bottomLeft)
        }
        // here p.x is > x1
        return if (p.y < y0)
        // top-right
            p.distanceTo(topRight)
        else
        // bottom-right
            p.distanceTo(bottomRight)
    }

    fun yDistanceTo(p: Point): Double {
        if (p.y < y0) return y0 - p.y
        if (p.y > y1) return p.y - y1
        return 0.0
    }

    fun xDistanceTo(p: Point): Double {
        if (p.x < x0) return x0 - p.x
        if (p.x > x1) return p.y - x1
        return 0.0
    }

    operator fun contains(r: DOMRect): Boolean {
        return r.left >= left && r.right <= right && r.top >= top && r.bottom <= bottom
    }

    operator fun contains(other: Box): Boolean {
        return other.left >= left && other.right <= right && other.top >= top && other.bottom <= bottom
    }

    /**
     * Enlarge box in all direction by adding/subtracting a given alue to its corners.
     *
     * @param d how much to add to __each side__, so result.width and heoght will be 2*d larger.
     * @return new box which is larger by [d]
     */
    fun growBy(d: Double) = Box(left - d, top - d, right + d, bottom + d)

    init {
        if (x0 > x1) throw IllegalArgumentException("wrong x-coordinate order: x0 > x1")
        if (y0 > y1) throw IllegalArgumentException("wrong x-coordinate order: y0 > y1")
    }

}

private fun DOMRect.toBox() = Box(this)
