package controls

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.Element
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLSpanElement
import tools.csplit

@Composable
fun Di(classNames: String,attrBuilder: AttrBuilderContext<HTMLDivElement>?=null,contentBuilder: ContentBuilder<HTMLDivElement>) {
    Div({
        classes(*classNames.split(' ').toTypedArray())
        attrBuilder?.invoke(this)
    }, contentBuilder)
}

@Composable
fun Spa(clsNames: String, attrBuilder: AttrBuilderContext<HTMLSpanElement>?=null, contentBuilder: ContentBuilder<HTMLSpanElement>) {
    Span({
        classNames(clsNames)
        attrBuilder?.invoke(this)
    }, contentBuilder)
}

@Composable
fun Bold(text: String,attrBuilder: AttrBuilderContext<HTMLSpanElement>?=null) {
    Span({
        classNames("fw-bold")
        attrBuilder?.invoke(this)
    }) {
        Text(text)
    }
}
@Composable
fun Danger(text: String,attrBuilder: AttrBuilderContext<HTMLSpanElement>?=null) {
    Span({
        classNames("text-danger")
        attrBuilder?.invoke(this)
    }) {
        Text(text)
    }
}

fun <T: Element>AttrsScope<T>.classNames(names: String) {
    classes(names.csplit)
}