package controls

import androidx.compose.runtime.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLSpanElement
import tools.shortString
import kotlin.math.abs
import kotlin.time.Duration

@Composable
fun RelativeTime(
    time: Instant,
    showBeforeOrAfter: Boolean = true,
    showDateOnDays: Boolean = false,
    attrs: AttrBuilderContext<HTMLSpanElement>? = null,
    onReachedTime: (()->Unit)? = null,
) {
    val scope = rememberCoroutineScope()
    var text by remember { mutableStateOf(relativeTime(time, Clock.System.now(), showBeforeOrAfter)) }

    val timerMode = time >= Clock.System.now()

    DisposableEffect(time) {
        val job = scope.launch {
            while (true) {
                val delta = time - Clock.System.now()
                delay(if( abs(delta.inWholeSeconds) < 3600 || timerMode ) 1 else 30)
                val t = relativeTime(time, showBeforeOrAfter = showBeforeOrAfter, showDateOnDays = showDateOnDays)
                if (t != text) text = t
                if( timerMode && delta.inWholeSeconds < 1 ) onReachedTime?.invoke()
            }
        }
        onDispose {
            job.cancel()
        }
    }
    Span({
        attrs?.invoke(this)
    }) {
        Text(text)
    }
}

fun relativeTime(t: Instant, from: Instant = Clock.System.now(), showBeforeOrAfter: Boolean = true,
                 showDateOnDays: Boolean = false): String {
    val delta: Duration
    val ahead: Boolean
    if (t > from) {
        ahead = true
        delta = t - from
    } else {
        ahead = false
        delta = from - t
    }
    fun fix(text: String) =
        if (showBeforeOrAfter) {
            if (ahead) "через $text" else "$text назад"
        } else text

    val seconds = delta.inWholeSeconds
    if (seconds < 60) return fix("${seconds}с.")
    val minutes = delta.inWholeMinutes
    if (minutes < 60) return fix("${minutes}м.")
    val hours = delta.inWholeHours
    if (hours < 24)
        return fix("${hours}ч.")
    val days = delta.inWholeDays
    return if( showDateOnDays ) t.shortString() else fix("${days}д.")
}