import androidx.compose.runtime.*
import controls.Btn
import controls.Dialog
import controls.textField
import document.registerFabricators
import editor.CrossBrowser
import editor.SpellWorker
import editor.SpellWorkerPool
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.delay
import net.sergeych.cloudoc.CloudocsClient
import net.sergeych.mp_logger.Log
import net.sergeych.mp_tools.decodeBase64
import net.sergeych.mp_tools.encodeToBase64
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.parsec3.defaultNamedStorage
import net.sergeych.parsec3.stored
import net.sergeych.superlogin.InitSuperlogin
import org.jetbrains.compose.web.renderComposable
import org.w3c.dom.HTMLInputElement

internal val serverURL = "wss://demo.cloudocs.ru/api/p3"

val APP_NAME = "cloudocs.ru"

val isLocalTest by lazy {
    val x = window.location.host
    x.startsWith("localhost") || x.startsWith("0.0.0.0")
}

val client = CloudocsClient(
    if (isLocalTest) "ws://localhost:8110/api/p3" else serverURL,
//    serverURL,
    localStorage.getItem("__slstate__")?.decodeBase64()
) {
    localStorage.setItem("__slstate__", it.encodeToBase64())
}

var spellWorkerPool: SpellWorkerPool? = null
val Browser = CrossBrowser()

val settingsStore = defaultNamedStorage("__settings")
fun main() {
    registerFabricators()
    var testerApproved by settingsStore.stored(false)
    Log.connectConsole(Log.Level.DEBUG)

    globalLaunch {
        InitSuperlogin()
        console.log("Суперлогин: библиотека готова")
    }

    renderComposable(rootElementId = "root") {
//        var approved by remember { mutableStateOf(testerApproved) }
        // Limited beta-test is closed now!
        var approved = true

        if (approved || Router.anonymourRe.matches(window.location.pathname + window.location.hash) )
            Router.userContent()
        else {
            Dialog("βета-тест") {
                staticBackdrop()
                body {
                    var password by remember { mutableStateOf("") }
                    var statusString: String? by remember { mutableStateOf(null) }

                    val pwdId = textField(password, "пароль бета-тестера",
                        message = statusString, isValid = statusString?.let { false },
                        attrs = {
                        }) {
                        password = it
                    }
                    Btn("проверить") {
                        statusString = null
                        when (password.lowercase().trim()) {
                            "rds-2", "рдс-2" -> {
                                testerApproved = true
                                approved = true
                                close()
                            }

                            else -> {
                                statusString = "неверный пароль тестера"
                            }
                        }
                    }
                    LaunchedEffect(true) {
                        delay(600)
                        (document.getElementById(pwdId) as? HTMLInputElement)
                            ?.also { console.log("focused item $it")}
                            ?.focus()
                            ?: console.error("can't find text input for a focus()")
                    }
                }
            }
        }
    }
}


