package editor

import androidx.compose.runtime.*
import controls.*
import document.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.type
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.*
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.events.MouseEvent
import tools.Debouncer
import tools.randomId
import kotlin.time.Duration.Companion.milliseconds

const val SEARCH_ENTRY_CSS = "search-highlight"
val SEARCH_RESIZE_REFRESH_RATE = 25.milliseconds
const val SEARCH_ENTRY_INDEX = "data-search-index"
const val SEARCH_ENTRY_CURRENT_CSS = "search-active"

data class SearchMode(
    val caseSensitive: Boolean = false,
    val regex: Boolean = false
)

class Search(
    val dc: DocContext
) {
    var mode = SearchMode()
    var isOpen = false

    fun search(needle: String, mode: SearchMode): List<CRange> {
        if (needle.isEmpty()) return emptyList()

        return dc.doc.allBlocks.map { searchBlock(it, needle, mode) }.flatten()
    }

    suspend fun open(isReplace: Boolean) {
        if (isOpen) return

        dc.doc.withLock("searchManager.open") {
            dc.transactionMode = TransactionMode.DEFERRED

            var needle = ""
            val chain = Chain(dc.doc.allBlocksCopy)

            chain.copySelection(dc.selection.range)?.let { copied ->
                dc.selection.clear()
                needle = dc.selection.getCopiedText(copied)
            }

            try {
                Router.pushModal { doClose ->
                    SearchNewModal(dc, needle, mode, isReplace) {
                        close()
                        doClose()
                    }
                }
            } catch (t: Throwable) {
                console.error("ошибка при создании модального диалога: $t")
                t.printStackTrace()
            }
        }
    }

    fun close() {
        isOpen = false
        hideFound()
        dc.transactionMode = TransactionMode.INSTANT
    }

    companion object {
        fun searchBlock(
            block: Block,
            needle: String,
            mode: SearchMode = SearchMode()
        ): List<CRange> {
            val text = block.plainText

            return searchText(text, needle, mode).map {
                CRange(block.getPlainCaret(it.first), block.getPlainCaret(it.second + 1))
            }
        }

        private fun searchText(text: String, needle: String, mode: SearchMode): List<Pair<Int, Int>> {
            if (needle.isEmpty()) return emptyList()

            val regex = if (mode.caseSensitive) Regex(needle) else Regex(needle, RegexOption.IGNORE_CASE)

            return regex.findAll(text).map { Pair(it.range.first, it.range.last) }.toList()
        }
    }
}

@Composable
fun SearchNewModal(
    dc: DocContext,
    needleString: String,
    mode: SearchMode,
    isReplaceMode: Boolean,
    onClose: () -> Unit
) {
    var isReplace by remember { mutableStateOf(isReplaceMode) }
    var currentMode by mutableStateOf(mode)
    var found by remember { mutableStateOf(dc.search.search(needleString, mode)) }
//    var current by mutableStateOf<CRange?>(null)
    var index by remember { mutableStateOf(if (found.isEmpty()) null else 0) }
    val needleId = remember { randomId(17) }
    val replaceId = remember { randomId(17) }
    var isNeedleFocused by remember { mutableStateOf(true) }
    val scope = rememberCoroutineScope()
    var needle by remember { mutableStateOf(needleString) }
    var replaceWith by mutableStateOf("")

    fun focusInput() {
        val el = if (isReplace) {
            if (isNeedleFocused) document.getElementById(needleId) else document.getElementById(replaceId)
        } else document.getElementById(needleId)

        el?.let { (el as HTMLInputElement).focus() }
    }

    fun showCurrent(index: Int) {
        val marks = document.querySelectorAll(".${SEARCH_ENTRY_CSS}").asList().filterIsInstance<HTMLDivElement>()

        marks.forEach {
            if (it.getAttribute(SEARCH_ENTRY_INDEX) != index.toString()) it.classList.remove(SEARCH_ENTRY_CURRENT_CSS)
            else {
                if (!it.classList.contains(SEARCH_ENTRY_CURRENT_CSS)) it.classList.add(SEARCH_ENTRY_CURRENT_CSS)
            }
        }
    }

    fun scrollToCurrent() {
        index?.let {
            showCurrent(it)
            val range = found[it]
            document.getElementById(range.left.spanId)?.scrollIntoView(js("{ block: 'center' }"))
        }
    }

    fun current(): CRange? {
        if (found.isEmpty()) return null

        return found[index ?: 0]
    }

    fun update(found: List<CRange>, index: Int?) {
        if (found.isNotEmpty()) {
            showFound(found)
            showCurrent((found.size + (index ?: 0)) % found.size)
            scrollToCurrent()
        } else hideFound()
    }

    suspend fun waitAndUpdate() {
        dc.domObserver.waitAll("waitAndUpdate")
        update(found, index)
    }

    fun prev() {
        if (found.isEmpty()) return
        index = (found.size + ((index ?: 0) - 1)) % found.size
        scrollToCurrent()
    }

    fun next() {
        if (found.isEmpty()) return
        index = (found.size + ((index ?: 0) + 1)) % found.size
        scrollToCurrent()
    }

    val outsideClickListener = { ev: Event ->
        val mev = ev as MouseEvent

        if (!isClickTargetClass(DOCUMENT_CONTROL_CLASS, mev)) {
            dc.search.close()
            hideFound()
            onClose()
        }
    }

    val keydownListener = { ev: Event ->
        if (ev is KeyboardEvent) {
            when(ev.key) {
                "Escape" -> onClose()
                "Enter" -> {
                    if (ev.shiftKey) prev()
                    else next()
                }
                "Tab" -> {
                    ev.preventDefault()

                    console.log("TAB")
                    if (isReplace) {
                        isNeedleFocused = !isNeedleFocused
                        focusInput()
                    }
                }
            }

            if (ev.code == "KeyF" && (ev.ctrlKey || ev.metaKey)) {
                ev.preventDefault()
                isReplace = ev.shiftKey
                isNeedleFocused = !ev.shiftKey
                focusInput()
            }
        }
    }

    LaunchedEffect(true) {
        console.log("Run update", found.size, index)
        update(found, index)
    }

    var resizeAdapter: Debouncer? = null

    fun scheduleResizeAdapter(found: List<CRange>, index: Int?) {
        resizeAdapter?.cancel()
        resizeAdapter = Debouncer(scope, SEARCH_RESIZE_REFRESH_RATE) {
            update(found, index)
        }
        resizeAdapter?.schedule()
    }

    val resizeCallback: (Event)-> Unit = { _->
        scheduleResizeAdapter(found, index)
    }

    DisposableEffect(true) {
        window.addEventListener("keydown", keydownListener)
        window.addEventListener("click", outsideClickListener)
        window.addEventListener("resize", resizeCallback)

        onDispose {
            window.removeEventListener("keydown", keydownListener)
            window.removeEventListener("click", outsideClickListener)
            window.removeEventListener("resize", resizeCallback)
            hideFound()
        }
    }

    Div({
        style {
            position(Position.Fixed)
            top(4.8.em)
            right(0.em)
            property("z-index", "2000")
            width(650.px)
        }
        classNames("col shadow p-3 mb-3 bg-body rounded $DOCUMENT_CONTROL_CLASS")
    }) {
        Di("row g-3 align-items-center") {
            Di("col-6") {
                textInputHeadless(
                    needleId,
                    needle,
                    true,
                    placeholder = "Найти",
                    isFocused = isNeedleFocused
                ) {
                    needle = it
                    found = dc.search.search(it, currentMode)
                    index = if (found.isEmpty()) null else 0
                    scope.launch { waitAndUpdate() }
                }
            }
            Di("col-2") {
                if (needle != "") {
                    Text("${(index ?: -1) + 1} / ${found.size}")
                } else Text("$invisibleNBSP")
            }
            Di("col-4") {
                Di("d-grid gap-2 d-md-flex justify-content-md-end") {
                    Button({
                        type(ButtonType.Button)
                        classNames("btn btn-outline")
                        onClick {
                            currentMode = currentMode.copy(caseSensitive = !currentMode.caseSensitive)
                            found = dc.search.search(needle, currentMode)
                            index = 0
                            scope.launch { waitAndUpdate() }
                            focusInput()
                        }
                        style {
                            if (currentMode.caseSensitive) background("lightgray")
                        }
                    }) {
                        if (currentMode.caseSensitive) Text("A≠a")
                        else Text("A=a")
                    }

                    Div({
                        classNames("btn-group")
                        attr("role", "group")
                    }) {
                        Button({
                            type(ButtonType.Button)
                            classNames("btn btn-outline")
                            onClick {
                                prev()
                                focusInput()
                            }
                        }) {
                            Icon.ArrowLeft.render(1.em)
                        }
                        Button({
                            type(ButtonType.Button)
                            classNames("btn btn-outline")
                            onClick {
                                next()
                                focusInput()
                            }
                        }) {
                            Icon.ArrowRight.render(1.em)
                        }
                        Button({
                            type(ButtonType.Button)
                            classNames("btn btn-outline")
                            onClick {
                                isReplace = true
                                focusInput()
                            }
                        }) {
                            Icon.ThreeDotsVertical.render(1.em)
                        }
                    }
                }
            }
        }

        if (isReplace) Di("row g-3 mt-1 align-items-center") {
            Di("col-6") {
                textInputHeadless(
                    replaceId,
                    replaceWith,
                    true,
                    placeholder = "Заменить на",
                    isFocused = !isNeedleFocused
                ) {
                    replaceWith = it
                }
            }
            Di("col-6") {
                Di("d-grid gap-2 d-md-flex justify-content-md-end") {
                    Button({
                        type(ButtonType.Button)
                        classNames("btn btn-secondary")
                        if (replaceWith == "" || found.isEmpty()) disabled()
                        onClick {
                            current()?.let {
                                scope.launch {
                                    dc.replace(it, replaceWith)
                                    val updated = dc.search.search(needle, currentMode)
                                    found = updated
                                    index = if (updated.isEmpty()) null else index

                                    waitAndUpdate()
                                    focusInput()
                                }
                            }
                        }
                    }) {
                        Text("Заменить")
                    }

                    Button({
                        type(ButtonType.Button)
                        classNames("btn btn-secondary")
                        if (replaceWith == "" || found.isEmpty()) disabled()
                        onClick {
                            scope.launch {
                                hideFound()

                                val recalculated = recalculateReplaceFound(found, replaceWith.length)

                                for (match in recalculated) {
                                    dc.replace(match, replaceWith)
                                }

                                found = emptyList()
                                index = null
                                focusInput()
                            }
                        }
                    }) {
                        Text("Заменить все")
                    }
                }
            }
        }
    }
}

fun fixDeletedRangePosition(left: Int, right: Int, position: Int, replaceSize: Int): Int {
    if (position < left) return position
    if (position > right) return position - (right - left - replaceSize)

    throw BugException("position is in deleted range")
}

fun recalculateFound(match: CRange, next: CRange, replaceSize: Int): CRange {
    // [ < > < > ]
    // [ < > < ] [ > ]
    // [ < > ] [ < > ]

    // [ < ] [ > < > ]
    // [ < ] [ > < ] [ > ]
    // [ < ] [ > ] [ < > ]

    val ml = match.left.path.last()
    val mr = match.right.path.last()

    val nl = next.left.path.last()
    val nr = next.right.path.last()

    val matchL = match.left.offset
    val matchR = match.right.offset

    var nextL = next.left.offset
    var nextR = next.right.offset

    if (ml == mr) {
        if (nl == ml) {
            nextL = fixDeletedRangePosition(matchL, matchR, nextL, replaceSize)
            if (nr == ml) nextR = fixDeletedRangePosition(matchL, matchR, nextR, replaceSize)
        }
    } else {
        if (nl == mr) {
            nextL = fixDeletedRangePosition(0, matchR, nextL, replaceSize)
            if (nr == mr) nextR = fixDeletedRangePosition(0, matchR, nextR, replaceSize)
        }
    }

    val fixedLeft = next.left.copy(offset = nextL)
    val fixedRight = next.right.copy(offset = nextR)

    return CRange(fixedLeft, fixedRight)
}

fun recalculateReplaceFound(matches: List<CRange>, replaceSize: Int): List<CRange> {
    val list = matches.toMutableList()

    for (i in 0 until list.size - 1) {
        val match = list[i]
        var j = i + 1

        while (j < list.size) {
            list[j] = recalculateFound(match, list[j], replaceSize)

            j++
        }
    }

    return list
}

fun showFound(found: List<CRange>) {
    hideFound()

    found.forEachIndexed { foundIndex, it ->
        it.getDOMRects().let {
            it.forEach { r ->
                val attributes = listOf(
                    "position: absolute",
                    "left: ${r.left}px",
                    "top: ${r.top + window.scrollY}px",
                    "width: ${r.width}px",
                    "height: ${r.height}px",
                    "z-index: 0",
                    "background: #BBD6FB"
                ).joinToString("; ")
                val div = document.createElement("div")
                div.className = SEARCH_ENTRY_CSS
                div.setAttribute("style", attributes)
                div.setAttribute(SEARCH_ENTRY_INDEX, foundIndex.toString())
                document.body?.appendChild(div)
            }
        }
    }
}

fun hideFound() {
    val existing = document.querySelectorAll(".${SEARCH_ENTRY_CSS}").asList().filterIsInstance<Element>()
    existing.forEach { it.remove() }
}