package controls

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLTableCellElement
import org.w3c.dom.HTMLTableElement


object PropTableBuilder {

    @Composable
    fun p(name: String,value: String) {
        p(name) { Text(value) }
    }

    @Composable
    fun p(name: String,builder: ContentBuilder<HTMLTableCellElement>) {
        Tr {
            Th({classNames("text-end pe-3 py-2 bg-light align-middle")}) {
                Text(name)
            }
            Td { builder() }
        }
    }
}

@Composable
fun Props(attrs: AttrBuilderContext<HTMLTableElement>?=null, handler: @Composable PropTableBuilder.()->Unit) {
    Table({
        classes("table","table-striped","p-2")
        attrs?.invoke(this)
    }) {
        Tbody {
            PropTableBuilder.handler()
        }
    }
}
