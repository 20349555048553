package editor

import androidx.compose.runtime.*
import controls.Toaster
import document.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import net.sergeych.mp_logger.LogTag
import org.w3c.dom.*
import org.w3c.dom.events.Event
import spellWorkerPool
import tools.Debouncer
import worker.*
import worker.CheckResult

//val DICTIONARIES = setOf(
////    "dicts/ru_RU.l3d",
////    "dicts/en_AU.l3d",
////    "dicts/en_CA.l3d",
////    "dicts/en_GB.l3d",
////    "dicts/en_US.l3d",
////    "dicts/en_ZA.l3d",
////    "dicts/full.l3d"
//)

val DICTIONARIES = (1 until 17).map { "dicts/combined.part${it}.l3d" }

val SC_WORKER = "/spellchecker.js"

val SC_CSS = "sc-highlight"
val SC_MODAL_CSS = "sc-modal"
val SC_UNDERLINE = "#CB2B1D"

val SC_BLOCK_GUID = "data-sc-block"
val SC_WORD_INDEX = "data-sc-word"

fun spellMatchAttributes(left: Double, top: Double, width: Double, height: Double): String {
    return listOf(
        "position: absolute",
        "left: ${left}px",
        "top: ${top}px",
        "width: ${width}px",
        "height: ${height}px",
        "z-index: 0",
//        "background: ${SC_BACKGROUND}",
        "border-bottom: 2px dotted ${SC_UNDERLINE}"
    ).joinToString("; ")
}

fun Request<RequestResult>.pack(): String {
    return Json.encodeToString(this)
}

class SpellWorker(val dictionaries: Set<String>, val debug: Boolean = false) {
    val worker = init()
    var isInitialized = false
    var isReady = CompletableDeferred<Boolean>()
    var activeRequest: CompletableDeferred<RequestResult> = sendInit()
    var shouldLoad: Set<String> = dictionaries

    fun sendInit(): CompletableDeferred<RequestResult> {
        return send(Init(debug) as Request<RequestResult>, false)
    }

    private fun send(request: Request<RequestResult>, shouldCheckActive: Boolean = true): CompletableDeferred<RequestResult> {
        if (shouldCheckActive && activeRequest.isActive) throw BugException("Another request processing")

        activeRequest = CompletableDeferred<RequestResult>()

        worker.postMessage(request.pack())

        return activeRequest
    }

    suspend fun run(request: Request<RequestResult>): RequestResult {
        if (!isReady.isCompleted) throw BugException("Worker is not ready")

        return send(request).await()
    }

    fun receive(response: Response) {
        if (response.error != null) activeRequest.completeExceptionally(
            BugException("Worker request completed with error ${response.error}")
        ) else {
            val result = response.result!!

            activeRequest.complete(result)

            when(result) {
                is InitResult -> {
                    if (!isInitialized) {
                        isInitialized = true
                        activeRequest = send(LoadDictionary(dictionaries.first()) as Request<RequestResult>)
                    }
                }
                is LoadDictionaryResult -> {
                    shouldLoad -= result.dictionarySRC
                    if (shouldLoad.size == 0) isReady.complete(true)
                    else send(LoadDictionary(shouldLoad.first()) as Request<RequestResult>)
                }
                else -> {}
            }
        }
    }

    private fun init(): Worker {
        val worker = Worker(SC_WORKER)

        worker.onmessage = { messageEvent ->
            val response = Json.decodeFromString<Response>(messageEvent.data.toString())
            receive(response)
        }

        worker.onerror = { e ->
            console.warn("Worker responded with error", e)
        }

        return worker
    }

    fun terminate() {
        worker.terminate()
    }
}

class SpellWorkerPool(val dictionaries: List<String>, val debug: Boolean = false) {
    var isReady = CompletableDeferred<Boolean>()
    var workers = listOf<SpellWorker>()

    suspend fun init() {
        val workersTotal = workers.size
        var toast: Toaster.Item? = null
        dictionaries.slice(workersTotal until dictionaries.size).forEach {
            val lastToastId = toast?.id
            toast = Toaster.info("Загрузка орфографических словарей... (${(100 * workers.size / dictionaries.size).toInt().toString()}%)")
            lastToastId?.let { Toaster.hide(it) }
//            console.warn("Create worker for ${it}")
            val worker = SpellWorker(setOf(it), debug)
            workers += worker
            worker.isReady.await()
//            console.warn("Worker for ${it} is ready")
        }
        isReady.complete(true)
    }

    suspend fun check(request: Check): CheckResult {
        if (!isReady.isCompleted) throw BugException("Pool is not ready")

        var responses = workers.map { it.run(request as Request<RequestResult>) } as List<CheckResult>
        val first = responses.first()
        var typos = first.typos.toSet()

        for (i in 1 until responses.size) {
            typos = typos.intersect(responses[i].typos.toSet())
        }

        return CheckResult(first.guid, typos.toList())
    }

    suspend fun correct(request: Correction): CorrectionResult {
        if (!isReady.isCompleted) throw BugException("Pool is not ready")

        var responses = workers.map { it.run(request as Request<RequestResult>) } as List<CorrectionResult>
        val first = responses.first()
        var corrections = responses.mapNotNull { (it.corrections ?: emptyList()).firstOrNull() }

        return CorrectionResult(first.typo, corrections)
    }

    fun terminate() {
        workers.forEach { it.terminate() }
    }

    fun terminateLast() {
        val last = workers.lastOrNull()

        if (last != null) {
            console.log("terminate last")
            last.terminate()
            console.log("remove last")
            workers -= last
        }
    }
}

class SpellChecker(
    val dc: DocContext,
    val dictionaries: List<String> = DICTIONARIES,
    val debug: Boolean = false
): LogTag("SpellChecker") {

    var isOn= false
//    private var isReady = false
//    private var isWorkerReady = false
//    private var isProcessing = false
//    var worker = spellWorker ?: SpellWorker(dictionaries, debug = debug)
    private var pool = spellWorkerPool ?: SpellWorkerPool(dictionaries, debug).also { spellWorkerPool = it }
//    var queue = mutableSetOf<String>()

    private val correct = mutableSetOf<String>()
    val incorrect = mutableSetOf<String>()
    var exclude: Set<String> = emptySet()

    private var corrections = mutableMapOf<String, List<String>>()
    private val wordsByBlock = mutableMapOf<String, List<Word>>()
    var callbacks = mutableMapOf<String, (candidates: Map<String, List<String>>) -> Unit>()
    var loaded = mutableSetOf<String>()
    var processor: Job? = null
//    val mutex = Mutex()
//    var isWorkerProcessing = false

    fun log(msg: String) {
        if (debug) console.log("[SPELLCHECKER]: ${msg}")
    }

    fun terminate() {
        pool.terminateLast()
        clearMarks()
    }

    suspend fun runChecker(guids: List<String>) = coroutineScope {
        if (!isOn) return@coroutineScope

        pool.isReady.await()

        processor?.let { if (it.isActive) it.cancelAndJoin() }

        processor = launch {
            val secondPriority = (dc.doc.allBlocks.map { it.guid }.toSet() - guids.toSet()).toList()

            (guids + secondPriority).forEach { guid ->
                val words = dc.getWords(guid)
                if (words != null) {
                    wordsByBlock[guid] = words
                    val wordsToCheck = (words.map { it.str }.toSet() - correct - incorrect - exclude).toList()

                    if (wordsToCheck.isNotEmpty()) {
                        val checkSet = wordsToCheck.toSet()
//                        val checkResult = worker?.run(Check(guid, wordsToCheck) as Request<RequestResult>) as CheckResult
                        val checkResult = pool?.check(Check(guid, wordsToCheck)) as CheckResult

                        incorrect += checkResult.typos
                        correct += checkSet - checkResult.typos.toSet()
                    }

                    mark(guid)
                }
            }
        }
    }

    suspend fun turnOn() {
        isOn = true
        if (pool?.isReady?.isCompleted == false) {
            pool?.init()
        }
        runChecker(dc.doc.allBlocks.map { it.guid })
        log("turn on spell checker!")
    }

    suspend fun turnOff() {
        isOn = false
        processor?.cancelAndJoin()
        clearMarks()
        log("turn off spell checker.")
    }

    suspend fun getCorrections(typo: String): List<String> {
        val existing = corrections[typo]

        if (existing != null) return existing

//        val result = worker?.run(Correction(typo) as Request<RequestResult>) as CorrectionResult
        val result = pool?.correct(Correction(typo)) as CorrectionResult

        val found = result.corrections ?: emptyList()
        corrections[typo] = found

        return found
    }

    suspend fun excludeOnDocument(typo: String) {
        exclude += typo
        incorrect -= typo
        mark()
        dc.saveState()
    }

    fun getContextMenu(ev: MouseContextMenuEvent, pointElements: Array<Element>): SpellCheckerMenu? {
        val highlight = pointElements.find { it.classList.contains(SC_CSS) } ?: return null
        val guid = highlight.getAttribute(SC_BLOCK_GUID) ?: return null
        val wordIndex = highlight.getAttribute(SC_WORD_INDEX)?.toInt() ?: return null
        val words = wordsByBlock[guid] ?: return null
        val word = words[wordIndex]

        return SpellCheckerMenu(word)
    }

    @Composable
    fun Monitor() {
        val scope = rememberCoroutineScope()
        var resizeAdapter: Debouncer? = null

        fun scheduleResizeAdapter() {
            resizeAdapter?.cancel()
            resizeAdapter = Debouncer(scope, SEARCH_RESIZE_REFRESH_RATE) {
                mark()
            }
            resizeAdapter?.schedule()
        }

        val resizeCallback: (Event)-> Unit = { _->
            scheduleResizeAdapter()
        }

        DisposableEffect(true) {
            window.addEventListener("resize", resizeCallback)
            onDispose {
                window.removeEventListener("resize", resizeCallback)
            }
        }
    }

    fun mark(
        guid: String,
        wordIndex: Int,
        word: Word
    ) {
        word.range.getDOMRects().let {
            // Remove caret rect if caret is in range
            val rects = filterIntersectingDOMRect(it)

            rects.forEach { r ->
                val attributes = spellMatchAttributes(r.left, r.top + window.scrollY, r.width, r.height)
                val div = document.createElement("div")
                div.className = "${SC_CSS} ${SC_CSS}-${guid}"
                div.setAttribute("style", attributes)
                div.setAttribute(SC_BLOCK_GUID, guid)
                div.setAttribute(SC_WORD_INDEX, wordIndex.toString())

                document.body?.appendChild(div)
            }
        }
    }

    suspend fun mark(guid: String) {
        val isReady = dc.domObserver.waitAll("mark $guid")
        if (!isReady) return

        clearMarks(guid)
        val words = wordsByBlock[guid] ?: return

        words.forEachIndexed { i, w ->
            if (incorrect.contains(w.str)) mark(guid, i, w)
        }
    }

    suspend fun mark() {
        val isReady = dc.domObserver.waitAll("mark")
        if (!isReady) return

        clearMarks()
        wordsByBlock.forEach { mark(it.key) }
    }

    fun clearMarks(guid: String) {
        val existing = document.querySelectorAll(".${SC_CSS}-${guid}").asList().filterIsInstance<Element>()
        existing.forEach { it.remove() }
    }

    fun clearMarks() {
        val existing = document.querySelectorAll(".${SC_CSS}").asList().filterIsInstance<Element>()
        existing.forEach { it.remove() }
    }
}

data class SpellCheckerMenu(
    val word: Word
)

fun filterIntersectingDOMRect(rects: List<DOMRect>): List<DOMRect> {
    val filtered = rects.toMutableList()
    fun isInnerIntersection(targetRect: DOMRect, possibleContainer: DOMRect): Boolean {
        return targetRect.left >= possibleContainer.left
                && targetRect.right <= possibleContainer.right
                && targetRect.top <= possibleContainer.top
                && targetRect.bottom >= possibleContainer.bottom
    }

    rects.forEachIndexed { j, target ->
//        var target = it
        var isIntersted = false
        var i = 0
        while (i < rects.size && !isIntersted) {
            if (i != j) isIntersted = isIntersted || isInnerIntersection(target, rects[i])
            i++
        }
        if (isIntersted) filtered -= target
    }

    return filtered
}