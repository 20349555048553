package tools

import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.Color

object ColorSerializer : KSerializer<CSSColorValue> {

    override val descriptor = PrimitiveSerialDescriptor("Color", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: CSSColorValue) {
        encoder.encodeString(value.toString())
    }

    override fun deserialize(decoder: Decoder): CSSColorValue {
        return Color(decoder.decodeString())
    }
}