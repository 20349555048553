import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import controls.classNames
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.forId
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLLabelElement
import tools.randomId

@Composable
fun booleanField(
    value: Boolean,
    label: String? = null,
    labelContent: ContentBuilder<HTMLLabelElement>? = null,
    isValid: Boolean? = null,
    message: String? = null,
    type: InputType<Boolean> = InputType.Checkbox,
    attrs: AttrBuilderContext<HTMLInputElement>?=null,
    isSwitch: Boolean? = false,
    setter: (Boolean) -> Unit,
): String {

    val controlId: String = remember { randomId(17) }

    val hasLabel = label != null || labelContent != null

    Div({
        classes("mb-3", "form-check")
        classNames("mb-3 form-check${ if (isSwitch == true) " form-switch" else "" }")
    }) {
        Input(type) {
            id(controlId)
            onInput { setter(it.value) }
            classes("form-check-input")
            checked(value)
            if (isSwitch == true) attr("role", "switch")
            if (isValid == true) classes("is-valid")
            else if (isValid == false) classes("is-invalid")
            if (hasLabel) placeholder("введите значение")
            attrs?.invoke(this)
        }
        if (hasLabel) {
            Label(controlId, {
                classes("form-check-label")
                forId(controlId)
            }) {
                label?.let { Text(it) }
                labelContent?.invoke(this)
            }
        }
        if (message != null) {
            Div({
                when (isValid) {
                    true -> classes("valid-feedback")
                    false -> classes("invalid-feedback")
                    else -> classes("small")
                }
            }) {
                Text(message)
            }
        }
    }

    return controlId
}