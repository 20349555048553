package document

interface L2Element<T> {
    val guid: String
    val nextGuid: String?
    val prevGuid: String?

    fun copyListElement(prev: String? = prevGuid, next: String? = nextGuid): T

    fun contentEquals(other: T): Boolean
}

interface L2Caret<T>: L2Element<T> {
    fun caretAt(guid: String, offset: Int): Caret?

    fun caretAtEnd(): Caret

    fun contains(c: Caret?): Boolean {
        if (c == null) return false

        return c.path.indexOf(guid) != -1
    }

    fun getOffset(c: Caret): Int
    fun caretAt(offset: Int, preferRight: Boolean): Caret?
}