package controls

import Router
import androidx.compose.runtime.*
import client
import net.sergeych.cloudoc.api.ApiUserDetails
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.ElementScope
import org.w3c.dom.HTMLDivElement

@Composable
fun Template(fluid: Boolean = false, f: @Composable ElementScope<HTMLDivElement>.(ApiUserDetails?) -> Unit) {
    var currentUser by remember { mutableStateOf<ApiUserDetails?>(client.currentUser) }

    LaunchedEffect("wathStateTemplate") {
        client.userFlow.collect {
            currentUser = it
        }
    }

//    Nav({
//        classNames("navbar navbar-expand-lg sticky-top bg-light navbar-expand-sm")
//    }) {
//        Di("container-fluid") {
//            A("#", { classes("navbar-brand") }) {
//                Img("/birch1.png") {
//                    style {
//                        height(1.6.em)
//                    }
//                }
//                Text("βереста!")
//            }
//            Button({
//                classes("navbar-toggler")
//                type(ButtonType.Button)
//                attr("data-bs-toggle", "collapse")
//                attr("data-bs-target", "#navbarContent")
//            }) {
//                Span({ classes("navbar-toggler-icon") })
//            }
//            Div({
//                classNames("collapse navbar-collapse")
//                id("navbarContent")
//            }) {
//                Ul({ classNames("navbar-nav me-auto mb-2 mb-lg-0") }) {
//                    simpleLink("/", "начало", active = true)
//                    simpleLink("/test", "test")
//                }
//                Ul({ classNames("navbar-nav ms-auto mb-2 mb-lg-0") }) {
//                    client.currentUser?.let {
//                        simpleLink("/lprofile", it.name)
//                    } ?: simpleLink("#", "гость", disabled = true)
//                }
//            }
//
//        }
//    }

    if( currentUser != null ) {
        Di("position-fixed top-0 end-0 m-0 py-0 px-1 border", {
            style { cursor("pointer") }
            onClick { e ->
                Router.push("/profile")
                e.preventDefault()
                e.stopPropagation()
            }
        }) {
            Icon.Gear.render({
                style { fontSize(1.5.em) }
            })
        }
    }
    Div({
        classNames(
            if (fluid) "container-fluid mt-2"
            else "container mt-2"
        )
    }) {
        f(currentUser)
    }
}

//@Composable
//private fun simpleLink(
//    href: String, text: String,
//    active: Boolean = false,
//    disabled: Boolean = false,
//) {
//    Li({ classes("nav-item") }) {
//        A(href, {
//            classes("nav-link")
//            if (active) classes("active")
//            if (disabled) classes("disabled")
//        }) {
//            Text(text)
//        }
//    }
//}