package tools

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement

@Composable
fun Loading(
    isLoading: Boolean,
    message: String? = null,
    smallSpinner: Boolean = true,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    readyContent: ContentBuilder<HTMLDivElement>
) {
    Div({
        attrs?.invoke(this)
    }) {
        if (isLoading) {
            Div({
                classes("spinner", "spinner-border")
                if (smallSpinner) classes("spinner-border-sm")
                style { display(DisplayStyle.InlineBlock) }
            }) {}
            message?.let { Text(it) }
        } else
            readyContent()
    }
}