package editor

import kotlinx.browser.window
import org.w3c.dom.DOMRect
import kotlin.math.abs
import kotlin.math.sqrt

fun qrt(d: Double): Double = d * d

data class Point(val x: Double, val y: Double) {
    fun shiftY(d: Double) = copy(y = y + d)
    fun shiftX(d: Double) = copy(x + d)

    fun distanceTo(p: Point): Double = sqrt(qrt(p.x - x) + qrt(p.y - y))

    /**
     * Distance from this point to the closest point of the Box. See more at [Box.distanceTo]
     * @return the distance, which is 0 if the point is inside the box.
     */
    fun distanceTo(b: Box): Double = b.distanceTo(this)

    // Length of zero-vector, sort of ||this||
    fun norm(): Double = sqrt(qrt(x) + qrt(y))
    operator fun plus(d: Double): Point {
        return Point(x + d, y + d)
    }

    operator fun minus(d: Double): Point {
        return Point(x - d, y - d)
    }

    operator fun plus(p: Point) = Point(x + p.x, y + p.y)
    operator fun minus(p: Point) = Point(x - p.x, y - p.y)

    companion object {
        val ZERO = Point(0.0, 0.0)

        fun scrollPos(): Point = Point(window.scrollX, window.scrollY)
    }
}

operator fun DOMRect.contains(p: Point): Boolean {
    return p.x >= left && p.x <= right && p.y >= top && p.y <= bottom
}

fun DOMRect.isCloserToRightBorder(p: Point): Boolean {
    return abs(p.x - left) > abs(right - p.x)
}



