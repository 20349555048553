package editor

import document.*
import net.sergeych.mp_logger.warning

fun isConsecutiveSpaces(source: String, addition: String): Boolean {
    return addition == " " && source.lastOrNull() == ' '
}

suspend fun DocContext.insertChar(ch: String) {
    replay.insertChar(ch)

    runTransaction("INSERT CHAR") { chain, style ->
        deleteSelectionIfExists(chain)

        chain.insertString(ch, style)
    }
}

fun DocChain.insertTerminalFragment(f: Fragment, atCaret: Caret? = null) {
    val logger = log("insertString")
    logger("run")

    val c = atCaret ?: caret
    ?: throw BugException("Can't perform operation without caret")

    val block = caretBlock(c)
    val span = caretSpan(c)
    if (span.isLink() && (f !is Fragment.StyledSpan || f.href != span.href )) return

    val left = span.textLeft(c)
    val right = span.textRight(c)

    val span1 = span.copy(text = left)
    val span2 = Fragment.StyledSpan(right, span.textStyle, href=span.href)

    val updatedParagraph = block.paragraph.replaceFragments(listOf(span), listOf(span1, f, span2))
    val updatedBlock = block.withUpdatedParagraph(updatedParagraph)
    val updatedCaret = updatedBlock.caretAt(span2.guid, 0)

    updateBlockAndClean(updatedBlock, updatedCaret)
}

fun DocChain.insertString(plainString: String, style: TextStyle?, atCaret: Caret? = null) {
    val logger = log("insertString")
    logger("run")
    if (plainString == "") return

    val c = atCaret ?: caret
        ?: throw BugException("Can't perform operation without caret")

//    val block = caretBlock(c)
    val span = caretSpan(c)
    val left = span.textLeft(c)

    if (isConsecutiveSpaces(left, plainString))
        return warning { "consecutive spaces are ignored" }

    val newStyle = if (span.isLink()) span.textStyle else (style ?: span.textStyle)
    val newSpan = Fragment.StyledSpan(plainString, newStyle, href = span.href)

    insertTerminalFragment(newSpan, c)
}

suspend fun DocContext.insertLink(name: String, href: String) {
    val fragment = Fragment.StyledSpan(text = name, href = href)

    insertTerminalFragmentAtCaret(fragment)
}

suspend fun DocContext.updateLink(link: Fragment.StyledSpan, name: String, href: String) {
    updateTerminalFragment(link.copy(text = name, href = href))
}

fun DocChain.insertMultilineText(plainText: String, style: TextStyle?, atCaret: Caret? = null) {
    val logger = log("insertMultilineText")
    logger("run")
    var c = atCaret ?: caret
        ?: throw BugException("Can't perform operation without caret")

    val lines = plainText.split('\n')

    if (lines.size == 1) return insertString(plainText, style, c)

    val block = caretBlock(c)
    val span = caretSpan(c)
    val style = span.textStyle

    val firstLine = lines.first()
    insertString(firstLine, style, c)
//    console.log("@@@ inserted first line '${firstLine}' at ${c}")

    val lastBlockId = split()
    var currentBlockId = block.guid

    if (lines.size > 2) {
        for (i in 1..lines.size - 2) {
            val newSpan = Fragment.StyledSpan(lines[i], style)
            val newParagraph = Fragment.Paragraph(listOf(newSpan))
            val newBlock = Block(newParagraph)

//            console.log("@@@ insert block ${newBlock.guid} after ${currentBlockId}")
            insertBlockAndClean(newBlock, currentBlockId)
            currentBlockId = newBlock.guid
        }
    }

    val lastLine = lines.last()
    val lastBlock = get(lastBlockId) ?: throw BugException("There's no second block of split in chain")
    val lastBlockStart = lastBlock.caretAtStart()
    insertString(lastLine, style, lastBlockStart)
//    console.log("@@@ inserted last line '${lastLine}' at ${lastBlockStart}")
}