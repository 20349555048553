package editor

import APP_NAME
import Browser
import androidx.compose.runtime.*
import controls.Di
import controls.DialogScope
import controls.classNames
import controls.textInputHeadless
import document.Fragment
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.type
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.events.Event
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.events.MouseEvent
import tools.randomId

val MENU_SPACER = 20
val MENU_WIDTH = 300
val MENU_HEIGHT = 200

data class ContextMenuData(
    val spellchecker: SpellCheckerMenu?,
    val selection: SelectionMenuData?,
    val frame: FrameMenuData?,
    val link: Fragment.StyledSpan?
)

fun calculateTopLeft(spawnAt: Point): Point {
    var yAlign = "bottom"
    var xAlign = "right"

    document.getElementById(DOCUMENT_CONTENT_ID)?.getBoundingClientRect()?.let {
        if (it.bottom - spawnAt.y <= MENU_HEIGHT + MENU_SPACER) yAlign = "top"
        if (it.right - spawnAt.x <= MENU_WIDTH + MENU_SPACER) xAlign = "left"
        if (spawnAt.y - it.top <= MENU_HEIGHT + MENU_SPACER) yAlign = "bottom"
    }

    var left = if (xAlign == "right") spawnAt.x + MENU_SPACER else spawnAt.x - MENU_SPACER - MENU_WIDTH
    var top = if (yAlign == "bottom") spawnAt.y + MENU_SPACER else spawnAt.y - MENU_SPACER - MENU_HEIGHT

    return Point(left, top)
}

enum class ContextMenuState {
    BASIC,
    LINK,
    INFO
}

class ContextMenu(val spawnAt: Point): DialogScope() {
    val topLeft = calculateTopLeft(spawnAt)
    var dc: DocContext? = null
    var menu: ContextMenuData? = null

    fun close(id: String) {
        fireClose()
    }

    @Composable
    override fun render(id: String) {
        val scope = rememberCoroutineScope()
        var state by mutableStateOf(ContextMenuState.BASIC)
//        var isLinkEdit by mutableStateOf(false)
//        var isInfo by mutableStateOf(true)
        var linkName by mutableStateOf(menu?.link?.text ?: "")
        var linkHref by mutableStateOf(menu?.link?.href ?: "")
        val linkNameId = remember { randomId(17) }
        val linkHrefId = remember { randomId(17) }
        var replaceWith by mutableStateOf<List<String>?>(null)

        fun positionCSS(isActive: Boolean): String {
            return if (isActive) "dropdown-item" else "dropdown-item disabled"
        }

        fun handleLink(name: String, href: String) {
            if (name.isEmpty() || href.isEmpty()) return

            scope.launch {
                val existing = menu?.link

                if (existing != null) dc?.updateLink(existing, name, href)
                else dc?.insertLink(name, href)
                close(id)
            }
        }

        LaunchedEffect(id) {
            menu?.spellchecker?.let {
                val corrections = dc?.spellChecker?.getCorrections(it.word.str)
                corrections?.let {
                    replaceWith = it
                }
            }
        }

//        dc?.spellChecker?.registerCallback(id) { replaceMap ->
//            menu?.spellchecker?.word?.str?.let { typo ->
//                val replaces = replaceMap[typo]
//
//                if (replaces != null) replaceWith = replaces
//            }
//        }

//        menu?.spellchecker?.word?.str?.let { dc?.spellChecker?.requestCorrections(it) }

        DisposableEffect(id) {
            val clickListener = { e: Event ->
                if (!isClickTargetId(id, e as MouseEvent)) close(id)
            }

            val onRightClick = { e: Event ->
                if (!isClickTargetId(id, e as MouseEvent)) close(id)
            }

            val keydownListener = { ev: Event ->
//                ev.preventDefault()

                if (ev is KeyboardEvent) {
                    if (ev.key == "Enter") {
                        if (state == ContextMenuState.LINK) handleLink(linkName, linkHref)
                    }
                }
            }

            window.addEventListener("click", clickListener)
            window.addEventListener("contextmenu", onRightClick)
            window.addEventListener("keydown", keydownListener)

            onDispose {
                window.removeEventListener("click", clickListener)
                window.removeEventListener("contextmenu", onRightClick)
                window.removeEventListener("keydown",keydownListener )

//                dc?.spellChecker?.deleteCallback(id)
            }
        }

        Div({
            classes("shadow", "bg-body", "rounded", SC_MODAL_CSS)
            id(id)
            style {
                position(Position.Absolute)
                left(topLeft.x.px)
                top((topLeft.y + window.scrollY).px)
                property("z-index", "10000")
                maxWidth(MENU_WIDTH.px)
            }
        }) {

//            Text("context")
            Div({
                classNames("dropdown-menu show")
                style {
                    property(
                        "width",
                        "${if (state == ContextMenuState.INFO) 370 else MENU_WIDTH}px"
                    )
                }
            }) {
                when(state) {
                    ContextMenuState.BASIC -> {
                        menu?.let {
                            if (it.frame != null) {
                                val frame = it.frame.f
                                // 50px
                                A(null, {
                                    classNames(positionCSS(true))
                                    onClick {
                                        scope.launch {
                                            dc?.deleteTerminalFragment(frame.guid)
                                            close(id)
                                        }
                                    }
                                }) { Text("Удалить") }
                            } else {
                                it.spellchecker?.let { spMenu ->
                                    if (replaceWith == null) {
                                        A(null, {
                                            classNames(positionCSS(false))
                                        }) {
                                            I {
                                                Text("Поиск возможных замен..")
                                            }
                                        }
                                    } else {
                                        replaceWith?.let { replaces ->
                                            if (replaces.isEmpty()) {
                                                A(null, {
                                                    classNames(positionCSS(false))
                                                }) {
                                                    I {
                                                        Text("Замен не найдено.")
                                                    }
                                                }
                                            } else {
                                                A(null, {
                                                    classNames(positionCSS(false))
                                                }) {
                                                    I {
                                                        Text("Возможно, вы имели в виду:")
                                                    }
                                                }
                                                A(null, {
                                                    classNames(positionCSS(true))
                                                    onClick {
                                                        scope.launch {
                                                            dc?.replace(spMenu.word.range, replaces.first())
                                                            close(id)
                                                        }
                                                    }
                                                }) { Text(replaces.first()) }
                                            }

                                            A(null, {
                                                classNames(positionCSS(true))
                                                onClick {
                                                    scope.launch {
                                                        dc?.spellChecker?.excludeOnDocument(spMenu.word.str)
                                                        close(id)
                                                    }
                                                }
                                            }) { Text("Пропустить все") }

                                            // TODO
//                                        A(null, {
//                                            classNames(positionCSS(true))
//                                            onClick {
//                                                scope.launch {
//                                                    dc?.spellChecker?.excludeUser(spMenu.typo)
//                                                    close(id)
//                                                }
//                                            }
//                                        }) { Text("Добавить в пользовательский словарь") }
                                        }
                                    }
                                    Di("dropdown-divider") {}
                                }
                                // 178px
                                val range = it.selection?.range
                                A(null, {
                                    classNames(positionCSS(range != null))
                                    onClick {
                                        scope.launch {
                                            if (Browser.support(EXTENSION.CLIPBOARD_WRITE)) {
                                                range?.let { r ->
                                                    dc?.cutSelection()
                                                    close(id)
                                                }
                                            } else {
                                                state = ContextMenuState.INFO
                                            }
                                        }
                                    }
                                }) { Text("Вырезать") }
                                A(null, {
                                    classNames(positionCSS(range != null))
                                    onClick {
                                        scope.launch {
                                            if (Browser.support(EXTENSION.CLIPBOARD_WRITE)) {
                                                range?.let { r ->
                                                    dc?.copy()
                                                    close(id)
                                                }
                                            } else {
                                                state = ContextMenuState.INFO
                                            }
                                        }
                                    }
                                }) { Text("Копировать") }
                                A(null, {
                                    classNames(positionCSS(it.selection?.hasCopied ?: false))
                                    onClick {
                                        scope.launch {
                                            if (Browser.support(EXTENSION.CLIPBOARD_READ)) {
                                                var clipboardText = Browser.clipboardReadText() ?: ""
                                                dc?.paste(clipboardText)
                                                close(id)
                                            } else {
                                                state = ContextMenuState.INFO
                                            }
                                        }
                                    }
                                }) { Text("Вставить") }
                                A(null, {
                                    classNames(positionCSS(it.selection?.hasCopied ?: false))
                                    onClick {
                                        scope.launch {
                                            if (Browser.support(EXTENSION.CLIPBOARD_READ)) {
                                                var clipboardText = Browser.clipboardReadText() ?: ""
                                                dc?.paste(clipboardText, true)
                                                close(id)
                                            } else {
                                                state = ContextMenuState.INFO
                                            }
                                        }
                                    }
                                }) { Text("Вставить без форматирования") }
                                A(null, {
                                    classNames(positionCSS(range != null))
                                    onClick {
                                        scope.launch {
                                            range?.let { r ->
                                                dc?.delete(r)
                                                close(id)
                                            }
                                        }
                                    }
                                }) { Text("Удалить") }
                                Di("dropdown-divider") {}
                                A(null, {
                                    classNames(positionCSS(true))
                                    onClick {
                                        state = ContextMenuState.LINK
                                    }
                                }) { Text(if (menu?.link != null) "Редактировать ссылку" else "Вставить ссылку") }
                            }
                        }
                    }
                    ContextMenuState.LINK -> {
                        Form(attrs = { classNames("px-4 py-3") }) {
                            Div({ classNames("mb-3") }) {
                                Label(forId = linkNameId, attrs = { classNames("form-label") }) { Text("Имя ссылки") }

                                textInputHeadless(
                                    linkNameId,
                                    linkName,
                                    true,
                                    placeholder = "Имя ссылки",
                                    isFocused = true
                                ) {
                                    linkName = it
                                }
                            }
                            Div({ classNames("mb-3") }) {
                                Label(forId = linkHrefId, attrs = { classNames("form-label") }) { Text("Ссылка") }

                                textInputHeadless(
                                    linkHrefId,
                                    linkHref,
                                    true,
                                    placeholder = "Ссылка"
                                ) {
                                    linkHref = it
                                }
                            }

                            Button({
                                type(ButtonType.Button)
                                classNames("btn btn-secondary")
                                if (linkHref.isEmpty() || linkName.isEmpty()) disabled()
                                onClick {
                                    handleLink(linkName, linkHref)
                                }
                            }) {
                                Text("Сохранить")
                            }
                        }
                    }
                    ContextMenuState.INFO -> {
                        A(null, {
                            classNames(positionCSS(false))

                        }) { B { Text("Вставка и копирование в ${APP_NAME}") } }
                        A(null, {
                            classNames(positionCSS(false))

                        }) { B {
                            Text("Через меню эти операции недоступны,")
                            Br()
                            Text("но вы можете использовать:")
                        } }

                        dc?.let {
                            A(null, {
                                classNames(positionCSS(false))

                            }) {
                                B { Text(it.shortcuts.getShortcut("Копировать")) }
                                Br()
                                Text("для копирования")
                            }

                            A(null, {
                                classNames(positionCSS(false))

                            }) {
                                B { Text(it.shortcuts.getShortcut("Вставить")) }
                                Br()
                                Text("для вставки")
                            }

                            A(null, {
                                classNames(positionCSS(false))

                            }) {
                                B { Text(it.shortcuts.getShortcut("Вырезать")) }
                                Br()
                                Text("для вырезания")
                            }

                            A(null, {
                                classNames(positionCSS(true))
                                onClick {
                                    state = ContextMenuState.BASIC
                                }
                            }) { Text("Ок") }
                        }
                    }
                }
            }
        }
    }
}

fun openContextMenu(docContext: DocContext, menuData: ContextMenuData, p: Point, onMenuClose: () -> Unit) {
    try {
        Router.pushModal { doClose ->
            val id = remember { randomId(7) }

            val scope = ContextMenu(p).apply {
                dc = docContext
                menu = menuData

                onClose {
                    onMenuClose()
                    doClose()
                }
            }

            scope.render(id)
        }
    } catch (t: Throwable) {
        console.error("ошибка при создании модального диалога: $t")
        t.printStackTrace()
    }
}