package controls

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun Txt(text: String,
        clsNames: String?=null,
        me: Int?=null,
        ms: Int?=null,
        mt: Int?=null,
        mb: Int?=null,
        fontSize: CSSNumeric?=null
) {
    Div({
        clsNames?.let { classNames(it) }
        ms?.let { classes("ms-$it")}
        me?.let { classes("me-$it")}
        mt?.let { classes("mt-$it")}
        mb?.let { classes("mb-$it")}
        fontSize?.let { style { fontSize(it) } }
    }) {
        Text(text)
    }
}