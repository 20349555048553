package controls

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Table
import org.w3c.dom.HTMLTableElement

@Composable
fun PlainTable(attrs: AttrBuilderContext<HTMLTableElement>?=null, contents: ContentBuilder<HTMLTableElement>) {
    Table({
        attrs?.invoke(this)
        classes("table")
    }, contents)
}

@Composable
fun ResponsiveTable(attrs: AttrBuilderContext<HTMLTableElement>?=null, contents: ContentBuilder<HTMLTableElement>) {
    Di("table-responsive-sm") {
        Table({
            attrs?.invoke(this)
            classes("table")
        }, contents)
    }
}