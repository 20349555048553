import androidx.compose.runtime.Composable
import controls.BtnLink
import controls.Template
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import views.Heading
import views.ModalCodeActivation
import views.unaryPlus

@Composable
fun HelpOnLocked() {
    Template {
        Heading("Если лимит исчерпан")


        P {
            +"""Если ваши данные превысили лимит, например, при истечении действия купленного хранилища,
            |ваша учетная запись переводится в режим только для чтения. Это означает, что ваши данные вам
            |будут доступны, вы сможете их скопировать, или удалить, но не сможете изменить, кроме удаления.
        """.trimMargin()
        }
        P {
            +"""Если вы просто превысили свой лимит, то вы можете удалить окончательно часть документов,
                |учетная запись разблокируется, как только объем хранимых данных станет допустимым. Или вы
                |можете увеличить лимит,
            """.trimMargin()
            +" "
            BtnLink("активировав скретч-код") {
                ModalCodeActivation()
            }
            +"."
        }
        Div {
            +"""В заблокированном состоянии ваша учетная запись будет храниться в системе не менее трех месяцев,
                |после чего может быть удалена системой. Претензии в этом случае не принимаются, технической
                |возможности восстановления данных не будет.
            """.trimMargin()
        }
    }
}