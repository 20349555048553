package document

class SpanBuilder(var paragraph: Fragment.Paragraph, val style: TextStyle? = null) {
    val currentText = StringBuilder()
    fun t(text: String) {
        currentText.append(text)
    }

    fun build(): Fragment.Paragraph {
        emitText()
        return paragraph
    }

    fun span(style: TextStyle? = null, builder: SpanBuilder.() -> Unit) {
        // if there is some text, eimt para text:
        emitText()
        // now it is safe to add another styled node
        paragraph = SpanBuilder(paragraph, style).also { it.builder() }.build()
    }

    private fun emitText() {
        val t = currentText.toString()
//        if (t.isNotEmpty()) {
            paragraph += Fragment.StyledSpan(t, style)
//        }
        currentText.clear()
    }

    fun b(builder: SpanBuilder.() -> Unit) = span(TextStyle.bold, builder)
    fun b(text: String) = b { t(text) }
    fun i(builder: SpanBuilder.() -> Unit) = span(TextStyle.italics, builder)
    fun bi(builder: SpanBuilder.() -> Unit) = span(TextStyle.boldItalics, builder)
    fun i(text: String) = span(TextStyle.italics) { t(text) }
    fun u(builder: SpanBuilder.() -> Unit) = span(TextStyle.underline, builder)
    fun u(text: String) = u{ t(text) }
}