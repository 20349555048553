package views

import DocumentPage
import Router
import androidx.compose.runtime.*
import client
import controls.Toaster
import controls.WaitMessage
import editor.RenderMode
import kotlinx.browser.window
import net.sergeych.cloudoc.doc.Cloudoc
import net.sergeych.superlogin.InitSuperlogin

@Composable
fun GuestVeiwDocument() {
    var cloudoc: Cloudoc? by remember { mutableStateOf(null) }

    cloudoc?.let { DocumentPage(it, RenderMode.READONLY) }
        ?: WaitMessage("Открываю секретную ссылку")

    LaunchedEffect(true) {
        InitSuperlogin()
        val share = "${window.location.pathname}${window.location.hash}".drop(1)
        Cloudoc.openByShare(client, share)?.let { cd ->
            console.log("cloudoc here", cd)
            cloudoc = cd
//            Toaster.info("документ открыт в режиме анонимного просмотра")
        } ?: run {
            Toaster.warning("неправильная ссылка")
             Router.replace("/")
        }
    }
}