import androidx.compose.runtime.*
import controls.*
import controls.WaitMessage
import document.Block
import document.Doc
import editor.*
import kotlinx.coroutines.flow.toList
import kotlinx.coroutines.launch
import net.sergeych.cloudoc.LocalEvent
import net.sergeych.cloudoc.api.ApiEvent
import net.sergeych.cloudoc.doc.Cloudoc
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import tools.Debouncer
import views.EditHeading
import views.HelpPanel
import views.TextStyleToolbar
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.Duration.Companion.seconds

val FORCE_SAVE_TIMEOUT = 30.seconds
val PAUSE_SAVE_TIMEOUT = 7.seconds
val SPELLCHECKER_TIMEOUT = 100.milliseconds

@Composable
fun DocumentEditor(dc: DocContext, renderMode: RenderMode = RenderMode.EDITOR, cloudoc: Cloudoc? = null) {
    var exportMode by remember { mutableStateOf(RenderMode.READONLY) }

    LaunchedEffect(true) {
        launch {
            dc.exporter.exportMode.collect {
                exportMode = it
            }
        }
    }

    if (renderMode.isInteractive) HelpPanel()
//    if( cloudoc != null )
//        collaboratorsDialog(cloudoc)
    var showWaitSaving by remember { mutableStateOf(false) }

    if (renderMode.isInteractive) DisposableEffect(cloudoc) {
        val h = Router.quitHandler {
            if( dc.doc.docIsDirty.value ) {
                showWaitSaving = true
                globalLaunch {
                    dc.save(cloudoc!!)
                    disconnect()
                    retry()
                    showWaitSaving = false
                }
                false
            }
            else true
        }
        onDispose {
            h.disconnect()
            if( dc.doc.docIsDirty.value ) {
                println("-- document is still not saved - schedule it")
                showWaitSaving = true
                globalLaunch {
                    println("sceduled save after editor closed")
                    dc.save(cloudoc!!)
                }
            }
            dc.spellChecker?.terminate()

        }
    }

    if( showWaitSaving )
        WaitMessage("сохраняю документ")
    else {
        if (renderMode == RenderMode.PRINT) {
            ExportContainer(dc, RenderMode.PRINT)
        } else {
            Div({ classes("container-fluid") }) {
                cloudoc?.let { EditHeading(it, dc, renderMode) }

                Di("row", {
                    style {
                        paddingTop(4.8.em)
                    }
                }) {
                    Di("col-1 d-none d-sm-block") {
                        // This is a place  for left float menu/doc structure/etc
                    }
                    Di("col") {
                        RenderDocContent(dc.doc, dc, renderMode)
                    }
                    Di("col-1 d-none d-sm-block") {
                        // This is a place for comments
                    }
                }
            }

            if (renderMode != RenderMode.READONLY) Di("position-fixed bottom-0 end-0 p-2 border d-none d-sm-block", {
                style {
                    property("z-index", 1000)
                    cursor("pointer")
                }

                attr("data-bs-toggle", "offcanvas")
                attr("data-bs-target", "#helpKeys")
            }) {
                Icon.Keyboard.render({ style { fontSize(1.5.em) } })
            }

            if (renderMode.isInteractive) Di("position-fixed bottom-0", {
                style {
                    display(DisplayStyle.Flex)
                    property("justify-content", "center")
                    property("left", 0)
                    property("right", 0)
                    property("z-index", 100)
                    paddingBottom(10.px)
                }
            }) {
                TextStyleToolbar(dc.doc, dc)
            }

            ExportContainer(dc, exportMode)
//            ExportContainer(dc, RenderMode.DOCX)
        }
    }
}

fun isPrintMode(): Boolean {
    return Router.queryParam("print") == "true"
}

@Composable
fun DocumentPage() {
    val docId = Router.param(0)
    var cloudoc: Cloudoc? by remember { mutableStateOf(null) }
    var renderMode by remember { mutableStateOf(RenderMode.EDITOR) }
    val isPrint = isPrintMode()

    LaunchedEffect(docId) {
        if (docId != null) {
            if (renderMode == RenderMode.EDITOR) Browser.init()

            cloudoc = Cloudoc.openById(client, docId.toLong())!!
            renderMode = if (cloudoc?.role?.canWrite == false) RenderMode.READONLY else renderMode
            if (!isPrint) cloudoc?.events()?.collect {
                when(it) {
                    is ApiEvent.Doc.Erased -> {
                        Router.back()
                        Toaster.info("доступ к документу невозможен")
                    }
                    is ApiEvent.Doc.ShareChanged -> {
                        cloudoc?.sharedWith()?.firstOrNull { it.user.id == client.currentUser?.id }?.let {
                            println("updating readonly status to $it")
                            renderMode = if (!it.role.canWrite) RenderMode.READONLY else renderMode
                        }
                    }
                    else -> {}
                }
            } ?: run {
                Router.back()
                Toaster.info("доступ к документу невозможен")
            }
        }
    }

    cloudoc?.let {
        DocumentPage(it, renderMode)
    } ?: WaitMessage("открываю документ")
}

@Composable
fun DocumentPage(cloudoc: Cloudoc, renderMode: RenderMode = RenderMode.EDITOR) {
    var dc: DocContext? by remember { mutableStateOf(null) }
    var hasLimit: Boolean? by remember { mutableStateOf(client.storageState.value?.let { it.available > 0}) }
    val mode = if (isPrintMode()) RenderMode.PRINT else renderMode

    LaunchedEffect(cloudoc.docId) {
        val bodyBlocks = cloudoc.bodyBlocks().toList().map { it.decode<Block>() }.filterNotNull()
        val document = Doc()
//        console.log("Load document: started")
        document.initializeWithBlocks(bodyBlocks)
//        console.log("Load document: done, blocks total ${bodyBlocks.size}")
        document.calculateViewProperties()
//        console.log("Load document: calculated view props")
        val ctx = DocContext(document)
        ctx.cloudoc = cloudoc
//        console.log("Load document: created context")

        if (mode.isInteractive) {
            ctx.doc.withLock("dc.setState") { ctx.setState(cloudoc.state?.decode()) }
        }

        dc = ctx

        if (mode.isInteractive) {
            // Watch the dirty state and translate it into clouduc events
            launch {
                ctx.doc.docIsDirty.collect {
                    if (DebugList.cloudocEvents) console.log("doc is dirty, send dirty event")
                    if (it) cloudoc.push(LocalEvent.Doc.Dirty(cloudoc.docId))
                }
            }

            var checkers = mutableMapOf<String, Debouncer?>()
            var onPauseSaver: Debouncer? = null
            var forceSaver: Debouncer? = null
            val scope = this

            fun handleChecker(e: Doc.Event.UpdateBlock) {
//                console.log("handle check for ${e.block.guid}")
                val guid = e.block.guid

                checkers[guid]?.cancel()
//                dc?.spellChecker?.mark(guid)
                checkers[guid] = Debouncer(scope, SPELLCHECKER_TIMEOUT) {
                    Toaster.launchCatching {
                        globalLaunch {
                            dc?.spellChecker?.
                            dc?.spellChecker?.runChecker(listOf(guid))
                        }
                    }
                }
                checkers[guid]?.schedule()
            }

            fun scheduleForceSaver() {
                forceSaver?.cancel()
                forceSaver = Debouncer(this, FORCE_SAVE_TIMEOUT) {
                    if (DebugList.save) console.warn("[SAVE]: forced run")
                    dc?.save(cloudoc)
                    forceSaver?.schedule()
                }
                forceSaver?.schedule()
            }

            fun schedulePauseSaver() {
                onPauseSaver?.cancel()
                onPauseSaver = Debouncer(this, PAUSE_SAVE_TIMEOUT) {
                    if (DebugList.save) console.warn("[SAVE]: on pause run")
                    dc?.save(cloudoc)
                    scheduleForceSaver()
                }
                onPauseSaver?.schedule()
            }

            scheduleForceSaver()

//            dc?.spellChecker?.scheduleAll()

            // REFACTOR
            ctx.doc.events.collect {
                if (it !is Doc.Event.RedrawBlock) {
                    schedulePauseSaver()

                    // REFACTOR
                    if (it is Doc.Event.UpdateBlock) handleChecker(it)
                }
            }
        }
    }

    if (mode.isInteractive) LaunchedEffect(cloudoc.docId) {
        cloudoc.events().collect {
            when(it) {
                is ApiEvent.Doc.BodyChanged -> {
                    if (it.lastSerial > cloudoc.lastSerial) {
                        Toaster.launchCatching {
                            dc?.merge(cloudoc)
                        }
                    }
                }
                else -> {
                    if (DebugList.cloudocEvents) console.log("Received ApiEvent.Doc", it.toString())
                }
            }
        }
    }

    if (mode.isInteractive) LaunchedEffect(true) {
        client.storageState.collect {
            hasLimit = it?.let { it.available > 0 }
        }
    }

    // Copy current values: sources can be changed outside
    val documentContext = dc
    val limitOk = if (mode.isInteractive) hasLimit else true

    if( documentContext == null || limitOk == null ) {
        WaitMessage("загружаю документ")
    }
    else {
        if (limitOk) {
            DocumentEditor(documentContext, mode, cloudoc)
        }
        else {
            DocumentEditor(documentContext, RenderMode.READONLY, cloudoc)
            Toaster.warning("лимит хранения исчерпан, изменения невозможны")
        }
    }
}