package document

import editor.invisibleNBSP
import editor.truncateEnd
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
data class Block(
    val paragraph: IParagraph,
    val prevBlockGuid: String? = null,
    val nextBlockGuid: String? = null,
    val revision: Int = 0,
    val isFocusable: Boolean? = true,
    @Transient
    val renderVersion: Int = 0
) : IParagraph by paragraph, L2Caret<Block> {
    override val prevGuid: String?
        get() { return prevBlockGuid }

    override val nextGuid: String?
        get() { return nextBlockGuid }

    override fun copyListElement(prev: String?, next: String?): Block {
        return copy(prevBlockGuid = prev, nextBlockGuid = next)
    }

    override fun contentEquals(other: Block): Boolean {
        return paragraph == other.paragraph
    }

    val firstStyledSpan: Fragment.StyledSpan by lazy { paragraph.firstTextFragment() }
    val lastStyledSpan: Fragment.StyledSpan by lazy { paragraph.lastTextFragment() }

    fun withUpdatedFragment(f: Fragment): Block = copy(paragraph = paragraph.replaceFragment(f))

    fun withUpdatedParagraph(p: IParagraph): Block = copy(paragraph = p)

    /**
     * @return copy of the block withut the paragraph
     */
    fun withoutFragment(guid: String): Block {
        if (paragraph.elements.size > 1) return copy(paragraph = paragraph.removeFragment(guid))
        else {
            var updatedParagraph = paragraph

            val element = paragraph.elements.first()
            when (element) {
                is Fragment.StyledSpan -> {
                    updatedParagraph = paragraph.replaceFragment(element.copy(text = ""))
                }

                else -> {
                    val emptySpan = Fragment.StyledSpan("")
                    updatedParagraph = paragraph.replaceFragments(listOf(element), listOf(emptySpan))
                }
            }
            return copy(paragraph = updatedParagraph)
        }
    }

    fun withoutFragment(f: Fragment) = withoutFragment(f.guid)

    val firstStyledSpanOrNull: Fragment.StyledSpan? =
        elements.firstOrNull { it is Fragment.StyledSpan } as Fragment.StyledSpan?

    override val isEmpty by lazy { paragraph.isEmpty }
    override val isNotEmpty by lazy { paragraph.isNotEmpty }
    override val isBlank by lazy { paragraph.isBlank }
    override val plainText by lazy { paragraph.plainText }

    override fun toString(): String = "B:${guid.truncateEnd(6)}:${plainText.truncateEnd(100)}"
//    operator fun contains(caret: Caret?): Boolean = caret?.path?.get(0) == guid

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class.js != other::class.js) return false

        other as Block

        return paragraph == other.paragraph && renderVersion == other.renderVersion
    }

    override fun hashCode(): Int {
        return guid.hashCode()
    }

    fun appendContent(other: Block): Block = copy(paragraph = paragraph + other.paragraph as Fragment)

    val asInvisible by lazy { copy(paragraph = paragraph.makeCopy(isVisible = false)) }

    val fragments: Sequence<Fragment> get() = paragraph.all()
}

