package editor

import androidx.compose.runtime.Composable
import androidx.compose.runtime.SideEffect
import androidx.compose.runtime.rememberCoroutineScope
import kotlinx.browser.window
import kotlinx.coroutines.launch
import net.sergeych.mp_tools.globalLaunch
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.INSTANT
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions

val CaretCSS = "blinking-cursor"
val CaretId = "_caret"

/**
 * Render caret and scroll document to fit
 */
@Composable
fun renderCaret(dc: DocContext, mode: RenderMode, renderVersion: String? = null) {
    if (!mode.isInteractive) return

    val scope = rememberCoroutineScope()
    val observer = dc.domObserver

    SideEffect {
        scope.launch {
            val container = window.document.getElementById(DOCUMENT_CONTENT_ID)
            val box = dc.caretBoxDOM() ?: return@launch

            container?.let {
                window.document.documentElement?.let { html ->
                    val b3 = box.height * 3
                    when (dc.lastMove.direction) {
                        MoveDirection.UP -> {
                            if (html.scrollTop != 0.0 && box.top - html.scrollTop < b3) {
//                            html.scrollTop -= b3
                                window.scrollTo(
                                    ScrollToOptions(
                                        top = html.scrollTop - b3,
                                        behavior = ScrollBehavior.INSTANT
                                    )
                                )
                            }
                        }

                        MoveDirection.DOWN -> {
                            if (html.scrollTop < html.scrollHeight && (html.scrollTop + html.clientHeight - box.bottom) < b3) {
//                            html.scrollTop += b3
                                window.scrollTo(
                                    ScrollToOptions(
                                        top = html.scrollTop + b3,
                                        behavior = ScrollBehavior.INSTANT
                                    )
                                )
                            }
                        }

                        else -> {}
                    }
                }
            }
        }

//        logger.warning { "Caret recomposed" }
//        dc.domObserver.finish("caret")
    }

    Div({
        id(CaretId)
        classes(CaretCSS)
        domVersion(dc.domObserver, CaretId, renderVersion)
    }) { Text("|") }
}