package controls

import androidx.compose.runtime.*
import editor.DOCUMENT_CONTROL_CLASS
import editor.FrameMargin
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.type
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLLabelElement
import tools.randomId

@Composable
fun marginField(
    value: FrameMargin,
    units: List<String>,
    label: String? = null,
    labelContent: ContentBuilder<HTMLLabelElement>? = null,
    isValid: Boolean? = null,
    message: String? = null,
    type: InputType<String> = InputType.Text,
    floating: Boolean = true,
    additionalClasses: String? = "mb-3",
    attrs: AttrBuilderContext<HTMLInputElement>?=null,
    setter: (FrameMargin) -> Unit,
) {
    var amount by mutableStateOf(value.amount)
    var unit by mutableStateOf(value.unit)
    val controlId: String = remember { randomId(17) }
    val hasLabel = label != null || labelContent != null

    Label(controlId, { classes("form-label") }) {
        label?.let { Text(it) }
        labelContent?.invoke(this)
    }
    Di("input-group mb-3") {
        if (unit != "auto") textInputHeadless(
            controlId,
            amount,
            hasLabel,
            if (!hasLabel) "" else null,
            isValid,
            InputType.Text,
            null
        ) { newAmount ->
            amount = newAmount
            setter(FrameMargin(newAmount, unit))
        } else Input(InputType.Text) {
            disabled()
            classNames("form-control")
        }

        Button({
            type(ButtonType.Button)
            classNames("btn btn-outline-secondary dropdown-toggle")
            attr("data-bs-toggle", "dropdown")
            attr("aria-expanded", "false")
        }) {
            Text(unit)
        }

        Ul({
            classNames("dropdown-menu dropdown-menu-end")
        }) {
            units.forEach { u ->
                Li {
                    A(null, {
                        classNames("dropdown-item $DOCUMENT_CONTROL_CLASS")
                        onClick {
                            unit = u
                            var a = amount
                            if (u == "auto") {
                                amount = ""
                                a = ""
                            }

                            setter(FrameMargin(a, u))
                        }
                    }) { Text(u) }
                }
            }
        }

//        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Dropdown</button>
//        <ul class="dropdown-menu dropdown-menu-end">
//        <li><a class="dropdown-item" href="#">Action</a></li>
//        <li><a class="dropdown-item" href="#">Another action</a></li>
//        <li><a class="dropdown-item" href="#">Something else here</a></li>
//        <li><hr class="dropdown-divider"></li>
//        <li><a class="dropdown-item" href="#">Separated link</a></li>
//        </ul>
    }
}