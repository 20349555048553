package document

import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.*
import kotlinx.coroutines.sync.Mutex
import kotlinx.coroutines.sync.withLock
import net.sergeych.mp_tools.globalLaunch

/**
 * Smart document mutex & event queue, 2 in 1. Implements automatic buffering of the events when is locked
 * amd emitting deduplicated events from buffer on unlock. Can be a direct replacement of the
 * Doc's mutex and events flow.
 */
class DocQueueAndMutex(private val mutex: Mutex = Mutex()) : SharedFlow<Doc.Event>, Mutex by mutex {

    private val readyEvents = MutableSharedFlow<Doc.Event>(extraBufferCapacity = 128)

    /**
     * Collect is exactly as a [SharedFlow.collect]
     */
    override suspend fun collect(collector: FlowCollector<Doc.Event>): Nothing {
        collector.emitAll(readyEvents)
        throw Exception("it should not happen")
    }

    /**
     * Schedule event. It will be processed in a separate coroutine, e.g. optimized
     * against yet undelivered messages, and then buffered events will be delivered
     * if the document (this mutex) is not locked
     */
    fun smartEmit(event: Doc.Event) {
        globalLaunch { addToBuffer(event) }
    }

    /**
     * Synchronize access to the buffered event, [buffer]
     */
    private val bufferLock = Mutex()

    /**
     * When the main [mutex] is locked, we buffer and optimize events in our queue
     */
    private val buffer = mutableListOf<Doc.Event>()

    /**
     * Add event to buffered and optimize it (deduplicate as for now). The new event is always placed to the end
     * of the buffer.
     *
     * It will then attempt to empty the buffer if it is possible. see [emptyBuffer]
     */
    private suspend fun addToBuffer(event: Doc.Event) {

        fun removeExisting() {
            buffer.removeAll { event.block.guid == it.block.guid }
        }

        fun removeExistingOfThisType() {
            buffer.removeAll { event.block.guid == it.block.guid && event::class == it::class }
        }

        fun addToEnd() {
            buffer.add(event)
        }
        bufferLock.withLock {
            when (event) {
                is Doc.Event.UpdateCaretBlock, is Doc.Event.UpdateBlock, is Doc.Event.RedrawBlock  -> {
                    removeExistingOfThisType()
                    addToEnd()
                }
                is Doc.Event.DeleteBlock -> {
                    removeExisting()
                    addToEnd()
                }

                is Doc.Event.NewBlock -> addToEnd()
            }
        }
        emptyBuffer()
    }

    /**
     * If the document is not locked, try to send all buffered events. On sending queue overflow (not collected
     * events) will retry it after some delay. This means, all collectors MUST COLLECT!
     */
    private suspend fun emptyBuffer() {
        if (bufferLock.isLocked) throw Exception("buffer should not be locked")
        bufferLock.withLock {
            if (!isLocked) {
                while (buffer.isNotEmpty() && !isLocked) {
                    val e = buffer.first()
                    if (!readyEvents.tryEmit(e)) {
                        console.warn("Can't emit: event flow overfloved. will retry!")
                        delay(500)
                        console.log("attempt to continue sending buffered events (${buffer.size} left)")
                    } else
                        buffer.removeFirst()
                }
            }
        }
    }


    /**
     * Overriden unlock send all the buffered events in a separate coroutine, _after unlocking_ this mutex.
     */
    override fun unlock(owner: Any?) {
        mutex.unlock(owner)
        globalLaunch { emptyBuffer() }
    }

    /**
     * Please don't use replay cache on this class, it is neither needed nor implemented.
     */
    override val replayCache: List<Doc.Event> = listOf()

}