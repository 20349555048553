package editor

import controls.Dialog
import document.*
import io.ktor.util.*

fun <T: L2Element<T>> getById(id: String, list: List<T>): T? {
    return list.find { it.guid == id }
}

fun <T: L2Element<T>> getFirstById(id: String, searchPriority: List<List<T>>): T {
    var found: T? = null

    var i = 0
    while (i < searchPriority.size && found == null) {
        found = searchPriority[i].find { it.guid == id }
        i++
    }

    if (found == null) throw BugException("Can't find block in transaction sum")

    return found
}

fun <T: L2Element<T>> Chain<T>.getTransaction(): RevertableTransaction<T> {
    val redo = getAUDTransaction()
    val undo = getRevertAUDTransaction()

    return RevertableTransaction(redo, undo)
}

fun <T: L2Element<T>> Chain<T>.getAUDTransaction(): AUDTransaction<T> {
    val caretBefore = initialCaret ?: throw BugException("caret needed for transaction")
    val caretAfter = caret ?: throw BugException("caret needed for transaction")

    return AUDTransaction(
        caretBefore,
        caretAfter,

        getInserted(),
        getUpdated(),
        getDeleted()
    )
}

fun <T: L2Element<T>> Chain<T>.getRevertAUDTransaction(): AUDTransaction<T> {
    val caretBefore = initialCaret ?: throw BugException("caret needed for transaction")
    val caretAfter = caret ?: throw BugException("caret needed for transaction")

    val updatedIDs = getUpdated().map { it.guid }
    val initial = initialElements.filter { updatedIDs.contains(it.guid) }

    if (updatedIDs.size != initial.size)
        throw BugException("Can't find update block in initial list")

    return AUDTransaction(
        caretAfter,
        caretBefore,

        getDeleted(),
        initial,
        getInserted()
    )
}

fun <T: L2Element<T>> Chain<T>.apply(other: Chain<T>) {
    applyAUDTransaction(other.getAUDTransaction())
}

fun <T: L2Element<T>> Chain<T>.applyAUDTransaction(t: AUDTransaction<T>) {
    val addChains = PartialChain.getChains(t.add)

    addChains.forEach {
        val prevId = it.elements.first().prevGuid ?: throw BugException("trying to add another root block")
        insertChain(it, prevId)
    }

    t.delete.forEach {
        delete(it.guid)
    }

    t.update.forEach {
        update(it)
    }

    caret = t.caretAfter
}

fun AUDTransaction<Block>.printCase(name: String) {
    val varname = name.split(' ').joinToString("_").toLowerCasePreservingASCIIRules()

    fun printList(l: List<Block>): String {
        if (l.size == 0) return """
        listOf(),
        """.trimIndent()
        val lElements = l.map {
            var text = it.plainText
            if (text.endsWith('\n')) text = text.substring(0, text.length - 1)
            "DummyL2(prevGuid = \"${it.prevGuid}\", guid = \"${it.guid}\",  nextGuid = \"${it.nextGuid}\", content=\"${text}\")"
        }.joinToString(",\n            ")

        return """listOf(
            ${lElements}
        ),
        """.trimIndent()
    }

    if (isEmpty()) {
        console.log("    // AUD TRANSACTION [${name}]: EMPTY")
        return
    }

    console.log(""""
    // AUD TRANSACTION [${name}]: 
    val ${varname} = simpleAUD(
        ${printList(add)}
        ${printList(update)}
        ${printList(delete)}
    )
        """)
}

data class AUDTransaction<T: L2Element<T>>(
    val caretBefore: Caret? = null,
    val caretAfter: Caret? = null,

    val add: List<T>,
    val update: List<T>,
    val delete: List<T>,
) {
    /*
     * A - blocks to add
     * U - blocks to update
     * D - blocks to delete
     *
     * Sum of two AUD transactions T3 = T1 + T2 will be:
     *
     * A3 = (A1 + A2) - (D1 + D2)
     * U3 = (U1 + U2) - (A1 + A2)
     * D3 = (D1 + D2) - (A1 + A2)
     */
    operator fun plus(other: AUDTransaction<T>): AUDTransaction<T> {
        val a1 = add.map { it.guid }.toSet()
        val u1 = update.map { it.guid }.toSet()
        val d1 = delete.map { it.guid }.toSet()
        val a2 = other.add.map { it.guid }.toSet()
        val u2 = other.update.map { it.guid }.toSet()
        val d2 = other.delete.map { it.guid }.toSet()

        val aSum = a1 + a2
        val uSum = u1 + u2
        val dSum = d1 + d2

        val a3 = aSum - dSum
        val u3 = uSum - aSum - dSum
        val d3 = dSum - aSum

        return AUDTransaction<T>(
            caretBefore,
            other.caretAfter,

            a3.map { getFirstById(it, listOf(other.update, other.add, add)) },
            u3.map { getFirstById(it, listOf(other.update, update)) },
            d3.map { getFirstById(it, listOf(other.delete, delete)) }
        )
    }

    fun getRevertTransaction(oldBlocks: List<T>): AUDTransaction<T> {
        return AUDTransaction(
            caretAfter,
            caretBefore,

            delete,
            update.map { getById(it.guid, oldBlocks) ?: throw BugException("no updated block in document") },
            add
        )
    }

    fun print(name: String) {
        fun prefixed(m: String) {
            console.log("[AUD ${name}]: ${m}")
        }
        prefixed("BEGIN")
        add.forEach {
            prefixed("INSERT ${it.guid} -> ${it.nextGuid} <- ${it.prevGuid}")
        }

        delete.forEach {
            prefixed("DELETE ${it.guid} -> ${it.nextGuid} <- ${it.prevGuid}")
        }

        update.forEach {
            prefixed("UPDATE ${it.guid} -> ${it.nextGuid} <- ${it.prevGuid}")
        }
        prefixed("END")
    }

    fun printShort(name: String) {
        console.log(""""
            AUD TRANSACTION [${name}]: 
            add=${add.joinToString(", ") { it.guid }}
            update=${update.joinToString(", ") { it.guid }}
            delete=${delete.joinToString(", ") { it.guid }}
            caretBefore=${caretBefore?.toFullString()}
            caretAfter=${caretAfter?.toFullString()}
        """)
    }

    fun isEmpty(): Boolean {
        return add.isEmpty() && update.isEmpty() && delete.isEmpty()
    }
}

data class RevertableTransaction<T: L2Element<T>>(
    val redo: AUDTransaction<T>,
    val undo: AUDTransaction<T>
) {
    operator fun plus(other: RevertableTransaction<T>): RevertableTransaction<T> {
        return RevertableTransaction(
            redo + other.redo,
            other.undo + undo
        )
    }
}

suspend fun Doc.runTransaction(
    name: String,
    transaction: AUDTransaction<Block>,
    shouldSetDirty: Boolean = true,
    debug: Boolean = false
) {
    fun log(message: String, isWarn: Boolean = false) {
        if (!debug) return
        val msg = "=================== [$name][DOC]: $message"
        if (isWarn) console.warn(msg)
        else console.log(msg)
    }

    withLock("doc.runTransaction") {
//        log("BEGIN")
        val caretAfter = transaction.caretAfter ?: throw BugException("no caret after transaction")
        val add = PartialChain.getChains(transaction.add)
        val delete = PartialChain.getChains(transaction.delete)

        add.forEach {
            it.elements.forEach {
//                log("insert block ${it.guid} after ${it.prevBlockGuid}")
//                log("DOC BEFORE S")
                allBlocks.toList().forEach {
//                    log("${it.prevGuid} <- ${it.guid} -> ${it.nextGuid}")
                }
//                log("DOC BEFORE E")
                insertBlockAfter(it.prevBlockGuid, it, shouldSetDirty)
            }
        }

        delete.forEach {
            it.elements.reversed().forEach {
//                log("remove block ${it.guid}")
//                log("DOC BEFORE S")
                allBlocks.toList().forEach {
//                    log("${it.prevGuid} <- ${it.guid} -> ${it.nextGuid}")
                }
//                log("DOC BEFORE E")
                removeBlockSafe(it.guid, shouldSetDirty)
            }
        }

        transaction.update.forEach {
//            log("update block ${it}")
//            log("DOC BEFORE S")
            allBlocks.toList().forEach {
//                log("${it.prevGuid} <- ${it.guid} -> ${it.nextGuid}")
            }
//            log("DOC BEFORE E")
            updateBlockSafe(it, shouldSetDirty)
        }

        val caretBlock = this[caretAfter.blockId]
        sendCaretBlockUpdate(caretBlock, caretAfter)

        log("END")
    }
}

suspend fun DocContext.runTransaction(
    name: String = "",
    canUndo: Boolean = true,
    debug: Boolean = DebugList.transaction,
    shouldSetDirty: Boolean = true,
    shouldResetChain: Boolean = false,
    isReplayForcedUndo: Boolean = false,
    fn: suspend (chain: DocChain, transactionStyle: TextStyle?) -> Unit
) {
    fun log(message: String, isWarn: Boolean = false) {
        if (!debug) return
        val msg = "=================== [$name]: $message"
        if (isWarn) console.warn(msg)
        else console.log(msg)
    }

    doc.withLock("dc.runTransaction") {
        log("RUN shouldSetDirty = ${shouldSetDirty}")

        val chain = DocChain(doc.allBlocksCopy, caret, name, debug)
        val debugChain = DocChain(chain.initialElements, caret, name, debug)
        var isOk = true

        try {
            fn(chain, nextTransactionStyle)
            if (chain.elements.isEmpty() || !chain.isLinked()) throw BugException("Broken chain")
            resetNextTransactionStyle()
            lastSavedChain?.apply(chain)
        } catch(e: Throwable) {
            isOk = false
            console.error(e)
            log("ERROR: ${e.message}", true)
            setIsActive(false)

            replay.onError(e.toString() + "\n" + e.stackTraceToString())
        }

        if (isOk && chain.isChanged()) {
            val transaction = chain.getAUDTransaction()

            if (debug) transaction.printCase(name)
            val caretBefore = transaction.caretBefore ?: throw BugException("no caret before transaction")
            val caretAfter = transaction.caretAfter ?: throw BugException("no caret after transaction")

            if (canUndo) {
                if (isReplay) {
                    undoManager.record(isReplayForcedUndo, chain)
                    replayUndoForce = false
                }
                else undoManager.record(false, chain)
            }
            else log("CAN'T UNDO THIS TRANSACTION")

            // DEBUG
//            domObserver.watch(doc.firstBlock.guid)

            chain.inserted.forEach { domObserver.watch(it, "runTransaction: inserted") }
            chain.updated.forEach { domObserver.watch(it, "runTransaction: updated") }
            if (caretBefore != caretAfter && showCaret) domObserver.watch(CaretId, "runTransaction: caret")

            doc.runTransaction(name, transaction, shouldSetDirty, debug)

            caret = chain.caret ?: throw BugException("Empty caret after transaction")

            if (shouldResetChain) {
                lastSavedChain = DocChain(doc.allBlocksCopy, chain.caret)
                replay.init(doc.allBlocksCopy, chain.caret)
            }
            log("DONE")
        } else {
            log("ABORTED ${if (isOk) "(EMPTY)" else "(ERROR)"}")
        }
    }
}
