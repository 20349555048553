package controls

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement

@Composable
fun Row(
    attrBuilderContext: AttrBuilderContext<HTMLDivElement>? = null,
    builder: ContentBuilder<HTMLDivElement>,
) {
    Div({
        classes("row")
        attrBuilderContext?.invoke(this)
    }) {
        builder()
    }
}

@Composable
fun Col(
    name: String? = null, attrs: AttrBuilderContext<HTMLDivElement>? = null,
    builder: ContentBuilder<HTMLDivElement>,
) {
    Div({
        name?.let { classes("col-$name") } ?: classes("col")
        attrs?.invoke(this)
    }) {
        builder()
    }
}

@Composable
fun RC(
    name: String? = null,
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    rowAttrs: AttrBuilderContext<HTMLDivElement>? = null,
    builder: ContentBuilder<HTMLDivElement>,
) {
    Row(rowAttrs) {
        Col(name, attrs, builder)
    }
}

