package views

import androidx.compose.runtime.*
import client
import controls.*
import kotlinx.browser.document
import kotlinx.coroutines.launch
import net.sergeych.cloudoc.api.ApiUser
import net.sergeych.cloudoc.api.ApiUserDetails
import net.sergeych.cloudoc.api.ErrorCode
import net.sergeych.mp_tools.globalLaunch
import net.sergeych.unikrypto.Passwords
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement

@Composable
fun Registration(additionalContent: ContentBuilder<HTMLDivElement>) {
    var loginName by remember { mutableStateOf("testUser") }
    var publicName by remember { mutableStateOf("test user") }
    var loginMessage by remember { mutableStateOf<String?>(null) }
    var codeMessage by remember { mutableStateOf<String?>(null) }
    var code by remember { mutableStateOf("") }
    var loginOk by remember { mutableStateOf<Boolean?>(null) }
    var password1 by remember { mutableStateOf("12345qwert") }
    var password1Message by remember { mutableStateOf("") }
    var password1Ok by remember { mutableStateOf<Boolean?>(null) }
    var password2 by remember { mutableStateOf("12345qwert") }
    var password2Ok by remember { mutableStateOf<Boolean?>(null) }
    var inProgress by remember { mutableStateOf(false) }

    val scope = rememberCoroutineScope()

    fun checkLogin() {
        when {
            loginName.trim() != loginName -> {
                loginOk = false
                loginMessage = "не должно содержать пробелов в начале и конце"
            }

            loginName.isEmpty() -> {
                loginOk = false
                loginMessage = "требуемое поле"

            }

            loginName.length < 3 -> {
                loginOk = false
                loginMessage = "слишком короткое (${loginName.length}"

            }

            loginName[0] == '.' -> {
                loginOk = false
                loginMessage = "не должно начинаться с точки"
            }

            else -> {
                loginOk = null
                loginMessage = null
                scope.launch {
                    println("Start check login")
                    loginOk = client.isLoginNameAvailable(loginName)
                    println("end check login: $loginOk")
                    loginMessage = if (loginOk == false) "недоступное имя" else null
                }
            }
        }
    }

    fun checkPasswords() {
        val strength = Passwords.estimateBitStrength(password1)
        when {
            password1.isEmpty() -> {
                password1Ok = false
                password1Message = "требуемое поле"
            }

            strength < 32 -> {
                password1Ok = false
                password1Message = "слишком слабый пароль (~$strength бит)"
            }

            else -> {
                password1Ok = true
                password1Message = "оценочная стойкость $strength бит"
            }
        }
        password2Ok = password1Ok == true && password1 == password2
    }

    checkLogin()
    checkPasswords()

    CenteredNarrowForm {
        H3 { Text("Регистрация нового пользователя:") }
        val loginId = textField(
            loginName,
            "Имя учетной записи (для системы)",
            isValid = loginOk,
            message = loginMessage
        ) {
            loginName = it
            checkLogin()
        }
        val m = ApiUser.checkName(publicName)
        textField(
            publicName,
            "Имя, видимое для других пользователей",
            message = m,
            isValid = m == null
        ) {
            publicName = it
        }
        textField(
            password1,
            "Пароль",
            type = InputType.Password,
            isValid = password1Ok,
            message = password1Message
        ) {
            password1 = it
            checkPasswords()
        }
        textField(
            password2,
            "Повторно пароль",
            type = InputType.Password,
            isValid = password2Ok,
            message = if (password2Ok == false && password1.isNotBlank()) "не совпадают" else null
        ) {
            password2 = it
            checkPasswords()
        }
        textField(
            code,
            "Код активации",
            type = InputType.Text,
            isValid = codeMessage == null,
            message = codeMessage
        ) {
            code = it
        }
        Bn({
            classNames("btn-primary me-2")
            if (inProgress || publicName.isBlank() || loginOk == false || password1Ok == false || password2Ok != true)
                disabled()
            onClick {
                inProgress = true
                globalLaunch {
                    val rr = client.register(
                        ApiUserDetails(
                            loginName = loginName, name = publicName
                        ),
                        if( code.isNotBlank()) code else null,
                        password1,
                        mainKeyStrength = 4096,
                        loginKeyStrength = 4096
                    )
                    inProgress = false
                    if( rr.isOk ) {
                        Toaster.info("Успешная регистрация")
                        // todo: show guid
                        ShowSecret(rr.resultOrThrow())
                    }
                    else {
                        loginMessage = null
                        when {
                            ErrorCode.LoginInUse in rr -> {
                                loginOk = false
                                loginMessage = "Имя недоступно"
                            }
                            ErrorCode.CodeIsRequired in rr -> codeMessage = "Для регистрации требуется код"
                            ErrorCode.CodeIsInvalid in rr -> codeMessage = "неправильный или неподходящий код"
                            ErrorCode.UnknownError in rr ->
                                Toaster.error("ошибка при регистрации, попробуйте еше раз позже")
                        }
                    }
                    inProgress = false
                }
            }
        }) {
            if( inProgress ) {
                Di("spinner-border spinner-border-sm d-inline-block me-1") {}
            }
            Text("Регистрация")
        }
        additionalContent()
        LaunchedEffect("registrationFocus") {
            (document.getElementById(loginId) as? HTMLInputElement)?.focus()
        }
    }
}