package views

import androidx.compose.runtime.Composable

@Composable
fun Home() {
    LoggedIn {
//        TestDoc()
        Directory()
    }
}
