package views

import androidx.compose.runtime.*
import controls.*
import net.sergeych.cloudoc.api.ApiUserDetails
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement

private enum class HomeGuestMode {
    Landing, Registration, Authentication
}

@Composable
fun LoggedIn(f: @Composable ElementScope<HTMLDivElement>.(ApiUserDetails) -> Unit) {
    var mode by remember { mutableStateOf(HomeGuestMode.Landing) }

    @Composable
    fun backButton() {
        Bn({
            classes("btn-link")
            onClick { mode = HomeGuestMode.Landing }
        }) {
            Text("отмена")
        }

    }
    Template { currentUser ->
        if (currentUser != null)
            f(currentUser)
        else {
            when (mode) {
                HomeGuestMode.Landing -> {
                    CenteredNarrowForm({ classes("text-center") }) {
                        H1 {
                            Img("/cloudocs-icon-md.png") {
                                style {
                                    height(1.1.em)
                                }
                                classes("me-1")
                            }

                            Text("cloudocs.ru")
                        }
                        P({classes("text-secondary")}) { Text("Демо верcия проекта") }
                        P {
                            Text(
                                """Для продолжения работы пожалуйста
                                |идентифицируйте себя или пройдите регистрацию.
                                """.trimMargin()
                            )
                        }

                        Row {
                            Col("sm-6",{classes("mb-2")}) {
                                Bn({
                                    success("w-100")
                                    onClick { mode = HomeGuestMode.Registration }
                                }) {
                                    Text("Новый тестер")
                                }
                            }
                            Col("sm-6") {
                                Bn({
                                    primary("w-100")
                                    onClick { mode = HomeGuestMode.Authentication }
                                }) {
                                    Text("Существующий тестер")
                                }
                            }
                            Col("12") {
                                Br {}
                                RouterLink("/testdoc","Тестовый документ")
                            }
                        }
                    }
                }

                HomeGuestMode.Registration -> Registration {
                    Bn({
                        classes("btn-secondary")
                        onClick { mode = HomeGuestMode.Authentication }
                    }) {
                        Text("у меня уже есть учетная запись")
                    }
                    backButton()
                }

                HomeGuestMode.Authentication -> Authentication {
                    Bn({
                        classes("btn-secondary")
                        onClick { mode = HomeGuestMode.Registration }
                    }) {
                        Text("создать учетную запись")
                    }
                    backButton()
                }
            }
        }
    }
}
